define('flowprosal-web-client/routes/clients/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      remove: function remove(model) {
        if (confirm('Are you sure?')) {
          model.destroyRecord();
        }
      }
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        clients: this.store.findAll('client')
      });
    }

    // Original
    // model: function() {
    //   return this.store.find('client');
    // }

    // model: function() {
    //   return this.store.find('client').then(function(clients){
    //   	return Ember.RSVP.all(clients.getEach('project')).then(function(){
    //   		return clients;
    //   	});
    //   });
    // }
    //

    // model: function() {
    //   var store = this.store;
    //   return store.find('client').then(function(client) {
    //     return store.find('project', client.project)
    //   });
    // }
  });
});