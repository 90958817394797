define('flowprosal-web-client/controllers/dashboard/settings/payment', ['exports', 'ember', 'ember-validations', 'flowprosal-web-client/config/environment'], function (exports, _ember, _emberValidations, _flowprosalWebClientConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
    stripe: _ember['default'].inject.service(),

    validations: {
      'model.paymentCountry': {
        presence: true
      },

      'model.paymentCurrency': {
        presence: true
      },

      'model.paymentRoutingNumber': {
        presence: true,

        inline: (0, _emberValidations.validator)(function () {
          var paymentCountry = this.get('model.model.paymentCountry');

          if (!paymentCountry || !Stripe.bankAccount.validateRoutingNumber(this.get('model.model.paymentRoutingNumber'), paymentCountry.toUpperCase())) {
            return "Bank account routing number";
          }
        })

      },
      'model.paymentAccountNumber': {
        presence: true,

        inline: (0, _emberValidations.validator)(function () {
          var paymentCountry = this.get('model.model.paymentCountry');

          if (!paymentCountry || !Stripe.bankAccount.validateAccountNumber(this.get('model.model.paymentAccountNumber'), paymentCountry.toUpperCase())) {
            return "Bank account number";
          }
        })
      },
      'model.paymentFirstName': {
        presence: true
      },
      'model.paymentLastName': {
        presence: true
      },
      'model.paymentBirthDate': {
        presence: true
      },
      'model.paymentLastSsn': {
        length: {
          is: 4,

          'if': function _if(object) {
            return object.get('selectedCountry.id') === 'us';
          }
        },
        numericality: {
          'if': function _if(object) {
            return object.get('selectedCountry.id') === 'us';
          }
        }
      },
      'model.paymentBusinessName': {
        presence: {
          'if': function _if(object) {
            return object.get('model.paymentType') === 'corporation';
          }
        }
      },
      'model.paymentBusinessEin': {
        presence: {
          'if': function _if(object) {
            return object.get('model.paymentType') === 'corporation';
          }
        }
      },
      'model.paymentAddress': {
        presence: true
      },
      'model.paymentCity': {
        presence: true
      },
      'model.paymentState': {
        presence: true
      },
      'model.paymentZip': {
        presence: true
      },

      'identityDocumentDropzone': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.get('isIdentityDocumentRequired') && !this.get('identityDocumentDropzone')) {
            return "Please attach an identity document";
          }
        })
      }
    },

    selectedIndividual: _ember['default'].computed('model.paymentType', function () {
      return this.get('model.paymentType') === 'individual';
    }),

    paymentFirstNameError: _ember['default'].computed('model.rawStripeHookData', 'errors.model.paymentFirstName', 'displayErrors', function () {
      var stripeError = false;

      if (this.model.get('rawStripeHookData.data.object.verification.fields_needed')) {
        stripeError = this.model.get('rawStripeHookData.data.object.verification.fields_needed').any(function (error) {
          return error.indexOf('first_name') > 0;
        });
      }

      return this.get('displayErrors') && this.get('errors.model.paymentFirstName') || stripeError;
    }),

    paymentLastNameError: _ember['default'].computed('model.rawStripeHookData', 'errors.model.paymentLastName', 'displayErrors', function () {
      var stripeError = false;

      if (this.model.get('rawStripeHookData.data.object.verification.fields_needed')) {
        stripeError = this.model.get('rawStripeHookData.data.object.verification.fields_needed').any(function (error) {
          return error.indexOf('last_name') > 0;
        });
      }

      return this.get('displayErrors') && this.get('errors.model.paymentLastName') || stripeError;
    }),

    paymentBirthDateError: _ember['default'].computed('model.rawStripeHookData', 'errors.model.paymentBirthDate', 'displayErrors', function () {
      var stripeError = false;

      if (this.model.get('rawStripeHookData.data.object.verification.fields_needed')) {
        stripeError = this.model.get('rawStripeHookData.data.object.verification.fields_needed').any(function (error) {
          return error.indexOf('dob') > 0;
        });
      }

      return this.get('displayErrors') && this.get('errors.model.paymentBirthDate') || stripeError;
    }),

    displayErrors: false,

    isFullSsnOrPersonalIdVIsible: _ember['default'].computed('model.rawStripeHookData', function () {
      if (this.get('model.rawStripeHookData.data.object.verification.fields_needed')) {
        return this.get('model.rawStripeHookData.data.object.verification.fields_needed').contains('legal_entity.personal_id_number');
      } else {
        return false;
      }
    }),

    stripeErrors: _ember['default'].computed('model.rawStripeHookData', function () {
      if (!this.model.get('rawStripeHookData.data.object.verification.fields_needed')) {
        return [];
      }

      var errorMap = {
        'legal_entity.dob.day': 'birth day',
        'legal_entity.dob.month': 'birth month',
        'legal_entity.dob.year': 'birth year',
        'legal_entity.first_name': 'first name',
        'legal_entity.last_name': 'last name',
        'legal_entity.address.city': '',
        'legal_entity.address.line1': '',
        'legal_entity.address.postal_code': '',
        'legal_entity.address.state': ''
      };

      return this.model.get('rawStripeHookData.data.object.verification.fields_needed').map(function (errorItem) {
        return errorMap[errorItem];
      });
    }),

    displayFileUpload: _ember['default'].computed('model.rawStripeHookData', function () {
      if (!this.model.get('rawStripeHookData.data.object.verification.fields_needed')) {
        return false;
      }

      return this.model.get('rawStripeHookData.data.object.verification.fields_needed').any(function (item) {
        return item === 'legal_entity.verification.document';
      });
    }),

    stripeErrorDetails: _ember['default'].computed('model.rawStripeHookData', function () {
      return this.model.get('rawStripeHookData.data.object.legal_entity.verification.details');
    }),

    selectedCountry: _ember['default'].computed('model.paymentCountry', {
      get: function get() {
        var paymentCountry = this.get('model.paymentCountry');
        return this.availableCountries.findBy('id', paymentCountry);
      },
      set: function set(key, value) {
        this.set('model.paymentCountry', value.id);
        return value;
      }
    }),

    selectedCurrency: _ember['default'].computed('selectedCountry', {
      get: function get() {
        if (!this.get('availableCurrencies')) {
          return null;
        }
        var paymentCurrency = this.get('model.paymentCurrency');
        return this.get('availableCurrencies').findBy('id', paymentCurrency);
      },
      set: function set(key, value) {
        this.set('model.paymentCurrency', value.id);
        return value;
      }
    }),

    isSSNFieldVisible: _ember['default'].computed('selectedCountry', function () {
      return this.get('selectedCountry.id') === 'us';
    }),

    isSINFieldVisible: _ember['default'].computed('selectedCountry', function () {
      return this.get('selectedCountry.id') === 'ca';
    }),

    isIdentityDocumentRequired: _ember['default'].computed('selectedCountry', function () {
      return this.get('selectedCountry.id') && this.get('selectedCountry.id') !== 'us';
    }),

    availableCurrencies: _ember['default'].computed('selectedCountry', function () {
      if (!this.get('selectedCountry.availableCurrencies')) {
        return null;
      }

      return this.get('selectedCountry.availableCurrencies').map(function (item) {
        return { id: item, title: item };
      });
    }),

    availableCountries: _ember['default'].A([{ id: 'us', title: 'United States', availableCurrencies: ['USD'] }, { id: 'ca', title: 'Canada', availableCurrencies: ['CAD', 'USD'] }, { id: 'au', title: 'Australia', availableCurrencies: ['AUD'] }, {
      text: 'Europe & UK',

      children: [{
        id: 'at',
        title: 'Austria',
        availableCurrencies: ['EUR', 'GBP', 'USD']
      }, {
        id: 'be',
        title: 'Belgium',
        availableCurrencies: ['EUR', 'GBP', 'USD']
      }, {
        id: 'dk',
        title: 'Denmark',
        availableCurrencies: ['EUR', 'GBP', 'USD', 'DKK', 'NOK', 'SEK']
      }, {
        id: 'fi',
        title: 'Finland',
        availableCurrencies: ['EUR', 'GBP', 'USD', 'DKK', 'NOK', 'SEK']
      }, {
        id: 'fr',
        title: 'France',
        availableCurrencies: ['EUR', 'GBP', 'USD']
      }, {
        id: 'de',
        title: 'Germany',
        availableCurrencies: ['EUR', 'GBP', 'USD']
      }, {
        id: 'it',
        title: 'Italy',
        availableCurrencies: ['EUR', 'GBP', 'USD']
      }, {
        id: 'ie',
        title: 'Ireland',
        availableCurrencies: ['EUR', 'GBP', 'USD']
      }, {
        id: 'lu',
        title: 'Luxembourg',
        availableCurrencies: ['EUR', 'GBP', 'USD']
      }, {
        id: 'nl',
        title: 'The Netherlands',
        availableCurrencies: ['EUR', 'GBP', 'USD']
      }, {
        id: 'no',
        title: 'Norway',
        availableCurrencies: ['EUR', 'GBP', 'USD', 'DKK', 'NOK', 'SEK']
      }, {
        id: 'se',
        title: 'Sweden',
        availableCurrencies: ['EUR', 'GBP', 'USD', 'DKK', 'NOK', 'SEK']
      }, {
        id: 'es',
        title: 'Spain',
        availableCurrencies: ['EUR', 'GBP', 'USD']
      }, {
        id: 'gb',
        title: 'United Kingdom',
        availableCurrencies: ['EUR', 'GBP', 'USD']
      }]
    }]),

    dzAddedFile: function dzAddedFile() {
      if (this.dropzone.files.length > 1) {
        this.dropzone.removeFile(this.dropzone.files[1]);
        return false;
      }

      var adapter = this.container.lookup('adapter:application'),
          url = adapter.buildURL('companies', this.model.id) + '/send_image_to_stripe',
          _this = this;

      this.dropzone.options.url = url;
      this.dropzone.options.headers = this.currentController.get('authHeaders');

      if (this.dropzone._callbacks.success.length === 1) {
        this.dropzone.on('success', function () {
          _this.model.reload();
          this.removeAllFiles();
          this._startStripeStatusPing();
        });
      }

      _ember['default'].run.later(this, function () {
        this.dropzone.processQueue();
      });
    },

    authHeaders: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return { "Authorization": this.get('session.currentUser.authenticationToken'), "CompanyIdentifier": this.get('session.currentCompany.id') };
    }),

    addIdentityDocument: function addIdentityDocument() {
      if (this.dropzone.files.length > 1) {
        this.dropzone.removeFile(this.dropzone.files[0]);
        return false;
      }

      var adapter = this.container.lookup('adapter:application'),
          url = adapter.buildURL('companies', this.model.id) + '/send_image_to_stripe',
          _this = this;

      this.dropzone.options.url = url;
      this.dropzone.options.headers = this.currentController.get('authHeaders');
      this.currentController.set('identityDocumentDropzone', this.dropzone);

      if (this.dropzone._callbacks.success.length === 1) {
        this.dropzone.on('success', function () {
          _this.model.reload();
          this.removeAllFiles();
          this._startStripeStatusPing();
        });
      }
    },

    _connectToStripe: function _connectToStripe() {
      var _this2 = this;

      var stripe = this.get('stripe'),
          model = this.get('model');

      var bankAccount = {
        country: model.get('paymentCountry'),
        currency: model.get('paymentCurrency'),
        routingNumber: model.get('paymentRoutingNumber'),
        accountNumber: model.get('paymentAccountNumber')
      };

      stripe.bankAccount.createToken(bankAccount).then(function (response) {
        model.set('paymentType', _this2.get('selectedIndividual') ? 'individual' : 'corporation');
        _this2.set('bankAccountToken', response.id);

        return model.save();
      }, function (response) {
        alert('There is an error in your bank account details: ' + response.error.message);
        throw "Wrong bank account details";
      }).then(function (user) {
        return user.connectToStripe({ bankAccountToken: _this2.get('bankAccountToken') });
      }).then(function (result) {
        _this2.set('bankAccountToken', null);

        if (result.stripe_result.verification.disabled_reason === 'fields_needed') {
          alert('Error: fields_needed');
          console.error('Missing fields: ', result.stripe_result.verification.fields_needed);
        } else {
          if (_this2.get('isIdentityDocumentRequired')) {
            _this2.get('identityDocumentDropzone').processQueue();
          } else {
            model.reload();
            _this2._startStripeStatusPing();
          }
        }
      });
    },

    _startStripeStatusPing: function _startStripeStatusPing() {
      _ember['default'].run.later(this, this._pingStripeStatus, 10000);
    },

    _pingStripeStatus: function _pingStripeStatus() {
      var adapter = this.container.lookup('adapter:application'),
          url = adapter.buildURL('companies', this.model.id) + '/stripe_status';

      var _this = this;

      $.ajax({
        url: url,
        type: "GET",
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', _this.get('authHeaders.Authorization'));
          xhr.setRequestHeader('CompanyIdentifier', _this.get('authHeaders.CompanyIdentifier'));
        },
        success: function success(response) {
          if (response.stripe_verification_status === 'pending') {
            _this._startStripeStatusPing();
          } else {
            _this.model.reload();
          }
        },
        error: function error() {
          _this._startStripeStatusPing();
        }
      });
    },

    actions: {
      showIndividual: function showIndividual() {
        this.set('selectedIndividual', true);

        this.validate();
      },

      showBusiness: function showBusiness() {
        this.set('selectedIndividual', false);

        this.validate();
      },

      countrySelected: function countrySelected(country) {
        this.set('selectedCountry', country);
        this.validate();
      },

      currencySelected: function currencySelected(currency) {
        this.set('selectedCurrency', currency);
        this.validate();
      },

      submit: function submit() {
        var _this3 = this;

        this.set('displayErrors', true);

        this.validate();

        if (!this.get('isValid')) {
          return false;
        }

        console.log(_flowprosalWebClientConfigEnvironment['default'].stripe.publishableKey);

        this.get('model').save().then(function () {
          _this3._connectToStripe();
        });
      }
    }
  });
});