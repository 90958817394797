define('flowprosal-web-client/routes/dashboard/account/payments/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.store.peekRecord('subscriptionPayment', params.subscription_payment_id);
    },

    actions: {
      closeModal: function closeModal() {
        this.transitionTo('dashboard.account.payments');
      }
    }
  });
});