define('flowprosal-web-client/controllers/password/new', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
    queryParams: ['resetToken'],
    displayErrors: false,

    validations: {
      'password': {
        presence: true,
        confirmation: true,
        length: { minimum: 8 }
      }
    },

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.set('displayErrors', true);

        if (!this.get('isValid')) {
          return false;
        }

        console.log('Dick', this.get('resetToken'));

        var url = this.container.lookup('adapter:application').buildURL() + '/public/passwords/reset';

        _ember['default'].$.ajax({
          url: url,
          type: 'POST',
          data: { password: this.get('password'), password_confirmation: this.get('passwordConfirmation'), reset_token: this.get('resetToken') }
        }).done(function () {
          _this.set('displayErrors', false);
          _this.set('password', null);
          _this.set('passwordConfirmation', null);

          _this.transitionToRoute('login');
        }).fail(function (response) {
          if (response.status === 404) {
            _this.notifications.addNotification({
              message: 'The password reset token has expired.',
              type: 'error',
              autoClear: true,
              clearDuration: 4200
            });
            _this.transitionToRoute('password.forgotten');
          } else {
            _this.notifications.addNotification({
              message: 'Something went wrong, please try later.',
              type: 'error',
              autoClear: true,
              clearDuration: 4200
            });
          }
        });
      }
    }
  });
});