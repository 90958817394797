define('flowprosal-web-client/models/payment-milestone', ['exports', 'ember', 'ember-data', 'ember-api-actions', 'ember-validations'], function (exports, _ember, _emberData, _emberApiActions, _emberValidations) {
  exports['default'] = _emberData['default'].Model.extend(_emberValidations['default'], {
    validations: {
      'description': {
        presence: true
      },
      'percentage': {
        numericality: { greaterThanOrEqualTo: 1, lessThanOrEqualTo: 100 }
      }
    },

    percentage: _emberData['default'].attr('number'),
    price: _emberData['default'].attr('number'),
    description: _emberData['default'].attr(),
    paymentMilestoneType: _emberData['default'].attr(),
    status: _emberData['default'].attr(),
    paidAt: _emberData['default'].attr(),
    cardBrand: _emberData['default'].attr(),
    cardLast4: _emberData['default'].attr(),
    deposit: _emberData['default'].attr('boolean'),

    proposal: _emberData['default'].belongsTo('proposal', { async: true }),

    descriptionForHumans: _ember['default'].computed('description', 'paymentMilestoneType', function () {
      if (this.get('paymentMilestoneType') === 'one_payment') {
        if (this.get('description') === 'one_payment_starting') {
          return 'One Payment Starting';
        } else {
          return 'Due Upon Completion';
        }
      }

      return this.get('description');
    }),

    milestoneTooltip: _ember['default'].computed('status', function () {
      if (this.get('status') === 'paid') {
        return "This request has been paid";
      } else if (this.get('status') === 'sent') {
        return "Payment request has been sent";
      } else {
        return "Payment request has not been sent";
      }
    }),

    //Custom actions
    pay: (0, _emberApiActions.memberAction)({ path: 'pay' })
  });
});