define('flowprosal-web-client/mixins/intro/proposal-edit-intro-mixin', ['exports', 'ember', 'flowprosal-web-client/mixins/intro/intro-mixin'], function (exports, _ember, _flowprosalWebClientMixinsIntroIntroMixin) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Mixin.create(_flowprosalWebClientMixinsIntroIntroMixin['default'], {
    introType: 'editProposal',

    initIntrojs: function initIntrojs() {
      var steps = [{
        element: $('.btn-assign-client')[0],
        intro: '<h2>Add your client</h2>\n        <p>Click this button to assign your Client (Customer) to add your client to this proposal.</p>'
      }];

      if ($('.btn-assign-contact')[0]) {
        steps.push({
          element: $('.btn-assign-contact')[0],
          intro: '<h2>Add your contact</h2>\n        <p>Click this button to add your Contact to this proposal.</p>'
        });
      }

      steps.push({
        element: $('.btn-add-segments')[0],
        intro: '<h2>Add segments</h2>\n      <p>Click this button to open the Segments drawer and create a new Segment or add one from your library to this proposal.</p>',
        tooltipClass: 'introjs-last tutorial-edit-proposal'
      });

      this.set('introSteps', steps);
    }
  });
});