define('flowprosal-web-client/macros/error-unless-email', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = errorUnlessEmail;

  function errorUnlessEmail(property, errorMessage) {
    var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
    var allowEmpty = options.allowEmpty;

    return _ember['default'].computed(property, function () {
      var value = this.get(property);
      if (allowEmpty && _ember['default'].isEmpty(value)) {
        return null;
      }
      return (/@/.test(value) ? null : errorMessage
      );
    });
  }
});