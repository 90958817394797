define('flowprosal-web-client/controllers/login', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    email: '',
    password: '',

    error: null,

    actions: {
      login: function login() {
        var _this = this;

        this.session.open('application', {
          email: this.get('email'),
          password: this.get('password')
        }).then(function () {
          _this.transitionToRoute('dashboard');
        })['catch'](function () {
          _this.set('error', 'Invalid email or password.');
        });
      }
    }
  });
});