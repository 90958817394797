define('flowprosal-web-client/controllers/dashboard', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    menustate: _ember['default'].inject.service(),

    isMenuOpen: _ember['default'].computed.alias('menustate.isMenuOpen'),

    actions: {
      toggleMenu: function toggleMenu() {
        this.toggleProperty('isMenuOpen');
      }
    }
  });
});