define('flowprosal-web-client/routes/contacts/new', ['exports', 'ember', 'flowprosal-web-client/mixins/save-model-mixin-factory'], function (exports, _ember, _flowprosalWebClientMixinsSaveModelMixinFactory) {
  exports['default'] = _ember['default'].Route.extend((0, _flowprosalWebClientMixinsSaveModelMixinFactory['default'])('contacts'), {
    model: function model() {
      return this.store.createRecord('contact');
    },

    afterModel: function afterModel(_, transition) {
      var _this = this;

      if (transition.queryParams.assignToProposal) {
        return _ember['default'].RSVP.hash({

          proposal: this.store.findRecord('proposal', transition.queryParams.assignToProposal).then(function (proposal) {
            _this.set('proposal', proposal);
          }),

          client: this.store.findRecord('client', transition.queryParams.clientId).then(function (client) {
            _this.set('client', client);
          })

        });
      } else {
        this.set('proposal', undefined);
        this.set('client', undefined);

        return this.store.findAll('client').then(function (result) {
          _this.set('clients', result);
        });
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var clients;

      if (this.get('clients.length') === 0) {
        clients = [{
          id: 0,
          name: 'Add new client',
          addNewClient: true
        }];
      } else {
        clients = this.get('clients');
      }

      controller.setProperties({
        displayNewRecordForm: true,
        selectedContact: this.get('proposal.contact'),
        proposal: this.get('proposal'),
        client: this.get('client'),
        clients: clients,
        contacts: this.get('client.contacts')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('assignToProposal', undefined);
        controller.set('clientId', undefined);
      }
    }
  });
});