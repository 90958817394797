define('flowprosal-web-client/routes/segments/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return this.store.createRecord('segment-template', {
        'company': this.get('session.currentCompany'),
        'user': this.get('session.currentUser')
      });
    },

    deactivate: function deactivate() {
      if (this.currentModel.get('isNew')) {
        this.currentModel.deleteRecord();
      } else {
        this.currentModel.rollbackAttributes();
      }
    },

    actions: {
      "on-success": function onSuccess() {
        this.transitionTo('segments.index');
      }
    }
  });
});