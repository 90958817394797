define('flowprosal-web-client/components/signup-flow-action-buttons', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['buttons row'],
    nextLabel: '',

    actions: {
      next: function next() {
        this.attrs.nextAction();
      },
      cancel: function cancel() {
        this.attrs.cancelAction();
      }
    }
  });
});