define('flowprosal-web-client/routes/bootstrap/team', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.store.findRecord('company', params.company_id);
    },

    afterModel: function afterModel(model) {
      this.get('session').set('content.currentCompany', model);
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      this.store.query('segment-template', { sort: 'createdAt', 'filter[source_type]': 'team_member' }).then(function (results) {
        controller.set('existingTeamBios', results);
      });
    },

    resetController: function resetController(controller) {
      controller.get('teamBios').clear();
      controller.setProperties({
        isShowingWarningModal: false,
        modalConfirmAction: null
      });
    }
  });
});