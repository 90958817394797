define('flowprosal-web-client/mirage/factories/contact', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = _emberCliMirage['default'].Factory.extend({
    firstName: _emberCliMirage.faker.name.firstName,
    lastName: _emberCliMirage.faker.name.lastName,
    title: _emberCliMirage.faker.name.jobTitle,
    email: _emberCliMirage.faker.internet.email,
    phone: _emberCliMirage.faker.phone.phoneNumber,
    client: function client() {
      return _emberCliMirage.faker.random.number({ min: 1, max: 10 });
    }
  });
});