define('flowprosal-web-client/components/form-edit-proposal', ['exports', 'ember', 'flowprosal-web-client/mixins/send-save-mixin'], function (exports, _ember, _flowprosalWebClientMixinsSendSaveMixin) {
  exports['default'] = _ember['default'].Component.extend(_flowprosalWebClientMixinsSendSaveMixin['default'], {
    isEditable: true,
    isShowingCommentsModal: false,
    selectedSegmentForShowingComments: null,

    templates: ['minimalist', 'block', 'align-right'],

    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    uploadingFiles: [],

    didInitAttrs: function didInitAttrs(_ref) {
      var attrs = _ref.attrs;

      this.set('internalSegments', attrs.sortedPLainSegments.value.slice(0));
      this.set('segmentCount', attrs.sortedPLainSegments.value.length);
    },

    segmentsLengthObserver: _ember['default'].observer('sortedPLainSegments.length', function () {
      if (this.get('sortedPLainSegments.length') !== this.get('segmentCount')) {
        this.set('internalSegments', this.get('sortedPLainSegments').slice(0));
        this.set('segmentCount', this.get('sortedPLainSegments.length'));
      }
    }),

    gridStackOptions: _ember['default'].computed('isEditable', function () {
      return {
        height: 12,
        width: 12,
        //cell_height: 29,
        vertical_margin: 0,
        static_grid: !this.get('isEditable'),
        float: true,
        animate: true
      };
    }),

    isIE: _ember['default'].computed('property', function () {
      var msie = window.location.hash = !!window.MSInputMethodContext && !!document.documentMode;

      if (msie) {
        return true;
      } else {
        return false;
      }
    }),

    galleryUploadUrl: _ember['default'].computed('uploadTargetSegment.id', function () {
      return this.container.lookup('adapter:application').buildURL('segments', this.get('uploadTargetSegment.id')) + '/upload_gallery_image';
    }),

    setHeaders: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return { "Authorization": this.get('session.currentUser.authenticationToken'), "CompanyIdentifier": this.get('session.currentCompany.id') };
    }),

    filteredImages: _ember['default'].computed('model.company.images.@each.id', 'uploadTargetSegment.galleryImageIds.[]', function () {
      var ret = _ember['default'].A([]);
      var company = this.get('model.company');
      var alreadyUsed = this.get('uploadTargetSegment.galleryImageIds');

      if (alreadyUsed) {
        if (company.get('images')) {
          company.get('images').forEach(function (im) {
            if (alreadyUsed.indexOf(im.get('id')) < 0) {
              ret.pushObject(im);
            }
          });
        }
      }

      return ret;
    }),

    isCoverLetterVisible: _ember['default'].computed('coverLetterSegment.visible', function () {
      var coverLetter = this.get('coverLetterSegment');

      if (!coverLetter || !coverLetter.get('visible')) {
        return false;
      }

      if (!this.get('isEditable') && !coverLetter.get('content')) {
        return false;
      }

      return true;
    }),

    isAppendixVisible: _ember['default'].computed('appendixSegment.visible', function () {
      var appendix = this.get('appendixSegment');

      if (!appendix || !appendix.get('visible')) {
        return false;
      }

      return true;
    }),

    actions: {
      updateProposalTemplate: function updateProposalTemplate(newValue) {
        this.sendAction('updateProposalTemplate', newValue);
      },

      updateProposalAttr: function updateProposalAttr() {
        if (this.get('isEditable')) {
          this.model.save();
        }
      },
      updateProposalClientAttr: function updateProposalClientAttr() {
        this.sendAction('updateProposalClientAttr');
      },

      updateProposalContactAttr: function updateProposalContactAttr() {
        this.sendAction('updateProposalContactAttr');
      },

      activateSegment: function activateSegment($inplaceEdit) {
        if (this.get('isEditable')) {
          _ember['default'].$('.segment__wrapper').removeClass('active');
          $inplaceEdit.parents('.segment__wrapper').addClass('active');
        }
      },

      updateSegmentAttr: function updateSegmentAttr(segment) {
        if (this.get('isEditable')) {
          _ember['default'].$('.segment__wrapper').removeClass('active');
          this.sendAction('updateSegmentAttr', segment);
        }
      },

      updateSegmentPrice: function updateSegmentPrice(segment, newPrice) {
        _ember['default'].$('.segment__wrapper').removeClass('active');
        segment.set('price', newPrice.replace(/[^\d.-]/g, ''));
        this.sendAction('updateSegmentAttr', segment);
      },

      clickOnSegmentRequiredArea: function clickOnSegmentRequiredArea(segment) {
        if (this.get('isEditable')) {
          this.sendAction('invertSegmentRequiredValue', segment);
        } else {
          if (!this.get('model.isInFinalStatus')) {
            this.sendAction('invertSegmentAccaptedValue', segment);
          }
        }
      },

      rankSegment: function rankSegment(segment, direction) {
        var _this2 = this;

        var internal = this.get('internalSegments');
        var originalPosition = internal.indexOf(segment);
        // validate the move
        if (this.get('isEditable') && (direction === -1 && originalPosition < internal.length - 1 || direction === 1 && originalPosition > 0)) {
          (function () {
            var _this = _this2;
            var el = _this2.$('#segment-' + segment.get('id'));

            el.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
              internal.removeObject(segment);
              internal.insertAt(originalPosition - direction, segment);
              _ember['default'].run.scheduleOnce('afterRender', _this, function () {
                _ember['default'].run.later(this, function () {
                  segment.set('isRankChanging', false);
                }, 150);
              });
            });

            segment.set('isRankChanging', true);
            _this2.sendAction('rankSegment', segment, direction);
          })();
        }
      },

      indentSegment: function indentSegment(segment, direction) {
        if (this.get('isEditable')) {
          this.sendAction('indentSegment', segment, direction);
        }
      },

      deleteSegment: function deleteSegment(segment) {
        if (this.get('isEditable') && confirm('Are you sure?')) {
          this.sendAction('deleteSegment', segment);
        }
      },

      duplicateProposal: function duplicateProposal() {
        this.sendAction('duplicateProposal');
      },

      deleteProposal: function deleteProposal() {
        if (confirm('Are you sure?')) {
          this.sendAction('deleteProposal');
        }
      },

      toggleSegmentVisibility: function toggleSegmentVisibility(segment) {
        this.sendAction('toggleSegmentVisibility', segment);
      },

      addFirstComment: function addFirstComment(segment, text) {
        this.sendAction('addNewComment', segment, text);
        _ember['default'].$('.segment__btn-add-comment').removeClass('active');
      },

      addNewComment: function addNewComment(segment, text) {
        this.sendAction('addNewComment', segment, text);
      },

      activateAddCommentArea: function activateAddCommentArea($elem) {
        $elem.parents('.segment__btn-add-comment').addClass('active');
      },

      toggleCommentsModal: function toggleCommentsModal(segment) {
        this.set('selectedSegmentForShowingComments', segment);
        this.toggleProperty('isShowingCommentsModal');
      },

      askSignature: function askSignature() {
        this.sendAction('askSignature');
      },

      acceptProposal: function acceptProposal() {
        if (confirm('Are you sure?')) {
          this.sendAction('acceptProposal');
        }
      },

      declineProposal: function declineProposal() {
        if (confirm('Are you sure?')) {
          this.sendAction('declineProposal');
        }
      },

      addGalleryImage: function addGalleryImage(segment) {
        if (this.get('isEditable')) {
          //upload image for the segment here and add to the grid data
          this.set('uploadTargetSegment', segment);
          this.set('isShowingUploadGalleryImageModal', true);
        }
      },

      removeGalleryImage: function removeGalleryImage(segment, item) {
        if (this.get('isEditable')) {
          var galleryImage = segment.get('gallery-images').findBy('id', _ember['default'].get(item, 'content.id'));

          segment.get('gridData').removeObject(segment.get('gridData').findBy('content.id', _ember['default'].get(item, 'content.id')));
          segment.get('gallery-images').removeObject(galleryImage);
          galleryImage.destroyRecord();
        }
      },

      galleryFileAdded: function galleryFileAdded(file) {
        this.get('uploadingFiles').pushObject(file.name);
      },

      galleryUploadComplete: function galleryUploadComplete(file) {
        this.get('uploadingFiles').removeObject(file.name);
        if (file.status === 'success') {
          var jsonResponse = undefined;
          try {
            jsonResponse = JSON.parse(file.xhr.response);
          } catch (e) {
            jsonResponse = null;
          }
          if (jsonResponse && jsonResponse.data.id) {
            this.get('store').pushPayload(jsonResponse);
            var segment = this.get('uploadTargetSegment');
            segment.get('gridData').push({ x: 5, y: 5, h: 2, w: 2, content: { id: jsonResponse.data.id } });
            segment.get('gallery-images').reload();
            segment.save();
          }
        }
      },

      galleryUploadAbort: function galleryUploadAbort(file) {
        this.get('uploadingFiles').removeObject(file.name);
      },

      closeUploadGalleryImageModal: function closeUploadGalleryImageModal() {
        if (this.get('uploadingFiles.length') < 1) {
          this.set('uploadTargetSegment', null);
          this.set('isShowingUploadGalleryImageModal', false);
        }
      },

      onGridChanged: function onGridChanged(segment, updatedItems) {
        var hasChanged = false;
        if (this.get('isEditable')) {
          (function () {
            var myItems = segment.get('gridData');
            updatedItems.forEach(function (it) {
              var thisItem = myItems.findBy('content.id', _ember['default'].get(it, 'content.id'));
              if (thisItem && (_ember['default'].get(thisItem, 'x') !== it.x || _ember['default'].get(thisItem, 'y') !== it.y || _ember['default'].get(thisItem, 'w') !== it.w || _ember['default'].get(thisItem, 'h') !== it.h)) {
                _ember['default'].set(thisItem, 'x', it.x);
                _ember['default'].set(thisItem, 'y', it.y);
                _ember['default'].set(thisItem, 'w', it.w);
                _ember['default'].set(thisItem, 'h', it.h);
                hasChanged = true;
              }
            });
            if (hasChanged) {
              _ember['default'].run.throttle(segment, segment.save, 700);
            }
          })();
        }
      },

      addToFavorites: function addToFavorites(segment) {
        var store = this.get('store');
        segment.favoriteGallery().then(function (segment_template) {
          store.pushPayload(segment_template);
        });
      },

      addExistingImageToGrid: function addExistingImageToGrid(image) {
        var _this = this;
        var gi = this.get('store').createRecord('gallery-image', {
          segment: this.get('uploadTargetSegment'),
          image: image,
          file: _ember['default'].get(image, 'file')
        });
        gi.save().then(function (saved) {
          var segment = _this.get('uploadTargetSegment');
          segment.get('gridData').push({ x: 5, y: 5, h: 2, w: 2, content: { id: saved.get('id') } });
          segment.get('gallery-images').reload();
          segment.save();
        })['catch'](function (err) {
          console.log(err);
        });
      },

      selectClient: function selectClient() {
        this.sendAction('selectClient');
      },

      selectContact: function selectContact() {
        this.sendAction('selectContact');
      },

      changeSegmentTypeToGallery: function changeSegmentTypeToGallery(segment) {
        segment.set('segmentType', 'gallery');
        this.sendAction('updateSegmentAttr', segment);
      },

      changeSegmentTypeToNormal: function changeSegmentTypeToNormal(segment) {
        segment.set('segmentType', '');
        if (!segment.get('price')) {
          //don't change price if already set
          segment.set('price', 1);
        }
        this.sendAction('updateSegmentAttr', segment);
      }
    }
  });
});