define('flowprosal-web-client/components/user-settings-expire-after', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['c-dashboard-settings__expire-after'],

    numbers: ['5', '10', '15', '20', '25', '30', '45', '60', '90'],

    actions: {
      updateUserSettings: function updateUserSettings(days) {
        this.get('user').set('expireAfter', days);
        this.attrs.updateUserSettings();
      }
    }
  });
});