define('flowprosal-web-client/components/stripe-checkout', ['exports', 'ember', 'flowprosal-web-client/config/environment'], function (exports, _ember, _flowprosalWebClientConfigEnvironment) {

  /* global StripeCheckout */

  exports['default'] = _ember['default'].Component.extend({

    //declare as a static member, so all instances will share the stripe instance
    stripe: {
      instance: null
    },

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('stripe.instance')) {
        var instance = StripeCheckout.configure({
          key: _flowprosalWebClientConfigEnvironment['default'].stripe.publishableKey,
          locale: 'auto'
        });
        this.set('stripe.instance', instance);
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;
      this.get('stripe.instance').open({
        name: 'FlowProsal LLC',
        description: this.get('description') + (' ($' + this.get('amount') / 100 + ' per month)'),
        panelLabel: 'Subscribe',
        label: 'Subscribe',
        closed: function closed() {
          _this.sendAction('on-closed');
        },
        token: function token(_token) {
          _this.sendAction('on-token', _token, _this.get('planLevel'));
        }
      });
    },

    willDestroyElement: function willDestroyElement() {
      var instance = this.get('stripe.instance');
      if (instance && instance.close) {
        instance.close();
      }
    }
  });
});