define('flowprosal-web-client/torii-adapters/twitter', ['exports', 'ember', 'flowprosal-web-client/config/environment', 'flowprosal-web-client/utils/camelize-keys'], function (exports, _ember, _flowprosalWebClientConfigEnvironment, _flowprosalWebClientUtilsCamelizeKeys) {
  exports['default'] = _ember['default'].Object.extend({
    open: function open(authorization) {
      var _this = this;

      var that = this;
      window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].authTokenName, authorization.code);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax({
          url: _flowprosalWebClientConfigEnvironment['default'].apiHost + '/api/v2/sessions',
          type: 'GET',
          dataType: 'json',
          headers: {
            Authorization: authorization.code
          },
          success: _ember['default'].run.bind(null, resolve),
          error: _ember['default'].run.bind(null, reject)
        });
      }).then(function (authorization) {
        var userId = authorization.data.id;
        var companyId = '';
        var user = _this.store.peekRecord('user', userId);
        if (!user) {
          user = _this.store.createRecord('user', (0, _flowprosalWebClientUtilsCamelizeKeys['default'])(authorization.data.attributes));
          user.set('id', authorization.data.id);
        }

        if (!companyId && authorization.data.attributes['single-company']) {
          companyId = authorization.data.attributes['single-company'];
          window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier, companyId);
        }

        if (companyId) {
          var company = that.store.peekRecord('company', companyId);
          if (!company) {
            company = that.store.createRecord('company');
            company.set('id', companyId);
          }

          return { currentUser: user, currentCompany: company };
        } else {
          return { currentUser: user };
        }
      });
    }
  });
});