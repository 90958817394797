define('flowprosal-web-client/routes/proposals/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      q: {
        refreshModel: true
      }
    },

    actions: {
      deleteProposal: function deleteProposal(model) {
        if (confirm('Are you sure?')) {
          model.destroyRecord();
        }
      },

      duplicateProposal: function duplicateProposal(proposal) {
        var route = this;

        proposal.duplicate().then(function (proposal) {
          route.transitionTo('proposals.edit', proposal.data.id);
        });
      }
    },
    model: function model(params) {
      return _ember['default'].RSVP.hash({
        proposals: this.store.queryRecord('proposal', { filter: { search: params.q, status: params.status } }),
        segments: this.store.findAll('segment')
      });
    }
  });
});