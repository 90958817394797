define('flowprosal-web-client/models/work-gallery', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    company: _emberData['default'].belongsTo('company', { async: true }),

    title: _emberData['default'].attr('string'),

    galleryImages: _emberData['default'].hasMany('gallery-image'),
    gridData: _emberData['default'].attr(), //plain json attribute
    gridHeight: _emberData['default'].attr('number'),

    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date')
  });
});