define('flowprosal-web-client/mixins/save-client-mixin', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  exports['default'] = _ember['default'].Mixin.create(_emberValidations['default'], {
    queryParams: ['assignToProposal'],
    displayErrors: false,

    validations: {
      'model.name': {
        presence: true
      }
    },

    _assignToProposal: function _assignToProposal() {
      var _this = this;

      var proposal = this.get('proposal');

      proposal.set('client', this.model);
      proposal.set('contact', null);

      proposal.save().then(function () {
        _this.transitionToRoute('proposals.edit', proposal);
      });
    },

    _closeModal: function _closeModal() {
      if (this.get('proposal')) {
        this.transitionToRoute('proposals.edit', this.get('proposal'));
      } else {
        if (this.model.get('isNew')) {
          this.transitionToRoute('clients.index');
        } else {
          this.transitionToRoute('clients.show', this.model);
        }
      }
    },

    actions: {
      save: function save() {
        var _this2 = this;

        this.set('displayErrors', !this.get('isValid'));

        if (!this.get('isValid')) {
          return false;
        }

        this.model.set('company', this.get('session.currentCompany'));

        this.model.save().then(function () {
          if (_this2.get('proposal')) {
            _this2._assignToProposal();
          } else {
            _this2._closeModal();
          }
        });
      },

      closeModal: function closeModal() {
        this._closeModal();
      }
    }
  });
});