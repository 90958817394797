define('flowprosal-web-client/components/select-segment', ['exports', 'ember', 'flowprosal-web-client/mixins/intro/select-segment-intro-mixin'], function (exports, _ember, _flowprosalWebClientMixinsIntroSelectSegmentIntroMixin) {
  exports['default'] = _ember['default'].Component.extend(_flowprosalWebClientMixinsIntroSelectSegmentIntroMixin['default'], {
    session: _ember['default'].inject.service('session'),

    segmentTemplates: [],

    opened: false,

    selectedFavorites: true,
    selectedAll: false,
    selectedNew: false,

    searchPlaceholder: 'Search favorites',
    searchTerm: '',

    onDidInitAttrs: _ember['default'].on('didInitAttrs', function (_ref) {
      var attrs = _ref.attrs;

      this.set('searchTerm', attrs.origSearchTerm);
    }),

    openDrawer: _ember['default'].on('didInsertElement', function () {
      _ember['default'].run.later(this, function () {
        this.set('opened', true);
      }, 100);
    }),

    destroyDropzone: function destroyDropzone() {
      if (Dropzone.instances.length && this.$(".c-select-segment__body .dropzone").length) {
        var dz = Dropzone.forElement(".c-select-segment__body .dropzone");
        dz.destroy();
      }
    },

    gridStackOptions: {
      height: 12,
      width: 12,
      cell_height: 27,
      vertical_margin: 0,
      static_grid: true,
      float: true
    },

    filteredFavoriteSegments: _ember['default'].computed('segmentTemplates.@each.favorite', function () {
      return this.get('filteredSegmentTemplates').filterBy('favorite', true);
    }),

    filteredLibrarySegments: _ember['default'].computed('segmentTemplates.@each.favorite', function () {
      return this.get('filteredSegmentTemplates').filterBy('library', true);
    }),

    filteredNewSegments: _ember['default'].computed('segmentTemplates.@each.createdAt', function () {
      return this.get('filteredSegmentTemplates').sortBy('createdAt', true).splice(0, 5);
    }),

    filteredSegmentTemplates: _ember['default'].computed('segmentTemplates', 'isSectionExist', function () {
      var segments = this.get('segmentTemplates');
      if (this.get('isSectionExist') === 'tos' || this.get('isSectionExist') === 'appendix') {
        return segments.filterBy('section', this.get('isSectionExist'));
      } else {
        return segments.filter(function (segment) {
          return segment.get('section') === '' || segment.get('section') === null;
        });
      }
    }),

    dzAddedFile: function dzAddedFile() {
      if (this.dropzone.files.length > 1) {
        this.dropzone.removeFile(this.dropzone.files[0]);
      }
    },

    setHeaders: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return { "Authorization": this.get('session.currentUser.authenticationToken'), "CompanyIdentifier": this.get('session.currentCompany.id') };
    }),

    actions: {
      leave: function leave() {
        this.destroyDropzone();
        this.set('opened', false);
        _ember['default'].run.later(this, function () {
          this.sendAction('cancelSelectSegment');
        }, 400);
      },

      showFavorites: function showFavorites() {
        this.destroyDropzone();
        this.setProperties({
          selectedFavorites: true,
          selectedAll: false,
          selectedNew: false,
          searchPlaceholder: 'Search favorites'
        });
      },

      showAll: function showAll() {
        this.destroyDropzone();
        this.setProperties({
          selectedFavorites: false,
          selectedAll: true,
          selectedNew: false,
          searchPlaceholder: 'Search all'
        });
      },

      showNew: function showNew() {
        this.setProperties({
          selectedFavorites: false,
          selectedAll: false,
          selectedNew: true
        });
      },

      addSegment: function addSegment(segmentTemplate, isGallery) {
        var _this = this;
        //reveal a spinner here
        this.attrs.addSegment(segmentTemplate, isGallery).then(function () {
          //animate the close transition
          // hide the spinner now
          _this.send('leave');
        });
      },

      createNewSegment: function createNewSegment() {
        var _this2 = this;

        var result = this.attrs.createNewSegment(this.get('newSegment'));
        //TODO Review
        if (result === false) {
          return false;
        }

        result.then(function (segment) {
          var _this = _this2;

          segment.set('position', segment.get('position'));

          var currentDropZone = Dropzone.forElement(".c-select-segment__body .dropzone"),
              adapter = _this2.container.lookup('adapter:application'),
              url = adapter.buildURL('segments', segment.id) + '/upload_image';

          currentDropZone.options.url = url;
          currentDropZone.options.headers = _this2.get('setHeaders');

          if (currentDropZone.files.length > 0) {
            currentDropZone.on('success', function () {
              _this.sendAction('reloadSegment', segment);

              //animate the close transition
              // hide the spinner now
              _this.send('leave');
            });

            currentDropZone.processQueue();
          } else {
            _this.sendAction('reloadSegment', segment);
            _this.send('leave');
          }
        });
      },

      search: function search() {
        this.sendAction('search', this.get('searchTerm'));
      },

      toggleFavorite: function toggleFavorite(segmentTemplate) {
        segmentTemplate.set('favorite', !segmentTemplate.get('favorite'));
        segmentTemplate.save();

        _ember['default'].run.schedule("afterRender", this, function () {
          PerfectScrollbar.update(this.$('.ps-wrapper')[0]);
        });
      }
    }
  });
});