define('flowprosal-web-client/helpers/lookup-module-styles', ['exports', 'ember-css-modules/helpers/lookup-module-styles'], function (exports, _emberCssModulesHelpersLookupModuleStyles) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCssModulesHelpersLookupModuleStyles['default'];
    }
  });
  Object.defineProperty(exports, 'lookupModuleStyles', {
    enumerable: true,
    get: function get() {
      return _emberCssModulesHelpersLookupModuleStyles.lookupModuleStyles;
    }
  });
});