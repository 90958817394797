define('flowprosal-web-client/components/dashboard-page', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      logout: function logout() {
        this.sendAction('logout');
      },

      toggleMenu: function toggleMenu() {
        this.sendAction('toggleMenu');
      },

      transitionToSearch: function transitionToSearch() {
        this.sendAction('transitionToSearch');
      },

      leaveSearchRoute: function leaveSearchRoute() {
        this.sendAction('leaveSearchRoute');
      },

      doProxySearch: function doProxySearch() {
        this.sendAction('doProxySearch', this.get('searchTerm'));
      }
    }
  });
});