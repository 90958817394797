define('flowprosal-web-client/mixins/intro/sign-up-segment-intro-mixin', ['exports', 'ember', 'flowprosal-web-client/mixins/intro/intro-mixin', 'ember-introjs/components/intro-js'], function (exports, _ember, _flowprosalWebClientMixinsIntroIntroMixin, _emberIntrojsComponentsIntroJs) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Mixin.create(_flowprosalWebClientMixinsIntroIntroMixin['default'], {
    introType: 'signUpSegment',

    initIntrojs: function initIntrojs() {
      if (this.get('introSteps') === null) {
        var steps = [{
          intro: '<h2>GREAT PROGRESS… ALMOST DONE</h2>\n          <p>Now we’re going to fill in some of your first products and/or services.</p>\n          <p>We call these “Segments” and they can be a narrative description, a price item (with or without a description) or include photos to show off your most excellent work...</p>',
          tooltipClass: 'signup-modal'
        }];
        this.set('introSteps', steps);
      }
    },

    actions: {
      introBeforeChange: function introBeforeChange(currentStep, nextStep) {
        if (nextStep.name === 'userMenu') {
          _ember['default'].run.later(function () {
            $('#dLabel2').trigger('click');
          });
        }

        if (currentStep.name === 'userMenu') {
          $(document.body).trigger('click');
        }
      }
    }
  });

  _emberIntrojsComponentsIntroJs['default'].reopen({
    'done-label': 'NEXT >'
  });
});