define('flowprosal-web-client/torii-providers/application', ['exports', 'ember', 'flowprosal-web-client/config/environment'], function (exports, _ember, _flowprosalWebClientConfigEnvironment) {
  exports['default'] = _ember['default'].Object.extend({
    open: function open(options) {
      var email = options.email;
      var password = options.password;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax({
          url: _flowprosalWebClientConfigEnvironment['default'].apiHost + '/api/v2/sessions',
          type: 'POST',
          data: { email: email, password: password },
          dataType: 'json',
          success: _ember['default'].run.bind(null, resolve),
          error: _ember['default'].run.bind(null, reject)
        });
      });
    }
  });
});