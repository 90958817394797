define("flowprosal-web-client/templates/components/profile-image", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/components/profile-image.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("img");
        dom.setAttribute(el1, "class", "user-profile-image img-circle");
        dom.setAttribute(el1, "width", "175px");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'src');
        morphs[1] = dom.createAttrMorph(element0, 'alt');
        return morphs;
      },
      statements: [["attribute", "src", ["concat", [["get", "imageUrl", ["loc", [null, [1, 50], [1, 58]]]]]]], ["attribute", "alt", ["concat", [["get", "userName", ["loc", [null, [1, 83], [1, 91]]]]]]]],
      locals: [],
      templates: []
    };
  })());
});