define('flowprosal-web-client/models/subscription-payment', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    stripeChargeId: _emberData['default'].attr(),
    status: _emberData['default'].attr(),
    subscription: _emberData['default'].attr(),
    cardEnding: _emberData['default'].attr(),
    amount: _emberData['default'].attr(),
    company: _emberData['default'].belongsTo('company'),

    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),

    label: _ember['default'].computed('subscription', function () {
      var s = this.get('subscription');
      return 'Monthly ' + (s === 1 ? 'Silver' : 'Gold') + ' Subscription Payment';
    })
  });
});