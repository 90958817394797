define('flowprosal-web-client/components/modal-ask-deposit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    actions: {
      closeModal: function closeModal() {
        this.sendAction('on-close');
      },

      payDeposit: function payDeposit() {
        this.sendAction('payDeposit');
      }
    }

  });
});