define('flowprosal-web-client/controllers/proposals/edit', ['exports', 'ember', 'flowprosal-web-client/mixins/intro/proposal-edit-intro-mixin'], function (exports, _ember, _flowprosalWebClientMixinsIntroProposalEditIntroMixin) {
  exports['default'] = _ember['default'].Controller.extend(_flowprosalWebClientMixinsIntroProposalEditIntroMixin['default'], {
    plainSegments: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.filter(function (segment) {
        if (segment.get('section') !== 'tos' && segment.get('section') !== 'appendix') {
          var segmentType = segment.get('segmentType');
          return segmentType.length === 0 || segmentType === 'gallery';
        }
      });
    }),

    sendEnabled: _ember['default'].computed.bool('model.contact.id'),

    sortedPLainSegments: _ember['default'].computed.sort('plainSegments', function (segment1, segment2) {
      if (segment1.get('position') > segment2.get('position')) {
        return 1;
      } else if (segment1.get('position') < segment2.get('position')) {
        return -1;
      }

      return 0;
    }),

    appendixSegments: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.filter(function (segment) {
        var section = segment.get('section');
        return section === "appendix";
      });
    }),

    tosSegments: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.filter(function (segment) {
        var section = segment.get('section');
        return section === "tos";
      });
    }),

    coverLetterSegment: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.find(function (segment) {
        return segment.get('segmentType') === 'cover_letter';
      });
    }),

    appendixSegment: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.find(function (segment) {
        return segment.get('segmentType') === 'appendix';
      });
    }),

    tosSegment: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.find(function (segment) {
        return segment.get('segmentType') === 'tos';
      });
    }),

    actions: {
      updateProposalTemplate: function updateProposalTemplate(proposalTemplate) {
        this.model.set('proposalTemplate', proposalTemplate);
        this.model.save();
      },

      updateProposalClientAttr: function updateProposalClientAttr() {
        this.model.get('client.content').save();
      },

      updateProposalContactAttr: function updateProposalContactAttr() {
        this.model.get('contact.content').save();
      },

      rankSegment: function rankSegment(segment, direction) {
        segment.set('position', direction);
        segment.save();
      },

      updateSegmentAttr: function updateSegmentAttr(segment) {
        if (segment.get('hasDirtyAttributes')) {
          segment.save();
        }
      },

      indentSegment: function indentSegment(segment, direction) {
        if (segment.get('indent') > 0 && direction === -1 || segment.get('indent') < 3 && direction === 1) {
          segment.incrementProperty('indent', direction);
          segment.save();
        }
      },

      deleteSegment: function deleteSegment(segment) {
        segment.deleteRecord();
        segment.save();
      },

      invertSegmentRequiredValue: function invertSegmentRequiredValue(segment) {
        segment.set('required', !segment.get('required'));
        segment.save();
      },

      toggleSegmentVisibility: function toggleSegmentVisibility(segment) {
        segment.set('visible', !segment.get('visible'));
        segment.save();
      }
    }
  });
});