define('flowprosal-web-client/routes/public-proposal/payment-milestones/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    'public': _ember['default'].inject.service('public'),

    model: function model(params) {
      return this.get('public').findRecord('payment-milestone', params.payment_milestone_id, { reload: true });
    },

    actions: {
      makePayment: function makePayment() {
        this.transitionTo('public-proposal.payment-milestones.pay', this.currentModel.get('id'));
      }
    }
  });
});