define('flowprosal-web-client/routes/proposals/edit/select-segment', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      q: {
        refreshModel: true
      },
      section: {
        refreshModel: true
      }
    },

    model: function model(params) {
      if (_ember['default'].isEmpty(params.q)) {
        return this.store.query('segment-template', { sort: '-updatedAt', section: params.section, reload: true });
      } else {
        return this.store.query('segment-template', { filter: { search: params.q }, reload: true });
      }
    },

    actions: {
      cancelSelectSegment: function cancelSelectSegment() {
        this.transitionTo('proposals.edit', this.modelFor('proposals.edit'));
      },

      search: function search(term) {
        if (_ember['default'].isEmpty(term)) {
          this.transitionTo({ queryParams: { q: null } });
        } else {
          this.transitionTo({ queryParams: { q: term } });
        }
      }
    }
  });
});