define('flowprosal-web-client/routes/contacts/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      q: {
        refreshModel: true
      }
    },

    actions: {
      remove: function remove(model) {
        if (confirm('Are you sure?')) {
          model.destroyRecord();
        }
      },
      searchContact: function searchContact(term) {
        this.transitionTo({ queryParams: { q: term } });
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('q', '');
      }
    },

    model: function model(params) {
      if (params.q) {
        this.set('searchTerm', params.q);
        return _ember['default'].RSVP.hash({
          contacts: this.store.queryRecord('contact', { filter: { search: params.q } })
        });
      } else {
        return _ember['default'].RSVP.hash({
          contacts: this.store.findAll('contact')
        });
      }
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('filterTerm', this.get('searchTerm'));
    }
  });
});