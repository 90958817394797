define('flowprosal-web-client/mirage/factories/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = _emberCliMirage['default'].Factory.extend({
    'first-name': _emberCliMirage.faker.name.firstName,
    'last-name': _emberCliMirage.faker.name.lastName,
    email: _emberCliMirage.faker.internet.email
  });
});
/*
  This is an example factory definition.

  Create more files in this directory to define additional factories.
*/
// name: 'Pete',                         // strings
// age: 20,                              // numbers
// tall: true,                           // booleans

// email: function(i) {                  // and functions
//   return 'person' + i + '@test.com';
// },

// firstName: faker.name.firstName,       // using faker
// lastName: faker.name.firstName,
// zipCode: faker.address.zipCode