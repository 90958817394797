define('flowprosal-web-client/models/user', ['exports', 'ember-data', 'ember', 'ember-api-actions'], function (exports, _emberData, _ember, _emberApiActions) {
  exports['default'] = _emberData['default'].Model.extend({
    firstName: _emberData['default'].attr(),
    lastName: _emberData['default'].attr(),
    email: _emberData['default'].attr(),
    password: _emberData['default'].attr(),
    authenticationToken: _emberData['default'].attr(),
    avatar: _emberData['default'].attr(),
    provider: _emberData['default'].attr(),
    title: _emberData['default'].attr(),
    phone: _emberData['default'].attr(),
    singleCompany: _emberData['default'].attr(),
    companies: _emberData['default'].hasMany('company', { async: true }),
    segments: _emberData['default'].hasMany('segment', { async: true }),

    colorPrimary: _emberData['default'].attr(),
    colorAccent: _emberData['default'].attr(),
    colorText: _emberData['default'].attr(),
    expireAfter: _emberData['default'].attr('number'),

    introDashboard: _emberData['default'].attr('boolean'),
    introEditProposal: _emberData['default'].attr('boolean'),
    introSelectSegment: _emberData['default'].attr('boolean'),

    "payment-milestones": _emberData['default'].attr(),
    gratuity: _emberData['default'].attr('boolean'),

    //Custom actions
    updatePaymentSettings: (0, _emberApiActions.memberAction)({ path: 'payment_settings' }),
    subscription: (0, _emberApiActions.memberAction)({ path: 'subscription' }),
    updateUserSettings: (0, _emberApiActions.memberAction)({ path: 'user_settings' }),

    fullName: _ember['default'].computed('firstName', 'lastName', function () {
      if (this.get('lastName') == null) {
        return this.get('firstName');
      } else {
        return this.get('firstName') + " " + this.get('lastName');
      }
    })
  });
});