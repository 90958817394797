define('flowprosal-web-client/routes/dashboard/transactions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      q: {
        refreshModel: true
      }
    },

    model: function model(params) {
      if (params.q) {
        return this.store.queryRecord('transaction', { filter: { search: params.q } });
      } else {
        return this.store.findAll('transaction');
      }
    }
  });
});