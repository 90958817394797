define('flowprosal-web-client/routes/application', ['exports', 'ember', 'flowprosal-web-client/config/environment'], function (exports, _ember, _flowprosalWebClientConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller) {
      controller.set('nowDate', new Date());
    },

    initStripeKey: (function () {
      _flowprosalWebClientConfigEnvironment['default'].stripe.publishableKey = localStorage.getItem('stripe_mode') === 'test' ? _flowprosalWebClientConfigEnvironment['default'].stripe.testPublishableKey : _flowprosalWebClientConfigEnvironment['default'].stripe.livePublishableKey;
      Stripe.key = _flowprosalWebClientConfigEnvironment['default'].stripe.publishableKey;
    }).on('init'),

    actions: {
      toggleStripeApiKey: function toggleStripeApiKey() {
        if (localStorage.getItem('stripe_mode') === 'test') {
          localStorage.removeItem('stripe_mode');
          _flowprosalWebClientConfigEnvironment['default'].stripe.publishableKey = _flowprosalWebClientConfigEnvironment['default'].stripe.livePublishableKey;
          alert('Stripe is in live mode now.');
        } else {
          localStorage.setItem('stripe_mode', 'test');
          _flowprosalWebClientConfigEnvironment['default'].stripe.publishableKey = _flowprosalWebClientConfigEnvironment['default'].stripe.testPublishableKey;
          alert('Stripe is in test mode now.');
        }

        Stripe.key = _flowprosalWebClientConfigEnvironment['default'].stripe.publishableKey;
      },

      error: function error() /*err*/{
        this.transitionTo('/not-found', 'not-found');
      },

      showSuccess: function showSuccess() {
        this.notifications.addNotification({
          message: 'Yeah, that just happened!',
          type: 'success',
          autoClear: true,
          clearDuration: 2200
        });
      },
      showError: function showError() {
        this.notifications.addNotification({
          message: 'Oh, Snap! Not sure what happened there... We might have a problem here?!?!?',
          type: 'error',
          autoClear: true,
          clearDuration: 2200
        });
      },
      showWarning: function showWarning() {
        this.notifications.addNotification({
          message: 'Are you REALLY sure you want to do that?',
          type: 'warning',
          autoClear: true,
          clearDuration: 2200
        });
      },
      showInfo: function showInfo() {
        this.notifications.addNotification({
          message: 'Just so you know...',
          type: 'info',
          autoClear: true,
          clearDuration: 2200
        });
      }
    }
  });
});