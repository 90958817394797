define('flowprosal-web-client/components/proposal-cover', ['exports', 'ember'], function (exports, _ember) {
  var isPresent = _ember['default'].isPresent;

  exports['default'] = _ember['default'].Component.extend({
    hasFocusedClientField: false,

    partialPath: _ember['default'].computed('model.proposalTemplate', function () {
      return 'proposals/headers/' + this.get('model.proposalTemplate');
    }),

    isClientAddressValid: _ember['default'].computed('model.client.addressLine1', 'model.client.city', 'model.client.state', 'model.client.zip', function () {
      return isPresent(this.get('model.client.addressLine1')) && isPresent(this.get('model.client.city')) && isPresent(this.get('model.client.state')) && isPresent(this.get('model.client.zip'));
    }),

    isClientAddressVisible: _ember['default'].computed.or('isClientAddressValid', 'hasFocusedClientField', 'hasNewFocusHappened'),

    actions: {
      updateProposalClientAttr: function updateProposalClientAttr() {
        if (this.get('isEditable')) {
          this.sendAction('updateProposalClientAttr');
          this.set('hasNewFocusHappened', false);

          _ember['default'].run.later(this, function () {
            this.set('hasFocusedClientField', this.get('hasNewFocusHappened'));
          }, 100);
        }
      },

      updateProposalContactAttr: function updateProposalContactAttr() {
        if (this.get('isEditable')) {
          this.sendAction('updateProposalContactAttr');
        }
      },

      updateProposalAttr: function updateProposalAttr() {
        if (this.get('isEditable')) {
          this.sendAction('updateProposalAttr');
        }
      },

      clientFieldActivated: function clientFieldActivated() {
        this.set('hasFocusedClientField', true);
        this.set('hasNewFocusHappened', true);
      }
    }
  });
});