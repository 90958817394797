define('flowprosal-web-client/routes/not-found', ['exports', 'ember'], function (exports, _ember) {

  /* global window */

  exports['default'] = _ember['default'].Route.extend({

    redirect: function redirect() {
      var url = this.router.location.formatURL('/not-found');
      if (window.location.pathname !== url) {
        this.transitionTo('/not-found');
      }
    }

  });
});