define('flowprosal-web-client/mirage/config', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = function () {

    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */

    //https://github.com/jsncmgs1/mirage-tutorial/blob/master/tutorial.md?utm_source=Ember+Weekly&utm_campaign=b10f69dd83-Ember_Weekly_Issue_130&utm_medium=email&utm_term=0_e96229d21d-b10f69dd83-95110945#stubbing-a-json-api-spec-api-with-ember-cli-mirage

    this.urlPrefix = 'http://localhost:3000'; // make this `http://localhost:8080`, for example, if your API is on a different server
    this.namespace = 'api/v2'; // make this `api`, for example, if your API is namespaced
    this.timing = 400; // delay for each request, automatically set to 0 during testing

    this.flowprosal = {
      access_token: 'aY_kYaXpJV_eCDyqfhF1',
      users: [{
        id: 1,
        'first-name': 'john',
        'last-name': 'wayne',
        email: 'john@aac.hu',
        password: 'secret23',
        'authentication-token': 'fF_kYaCpJW_tCHyqnhF1',
        'open-proposals': 3,
        'open-revenue': 425
      }, {
        id: 2,
        'first-name': 'alice',
        'last-name': 'mccarthy',
        email: 'alice@aac.hu',
        password: 'secret23',
        'authentication-token': 'gF_kYrCpJW_tCGyonhD4',
        'open-proposals': 5,
        'open-revenue': 225
      }]

    };

    var flowprosal = this.flowprosal;

    function formEncodedToJson(encoded) {
      var result = {};

      encoded.split("&").forEach(function (part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });

      return result;
    }

    function transformToJSONApi(type, record) {
      return { id: record.id, type: type, attributes: record };
    }

    function transformCollectionToJSONApi(type, records) {
      var data = {};
      data = records.map(function (record) {
        return transformToJSONApi(type, record);
      });

      return { data: data };
    }

    this.formEncodedToJson = formEncodedToJson;
    this.transformToJSONApi = transformToJSONApi;
    this.transformCollectionToJSONApi = transformCollectionToJSONApi;

    this.post('/sessions', function (db, request) {
      var body = {};
      try {
        body = JSON.parse(request.requestBody);
      } catch (e) {
        body = formEncodedToJson(request.requestBody);
      }
      for (var i = 0, l = flowprosal.users.length; i < l; i++) {
        var user = flowprosal.users[i];
        if (user.email === body.email && user.password === body.password) {
          delete user.password;
          return { data: transformToJSONApi('user', user) };
        }
      }

      return new _emberCliMirage['default'].Response(401, { 'Unauthorized': null }, {});
    });

    this.get('/sessions', function (db, request) {
      for (var i = 0, l = flowprosal.users.length; i < l; i++) {
        var user = flowprosal.users[i];
        if (user['authentication-token'] === request.requestHeaders['Authorization']) {
          delete user.password;
          return { data: transformToJSONApi('user', user) };
        }
      }

      if (flowprosal.access_token === request.requestHeaders['Authorization']) {
        var user = flowprosal.users[0];
        delete user.password;
        return { data: transformToJSONApi('user', user) };
      }

      return new _emberCliMirage['default'].Response(401, {}, { data: 'Unauthorized' });
    });

    this.del('/sessions', function () {
      return {};
    });

    this.get('/users/:id', function (db, request) {
      var user = db.users.find(request.params.id);
      //delete user['password'];

      return { data: transformToJSONApi('user', user) };
    });

    this.post('/users', function (db, request) {
      var body = JSON.parse(request.requestBody);
      var user = db.users.insert(body.data.attributes);
      user['authentication-token'] = flowprosal.access_token;

      return { data: transformToJSONApi('user', user) };
    });

    this.get('/contacts', function (db) {
      return transformCollectionToJSONApi('contacts', db.contacts);
    });

    this.get('/companies', function (db) {
      return transformCollectionToJSONApi('companies', db.companies);
    });

    this.get('/companies/:id', function (db, request) {
      var data = db.companies.find(request.params.id);
      if (data) {
        return {
          data: transformToJSONApi('company', data)
        };
      } else {
        return new _emberCliMirage['default'].Response(404, {}, 'Not found');
      }
    });

    this.post('/companies', function (db, request) {
      //transform from jsonapi
      var body = JSON.parse(request.requestBody);
      var newRecord = db.companies.insert(body.data.attributes);
      return { data: transformToJSONApi('company', newRecord) };
    });

    this.get('/segment-templates', function (db) {
      return transformCollectionToJSONApi('segment-template', db['segment-templates']);
    });

    this.get('/segment-templates/:id', function (db, request) {
      var data = db['segment-templates'].find(request.params.id);
      if (data) {
        return {
          data: transformToJSONApi('segment-template', data)
        };
      } else {
        return new _emberCliMirage['default'].Response(404, {}, 'Not found');
      }
    });

    this.post('/segment-templates', function (db, request) {
      //transform from jsonapi
      var body = JSON.parse(request.requestBody);
      var newRecord = db['segment-templates'].insert(body.data.attributes);
      return { data: transformToJSONApi('segment-template', newRecord) };
    });

    this.get('/clients/:id', function (db, request) {
      var client = db.clients.find(request.params.id);

      return { data: transformToJSONApi('client', client) };
    });

    this.get('/clients', function (db) {
      return transformCollectionToJSONApi('clients', db['clients']);
    });

    this.get('/clients/:id/contacts', function (db) {
      return transformCollectionToJSONApi('contacts', db['contacts']);
    });

    this.get('/proposals/:id', function (db, request) {
      var data = db.proposals.find(request.params.id);

      return { data: transformToJSONApi('proposal', data) };
    });

    this.get('/proposals', function (db) {
      return transformCollectionToJSONApi('proposals', db['proposals']);
    });

    this.post('/proposals', function (db, request) {
      var body = JSON.parse(request.requestBody);
      var newRecord = db['proposals'].insert(body.data.attributes);
      return { data: transformToJSONApi('paymentMilestone', newRecord) };
    });

    this.patch('/proposals/:id', function (db, request) {
      var body = JSON.parse(request.requestBody);
      db.proposals.update(body.id, body.data.attributes);

      var proposalData = db.proposals.find(request.params.id);

      return { data: transformToJSONApi('proposal', proposalData) };
    });

    this.post('/payment-milestones', function (db, request) {
      var body = JSON.parse(request.requestBody);
      var newRecord = db['payment-milestones'].insert(body.data.attributes);
      return { data: transformToJSONApi('paymentMilestone', newRecord) };
    });

    this.get('/public/proposals', function (db) {
      return transformCollectionToJSONApi('proposals', db['proposals']);
    });

    /*
      Route shorthand cheatsheet
    */
    /*
      GET shorthands
       // Collections
      this.get('/contacts');
      this.get('/contacts', 'users');
      this.get('/contacts', ['contacts', 'addresses']);
       // Single objects
      this.get('/contacts/:id');
      this.get('/contacts/:id', 'user');
      this.get('/contacts/:id', ['contact', 'addresses']);
    */

    /*
      POST shorthands
       this.post('/contacts');
      this.post('/contacts', 'user'); // specify the type of resource to be created
    */

    /*
      PUT shorthands
       this.put('/contacts/:id');
      this.put('/contacts/:id', 'user'); // specify the type of resource to be updated
    */

    /*
      DELETE shorthands
       this.del('/contacts/:id');
      this.del('/contacts/:id', 'user'); // specify the type of resource to be deleted
       // Single object + related resources. Make sure parent resource is first.
      this.del('/contacts/:id', ['contact', 'addresses']);
    */

    /*
      Function fallback. Manipulate data in the db via
         - db.{collection}
        - db.{collection}.find(id)
        - db.{collection}.where(query)
        - db.{collection}.update(target, attrs)
        - db.{collection}.remove(target)
       // Example: return a single object with related models
      this.get('/contacts/:id', function(db, request) {
        var contactId = +request.params.id;
         return {
          contact: db.contacts.find(contactId),
          addresses: db.addresses.where({contact_id: contactId})
        };
      });
     */
  };

  /*
  You can optionally export a config that is only loaded during tests
  export function testConfig() {
  
  }
  */
});