define('flowprosal-web-client/components/modal-change-request', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], {

    displayErrors: false,

    validations: {
      'model.title': {
        presence: true
      },
      'model.description': {
        presence: true
      },
      'model.price': {
        presence: true
      }
    },

    isShowingScope: true,

    actions: {

      closeModal: function closeModal() {
        this.sendAction('on-cancel');
      },

      showNewPaymentPanel: function showNewPaymentPanel() {
        var model = this.get('model');
        this.set('scopeTitleValue', model.get('title'));
        model.set('title', 'new payment request');
        model.set('scope', false);
      },

      showScopePanel: function showScopePanel() {
        var model = this.get('model');
        model.set('title', this.get('scopeTitleValue'));
        model.set('scope', true);
      },

      addChangeRequest: function addChangeRequest() {
        this.set('displayErrors', !this.get('isValid'));

        if (!this.get('isValid')) {
          return false;
        }

        this.sendAction('requestChange', this.get('model'));
      }
    }
  });
});