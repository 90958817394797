define('flowprosal-web-client/mirage/factories/segment-template', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {

  function twoWords() {
    return _emberCliMirage.faker.lorem.words(2).join(' ');
  }

  exports['default'] = _emberCliMirage['default'].Factory.extend({
    title: twoWords,
    content: _emberCliMirage.faker.lorem.sentence,
    price: _emberCliMirage.faker.finance.amount,
    company: 1,
    favorite: _emberCliMirage.faker.random.boolean
  });
});
/*
  This is an example factory definition.

  Create more files in this directory to define additional factories.
*/