define('flowprosal-web-client/macros/all', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = allTruthy;

  function allTruthy() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ember['default'].computed.apply(_ember['default'], args.concat([function () {
      var _this = this;

      return args.reduce(function (isAllTrue, key) {
        return isAllTrue && !_ember['default'].isEmpty(_this.get(key));
      }, true);
    }]));
  }
});