define('flowprosal-web-client/components/perfect-scroll', ['exports', 'ember'], function (exports, _ember) {
  var $ = _ember['default'].$;
  var on = _ember['default'].on;
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  var guidFor = _ember['default'].guidFor;
  exports['default'] = _ember['default'].Component.extend({
    // Internal id for element
    scrollId: null,
    classNames: ['ps-wrapper'],

    // Perfect scrollbar related settings
    wheelSpeed: 1,
    wheelPropagation: false,
    swipePropagation: true,
    minScrollbarLength: null,
    maxScrollbarLength: null,
    useBothWheelAxes: false,
    useKeyboard: true,
    suppressScrollX: false,
    suppressScrollY: false,
    scrollTop: 0,
    scrollLeft: 0,
    scrollXMarginOffset: 0,
    scrollYMarginOffset: 0,
    includePadding: false,

    _renderPerfectScroll: on('didInsertElement', function () {
      var _this = this;

      run.schedule("afterRender", this, function () {
        var elementId = _this.get('id') ? '#' + _this.get('id') : '#' + guidFor(_this);
        window.Ps.initialize($(elementId)[0], _this._getOptions());
      });
    }),

    _getOptions: function _getOptions() {
      return {
        wheelSpeed: get(this, 'wheelSpeed'),
        wheelPropagation: get(this, 'wheelPropagation'),
        swipePropagation: get(this, 'swipePropagation'),
        minScrollbarLength: get(this, 'minScrollbarLength'),
        maxScrollbarLength: get(this, 'maxScrollbarLength'),
        useBothWheelAxes: get(this, 'useBothWheelAxes'),
        useKeyboard: get(this, 'useKeyboard'),
        suppressScrollX: get(this, 'suppressScrollX'),
        suppressScrollY: get(this, 'suppressScrollY'),
        scrollXMarginOffset: get(this, 'scrollXMarginOffset'),
        scrollYMarginOffset: get(this, 'scrollYMarginOffset'),
        includePadding: get(this, 'includePadding'),
        scrollTop: get(this, 'scrollTop'),
        scrollLeft: get(this, 'scrollLeft')
      };
    }
  });
});