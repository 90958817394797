define("flowprosal-web-client/templates/components/signature-pad", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/components/signature-pad.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("canvas");
        dom.setAttribute(el1, "id", "signature-canvas");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'width');
        morphs[2] = dom.createAttrMorph(element0, 'height');
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["get", "canvasClassnames", ["loc", [null, [1, 39], [1, 55]]]]]]], ["attribute", "width", ["concat", [["get", "canvasWidth", ["loc", [null, [1, 68], [1, 79]]]]]]], ["attribute", "height", ["concat", [["get", "canvasHeight", ["loc", [null, [1, 93], [1, 105]]]]]]], ["inline", "yield", [["subexpr", "action", ["clear"], [], ["loc", [null, [2, 8], [2, 24]]]]], [], ["loc", [null, [2, 0], [2, 26]]]]],
      locals: [],
      templates: []
    };
  })());
});