define('flowprosal-web-client/components/proposal-cover-letter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    partialPath: _ember['default'].computed('model.proposalTemplate', function () {
      return 'proposals/headers/' + this.get('model.proposalTemplate');
    }),

    actions: {
      updateSegmentAttr: function updateSegmentAttr(segment) {
        if (this.get('isEditable')) {
          this.sendAction('updateSegmentAttr', segment);
        }
      }
    }
  });
});