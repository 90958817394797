define('flowprosal-web-client/controllers/proposals/edit/payment-settings', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    gratuity: _ember['default'].computed('model.gratuity', function () {
      return this.model.get('gratuity');
    }),

    selectedIndividual: _ember['default'].computed('model.scheduledPayment', function () {
      return this.model.get('scheduledPayment');
    }),

    onePaymentMilestone: _ember['default'].computed('model.paymentMilestones.length', function () {
      var proposal = this.model,
          onePaymentMilestone;

      onePaymentMilestone = proposal.get('paymentMilestones').findBy('paymentMilestoneType', 'one_payment');

      if (!onePaymentMilestone) {
        onePaymentMilestone = this.store.createRecord('paymentMilestone');
        onePaymentMilestone.set('paymentMilestoneType', 'one_payment');
        onePaymentMilestone.set('percentage', 100);
      }

      return onePaymentMilestone;
    }),

    plainMilestones: _ember['default'].computed('model.paymentMilestones.length', function () {
      return this.get('model.paymentMilestones').filter(function (milestone) {
        return !milestone.get('paymentMilestoneType');
      });
    }),

    firstMilestone: _ember['default'].computed.alias('plainMilestones.firstObject'),
    restMilestones: _ember['default'].computed('plainMilestones.[]', function () {
      return this.get('plainMilestones').without(this.get('firstMilestone'));
    }),

    finalPayment: _ember['default'].computed('plainMilestones', 'model.paymentMilestones.content.@each.percentage', function () {
      var result = 100;

      this.get('plainMilestones').forEach(function (plainMilestone) {
        if (!isNaN(plainMilestone.get('percentage'))) {
          result -= plainMilestone.get('percentage');
        }
      });

      return result;
    }),

    actions: {
      setScheduledPayment: function setScheduledPayment(value) {
        this.model.set('scheduledPayment', value);
      },

      closeModal: function closeModal() {
        this._closeModal();
      },

      updateSettings: function updateSettings() {
        this.set('displayErrors', true);

        if (!this._validate()) {
          return false;
        }

        var proposal = this.model;
        proposal.set('scheduledPayment', this.model.get('scheduledPayment'));
        proposal.set('gratuity', this.get('gratuity'));
        proposal.save();

        if (this.model.get('scheduledPayment')) {
          this._saveScheduledPayment();
        } else {
          this._saveOnePayment();
        }
      },

      addPlainMilestone: function addPlainMilestone() {
        var proposal = this.model,
            plainMilestone = this.store.createRecord('paymentMilestone');

        proposal.get('paymentMilestones').pushObject(plainMilestone);
      },

      removePlainMilestone: function removePlainMilestone() {
        if (confirm('Are you sure?')) {
          this.get('plainMilestones.lastObject').destroyRecord();
        }
      }
    },

    _saveOnePayment: function _saveOnePayment() {
      var _this = this,
          proposal = this.model,
          onePaymentMilestone = this.get('onePaymentMilestone');

      if (!onePaymentMilestone.id) {
        proposal.get('paymentMilestones').pushObject(onePaymentMilestone);
      }

      onePaymentMilestone.save().then(function () {
        _this._closeModal();
      });
    },

    _saveScheduledPayment: function _saveScheduledPayment() {
      var promises = [],
          _this = this;

      this.get('model.paymentMilestones').forEach(function (plainMilestone) {
        if (plainMilestone.get('hasDirtyAttributes')) {
          promises.push(plainMilestone.save());
        }
      });

      _ember['default'].RSVP.Promise.all(promises).then(function () {
        _this._closeModal();
      });
    },

    _validate: function _validate() {
      if (!this.model.get('scheduledPayment') && !this.get('onePaymentMilestone.description')) {
        return false;
      }

      var result = true;

      if (this.get('finalPayment') !== 0) {
        result = false;
      }

      this.get('plainMilestones').forEach(function (paymentMilestone) {
        if (!paymentMilestone.get('isValid')) {
          result = false;
        }
      });

      return result;
    },

    _closeModal: function _closeModal() {
      this.model.rollbackAttributes();

      this.model.get('paymentMilestones').forEach(function (paymentMilestone) {
        paymentMilestone.rollbackAttributes();
      });

      this.transitionToRoute('proposals.edit', this.model);
    }
  });
});