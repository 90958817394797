define('flowprosal-web-client/components/modal-ask-visitor-name', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      closeModal: function closeModal() {
        //do nothing, so the modal remains on the screen
      },

      setName: function setName() {
        var name = this.get('visitorName');
        if (name) {
          this.sendAction('setName', name);
        }
      }
    }
  });
});