define('flowprosal-web-client/controllers/proposals/new', ['exports', 'ember', 'ember-validations', 'flowprosal-web-client/macros/error-if-empty', 'flowprosal-web-client/macros/error-unless-email'], function (exports, _ember, _emberValidations, _flowprosalWebClientMacrosErrorIfEmpty, _flowprosalWebClientMacrosErrorUnlessEmail) {
  exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
    queryParams: ['clientId', 'contactId'],
    clientId: null,
    contactId: null,

    newClient: _ember['default'].computed(function () {
      return this.store.createRecord('client');
    }),

    newContact: null,
    proposal: null,

    hasClientId: _ember['default'].computed.notEmpty('clientId'),
    hasContactId: _ember['default'].computed.notEmpty('contactId'),
    noClientId: _ember['default'].computed.not('hasClientId'),
    noContactId: _ember['default'].computed.not('hasContactId'),

    createClientStep: _ember['default'].computed.and('noClientId', 'noContactId'),
    createContactStep: _ember['default'].computed.and('hasClientId', 'noContactId'),
    buildProposalStep: _ember['default'].computed.and('hasClientId', 'hasContactId'),

    displayErrors: false,

    clientNameError: (0, _flowprosalWebClientMacrosErrorIfEmpty['default'])('newClient.name', 'Name is required'),
    clientPhoneError: (0, _flowprosalWebClientMacrosErrorIfEmpty['default'])('newClient.phone', 'Phone is required'),
    clientEmailError: (0, _flowprosalWebClientMacrosErrorUnlessEmail['default'])('newClient.email', 'Email needs to be an email'),

    hasClientError: _ember['default'].computed.or('clientNameError', 'clientPhoneError', 'clientEmailError'),
    isClientValid: _ember['default'].computed.not('hasClientError'),

    contactFirstNameError: (0, _flowprosalWebClientMacrosErrorIfEmpty['default'])('newContact.firstName', 'First name is required'),
    contactLastNameError: (0, _flowprosalWebClientMacrosErrorIfEmpty['default'])('newContact.lastName', 'Last name is required'),
    contactEmailError: (0, _flowprosalWebClientMacrosErrorUnlessEmail['default'])('newContact.email', 'Email needs to be an email', { allowEmpty: true }),
    hasContactError: _ember['default'].computed.or('contactFirstNameError', 'contactLastNameError', 'contactEmailError'),
    isContactValid: _ember['default'].computed.not('hasContactError'),

    actions: {
      updateClient: function updateClient(field, value) {
        this.get('newClient').set(field, value);
      },

      assignClient: function assignClient() {
        var _this = this;

        function goToNextPhase(client) {
          this.set('displayErrors', false);
          this.set('clientId', client.get('id'));
          this.set('selectedClient', client);
          this.set('newContact', this.store.createRecord('contact', { client: client }));
          //this.transitionToRoute({ queryParams: { clientId: client.get('id') } });
        }

        var selectedClient = this.get('selectedClient');
        if (selectedClient) {
          goToNextPhase.call(this, selectedClient);
          return;
        }

        if (this.get('hasClientError')) {
          this.set('displayErrors', true);
          return;
        }

        var newClient = this.get('newClient');
        newClient.set('company', this.session.get('currentCompany'));

        newClient.save().then(function (client) {
          goToNextPhase.call(_this, client);
        })['catch'](function () {
          _this.flashMessages.danger("Client could not be saved.");
        });
      },

      selectClient: function selectClient(client) {
        //const client = this.store.peekRecord('client', clientId);
        this.set('selectedClient', client);
        this.set('displayErrors', false);
      },

      assignContact: function assignContact() {
        var _this2 = this;

        var controller = this;
        var currentUser = this.session.get('currentUser');
        var currentCompany = this.session.get('currentCompany');

        function goToNextPhase(controller, client, contact, currentUser, currentCompany) {
          controller.set('displayErrors', false);
          controller.store.createRecord('proposal', {
            client: client,
            contact: contact,
            user: currentUser,
            company: currentCompany
          }).save().then(function (proposal) {
            controller.transitionToRoute('proposals.edit', proposal);
          });
        }

        var selectedClient = this.get('selectedClient');
        var selectedContact = this.get('selectedContact');
        if (selectedContact) {
          goToNextPhase.call(this, controller, selectedClient, selectedContact, currentUser, currentCompany);
          return;
        }

        if (this.get('hasContactError')) {
          this.set('displayErrors', true);
          return;
        }

        var newContact = this.get('newContact');
        newContact.set('client', selectedClient);
        newContact.set('company', this.session.get('currentCompany'));

        newContact.save().then(function (contact) {
          goToNextPhase.call(_this2, controller, selectedClient, contact, currentUser, currentCompany);
        })['catch'](function () {
          _this2.flashMessages.danger("Contact could not be saved.");
        });
      },

      selectContact: function selectContact(contact) {
        //const contact = this.store.peekRecord('contact', contactId);
        this.set('selectedContact', contact);
        this.set('displayErrors', false);
      },

      addSegment: function addSegment(segmentTemplate) {
        var segment = this.store.createRecord('segment', segmentTemplate.getProperties('title', 'content', 'price'));
        this.get('proposal.segments').pushObject(segment);
      }
    }

  });
});