define('flowprosal-web-client/models/contact', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    firstName: _emberData['default'].attr('string'),
    lastName: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    phone: _emberData['default'].attr('string'),
    notes: _emberData['default'].attr('string'),
    facebookUrl: _emberData['default'].attr('string'),
    avatar: _emberData['default'].attr(),
    client: _emberData['default'].belongsTo('client', { async: true }),
    company: _emberData['default'].belongsTo('company', { async: true }),
    proposals: _emberData['default'].hasMany('proposal', { async: true }),

    fullName: _ember['default'].computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });
});