define('flowprosal-web-client/controllers/password/forgotten', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
    displayErrors: false,

    validations: {
      'email': {
        presence: true,
        email: true
      }
    },

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.set('displayErrors', true);

        if (!this.get('isValid')) {
          return false;
        }

        var url = this.container.lookup('adapter:application').buildURL('public/passwords');

        _ember['default'].$.ajax({
          url: url,
          type: 'POST',
          data: { email: this.get('email') }
        }).done(function () {
          _this.set('displayErrors', false);
          _this.set('email', null);

          _this.notifications.addNotification({
            message: 'We have sent the further instructions to your E-mail.',
            type: 'success',
            autoClear: true,
            clearDuration: 4200
          });
        }).fail(function (response) {
          if (response.status === 404) {
            _this.notifications.addNotification({
              message: 'We could not find the E-mail you specified.',
              type: 'error'
            });
          } else {
            _this.notifications.addNotification({
              message: 'Something went wrong, please try later.',
              type: 'error'
            });
          }
        });
      }
    }
  });
});