define('flowprosal-web-client/torii-adapters/facebook-connect', ['exports', 'ember', 'flowprosal-web-client/config/environment', 'flowprosal-web-client/utils/camelize-keys'], function (exports, _ember, _flowprosalWebClientConfigEnvironment, _flowprosalWebClientUtilsCamelizeKeys) {
  exports['default'] = _ember['default'].Object.extend({
    open: function open(authorization) {
      var that = this;
      var authorizationCode = authorization.accessToken;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax({
          url: _flowprosalWebClientConfigEnvironment['default'].apiHost + '/api/v2/sessions/omniauth/facebook',
          type: 'POST',
          data: { 'provider': 'facebook', 'accessToken': authorizationCode },
          dataType: 'json',
          success: _ember['default'].run.bind(null, resolve),
          error: _ember['default'].run.bind(null, reject)
        });
      }).then(function (authorization) {
        var user = that.store.createRecord('user', (0, _flowprosalWebClientUtilsCamelizeKeys['default'])(authorization.data.attributes));
        var companyId = '';
        user.set('id', authorization.data.id);
        window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].authTokenName, user.get('authenticationToken'));

        if (!companyId && authorization.data.attributes['single-company']) {
          companyId = authorization.data.attributes['single-company'];
          window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier, companyId);
        }

        if (companyId) {
          var company = that.store.peekRecord('company', companyId);
          if (!company) {
            company = that.store.createRecord('company');
            company.set('id', companyId);
          }

          return { currentUser: user, currentCompany: company };
        } else {
          return { currentUser: user };
        }
      });
    }
  });
});