define('flowprosal-web-client/mixins/save-contact-mixin', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  exports['default'] = _ember['default'].Mixin.create({
    queryParams: ['assignToProposal', 'clientId'],
    displayErrors: false,

    validations: {
      'client': {
        inline: (0, _emberValidations.validator)(function () {
          if (!this.get('client.id')) {
            return "You have to select a client.";
          }
        })
      },

      'model.firstName': {
        presence: true
      },
      'model.lastName': {
        presence: true
      },
      'model.email': {
        presence: true,
        email: true
      },
      'model.phone': {
        presence: true
      }
    },

    displayClientSelector: _ember['default'].computed('clientId', function () {
      return this.get('clientId') ? false : true;
    }),

    client: _ember['default'].computed('model.client', function () {
      var _this = this;

      if (this.get('clientId')) {
        var promise = this.store.findRecord('client', this.get('clientId'));

        promise.then(function () {
          _this.validate();
        });

        return promise;
      } else {
        return this.get('model.client');
      }
    }),

    _assignToProposal: function _assignToProposal() {
      var _this2 = this;

      var proposal = this.get('proposal');

      proposal.set('contact', this.model);

      proposal.save().then(function () {
        _this2.transitionToRoute('proposals.edit', proposal);
      });
    },

    _closeModal: function _closeModal() {
      if (this.get('assignToProposal')) {
        this.transitionToRoute('proposals.edit', this.get('assignToProposal'));
      } else {
        if (this.model.get('isNew')) {
          this.transitionToRoute('contacts.index');
        } else {
          this.transitionToRoute('contacts.show', this.model);
        }
      }
    },

    actions: {
      save: function save() {
        var _this3 = this;

        this.set('displayErrors', !this.get('isValid') || !this.get('phoneIsValid'));

        if (!this.get('isValid') || !this.get('phoneIsValid')) {
          return false;
        }

        var contact = this.model;

        contact.set('client', this.get('client'));
        contact.set('company', this.session.get('currentCompany'));
        contact.save().then(function () {
          if (_this3.get('clientId')) {
            _this3._assignToProposal();
          } else {
            _this3._closeModal();
          }
        });
      },

      closeModal: function closeModal() {
        this._closeModal();
      }
    }
  });
});