define('flowprosal-web-client/validators/local/date', ['exports', 'ember-validations/validators/base'], function (exports, _emberValidationsValidatorsBase) {
  exports['default'] = _emberValidationsValidatorsBase['default'].extend({
    call: function call() {
      var dateString = this.model.get(this.property);

      var dateParts = /^(\d{4})[-\/\.](\d{2})[-\/\.](\d{2})$/.exec(dateString);

      if (dateParts === null) {
        this.errors.pushObject('Invalid date format.');
        return false;
      }

      var date = new Date(dateString),
          providedMonth = parseInt(dateParts[2]);

      if (!date.getDate() || date.getMonth() !== providedMonth - 1) {
        this.errors.pushObject('Invalid date.');
      }
    }
  });
});