define("flowprosal-web-client/router", ["exports", "ember", "flowprosal-web-client/config/environment"], function (exports, _ember, _flowprosalWebClientConfigEnvironment) {

  var Router = _ember["default"].Router.extend({
    location: _flowprosalWebClientConfigEnvironment["default"].locationType,
    metrics: _ember["default"].inject.service(),

    menustate: _ember["default"].inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();

      this.get('menustate').set('isMenuOpen', false);
    },

    _trackPage: function _trackPage() {
      var _this = this;

      _ember["default"].run.scheduleOnce('afterRender', this, function () {
        var page = document.location.pathname;
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        _ember["default"].get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    //general uikit page
    this.route("uikit");

    this.route("terms-of-use");

    this.route("login", { path: '/' });
    this.route("password", function () {
      this.route("forgotten");
      this.route("new");
    });
    this.route("signup");
    this.authenticatedRoute("bootstrap", function () {
      this.route("company");
      this.route("team", { path: ":company_id/team" });
      this.route("services", { path: ":company_id/services" });
    });

    this.authenticatedRoute("company-selector");

    this.authenticatedRoute("dashboard", function () {
      this.route("search", function () {
        this.route("index");
      });

      this.route("clients", { resetNamespace: true }, function () {
        this.route("new");
        this.route("edit", { path: ":client_id/edit" });
        this.route("show", { path: ":client_id" });
      });

      this.route("companies", { resetNamespace: true }, function () {
        this.route("new");
        this.route("edit", { path: ":company_id/edit" });
        this.route("show", { path: ":company_id" });
      });

      this.route("contacts", { resetNamespace: true }, function () {
        this.route("new");
        this.route("edit", { path: ":contact_id/edit" });
        this.route("show", { path: ":contact_id" });
      });

      this.route("proposals", { resetNamespace: true }, function () {
        this.route("new");
        this.route("edit", { path: ":proposal_id/edit" }, function () {
          this.route("select-segment");
          this.route("payment-settings");
          this.route("send");
        });
        this.route("show", { path: ":proposal_id" }, function () {
          this.route("segments");
          this.route("payment-milestones", function () {
            this.route("show", { path: ":payment_milestone_id" });
          });
        });
      });

      this.route("segments", { resetNamespace: true }, function () {
        this.route("new");
        this.route("edit", { path: ":segment_id/edit" });
        this.route("show", { path: ":segment_id" });
      });

      this.route("transactions");

      this.route("account", function () {
        this.route('payments', function () {
          this.route("show", { path: ":subscription_payment_id" });
        });
      });
      this.route("team");

      this.route('settings', function () {
        this.route('payment');
      });

      this.route("library");
    });

    //Public interface for submitted proposals
    this.route("public-proposal", { path: ":company_name/proposals/:proposal_token" }, function () {
      this.route('payment-milestones', function () {
        this.route("show", { path: ":payment_milestone_id" });
        this.route("pay", { path: ":payment_milestone_id/pay" });
      });
      this.route('change-requests', function () {
        this.route('show', { path: ':change_request_id' });
      });
    });

    this.route('not-found', { path: '/*path' });
  });

  exports["default"] = Router;
});