define('flowprosal-web-client/components/service-segment-template', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    session: _ember['default'].inject.service('session'),

    title: null,
    content: null,
    price: null,
    isPrimary: false,
    favorite: true,
    errors: null,

    displayErrors: false,

    createErrors: _ember['default'].on('init', function () {
      this.set('errors', _ember['default'].Object.create({
        title: "Title is required",
        content: "Content is required"
      }));
    }),

    isTitleValid: _ember['default'].computed.empty('errors.title'),
    isContentValid: _ember['default'].computed.empty('errors.content'),
    isValid: _ember['default'].computed.and('isTitleValid', 'isContentValid'),

    _updateField: function _updateField(field, value) {
      this.set(field, value);
      this.attrs['on-update'](field, value, this.get('isValid'));
    },

    setHeaders: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return { "Authorization": this.get('session.currentUser.authenticationToken'), "CompanyIdentifier": this.get('session.currentCompany.id') };
    }),

    dzAddedFile: function dzAddedFile() {
      if (this.dropzone.files.length > 1) {
        this.dropzone.removeFile(this.dropzone.files[0]);
      }
    },

    registerSelfListener: _ember['default'].on('didInsertElement', function () {
      this.sendAction('registerSelf', this);
    }),

    teardownSelfListener: _ember['default'].on('willDestroyElement', function () {
      this.sendAction('teardownSelf', this);
    }),

    uploadSegmentImage: function uploadSegmentImage() {
      var _this = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (_this.get('model.id')) {
          var currentDropZone = Dropzone.forElement(_this.$('.dropzone')[0]);
          if (currentDropZone.files.length > 0) {
            var adapter = _this.container.lookup('adapter:application');

            currentDropZone.options.url = adapter.buildURL('segment-templates', _this.get('model.id')) + '/upload_image';
            currentDropZone.options.headers = _this.get('setHeaders');

            currentDropZone.on('complete', function () {
              resolve();
            });

            currentDropZone.processQueue();
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },

    actions: {

      removeSegment: function removeSegment() {
        this.attrs['on-remove']();
        return false;
      },

      titleDidChange: function titleDidChange(value) {
        this.set('errors.title', _ember['default'].isEmpty(value) ? "Title is required" : null);
        this._updateField('title', value);
      },

      contentDidChange: function contentDidChange(value) {
        this.set('errors.content', _ember['default'].isEmpty(value) ? "Content is required" : null);
        this._updateField('content', value);
      },

      priceDidChange: function priceDidChange(value) {
        this._updateField('price', value);
      },

      favoriteChanged: function favoriteChanged(value) {
        this._updateField('favorite', value);
      }

    }
  });
});