define('flowprosal-web-client/controllers/signup', ['exports', 'ember', 'ember-data', 'ember-validations', 'flowprosal-web-client/config/environment'], function (exports, _ember, _emberData, _emberValidations, _flowprosalWebClientConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
    email: '',
    password: '',
    passwordConfirmation: '',
    firstName: '',
    lastName: '',

    displayErrors: false,

    validations: {
      email: {
        format: {
          'with': /@/, // the real validation is being able to reply to an email sent to that address
          message: "Email needs to be an email"
        }
      },
      password: {
        length: {
          minimum: 8,
          messages: {
            tooShort: "Password needs at least 8 characters"
          }
        },
        confirmation: {
          message: "Password confirmation doesn't match"
        }
      },
      firstName: {
        presence: { message: "First name is required" }
      },
      lastName: {
        presence: { message: "Last name is required" }
      }
    },

    actions: {
      signup: function signup() {
        var _this = this;

        var myErrorMessage = "Validation failed";
        this.set('displayErrors', false);
        this.validate().then(function () {
          var user = _this.store.createRecord('user', _this.getProperties('email', 'password', 'firstName', 'lastName'));
          return user.save();
        }, function () {
          throw myErrorMessage;
        }).then(function (user) {
          window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].authTokenName, user.get('authenticationToken'));
          _this.session.fetch().then(function () {
            return _this.transitionToRoute('bootstrap.company');
          });
        })['catch'](function (error) {
          if (error === myErrorMessage) {
            return;
          }
          var baseError = error instanceof _emberData['default'].InvalidError ? "Email already taken" : "Couldn't save user";
          _this.set('errors.base', baseError);
        })['finally'](function () {
          _this.set('displayErrors', true);
        });
      }
    }
  });
});