define("flowprosal-web-client/validators/local/email", ["exports", "ember-validations/validators/base"], function (exports, _emberValidationsValidatorsBase) {
  exports["default"] = _emberValidationsValidatorsBase["default"].extend({
    call: function call() {
      var email = this.model.get(this.property);

      if (!email) {
        this.errors.pushObject("Must include an email");
      } else if (!email.match(/^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i)) {
        this.errors.pushObject("Must be formatted like an email");
      }
    }
  });
});