define("flowprosal-web-client/templates/proposals/edit/payment-settings", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 12
                  },
                  "end": {
                    "line": 28,
                    "column": 12
                  }
                },
                "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "checkbox-switch-wrapper");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                dom.setAttribute(el2, "for", "chx-is-deposit");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "input", [], ["type", "checkbox", "id", "chx-is-deposit", "class", "checkbox-switch", "checked", ["subexpr", "@mut", [["get", "firstMilestone.deposit", ["loc", [null, [25, 90], [25, 112]]]]], [], []]], ["loc", [null, [25, 14], [25, 114]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 6
                },
                "end": {
                  "line": 49,
                  "column": 6
                }
              },
              "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row row-plain-milestone");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-2 col-md-2 col-sm-2");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "deposit-label");
              var el4 = dom.createTextNode("Deposit");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-3 col-md-3 col-sm-3");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4, "class", "input__label input__label--ruri");
              dom.setAttribute(el4, "for", "input-name");
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "input__label-content input__label-content--ruri");
              var el6 = dom.createTextNode("% PAYMENT");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-6 col-md-6 col-sm-6");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4, "class", "input__label input__label--ruri");
              dom.setAttribute(el4, "for", "input-name");
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "input__label-content input__label-content--ruri");
              var el6 = dom.createTextNode("DESCRIPTION");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [3, 1]);
              var element7 = dom.childAt(element5, [5, 1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
              morphs[1] = dom.createAttrMorph(element6, 'class');
              morphs[2] = dom.createMorphAt(element6, 1, 1);
              morphs[3] = dom.createAttrMorph(element7, 'class');
              morphs[4] = dom.createMorphAt(element7, 1, 1);
              return morphs;
            },
            statements: [["block", "tooltip-message", [], ["message", "Make this payment required after Proposal is Accepted", "position", "top", "theme", "dark"], 0, null, ["loc", [null, [23, 12], [28, 32]]]], ["attribute", "class", ["concat", ["input input--ruri ", ["subexpr", "if", [["subexpr", "not-empty", [["get", "firstMilestone.percentage", ["loc", [null, [32, 59], [32, 84]]]]], [], ["loc", [null, [32, 48], [32, 85]]]], "input--filled"], [], ["loc", [null, [32, 43], [32, 103]]]], " ", ["subexpr", "if", [["subexpr", "and", [["get", "displayErrors", ["loc", [null, [32, 114], [32, 127]]]], ["get", "firstMilestone.errors.percentage", ["loc", [null, [32, 128], [32, 160]]]]], [], ["loc", [null, [32, 109], [32, 161]]]], "error", ""], [], ["loc", [null, [32, 104], [32, 174]]]]]]], ["inline", "input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "firstMilestone.percentage", ["loc", [null, [33, 42], [33, 67]]]]], [], []], "class", "input__field input__field--ruri"], ["loc", [null, [33, 14], [33, 109]]]], ["attribute", "class", ["concat", ["input input--ruri ", ["subexpr", "if", [["subexpr", "not-empty", [["get", "firstMilestone.description", ["loc", [null, [41, 59], [41, 85]]]]], [], ["loc", [null, [41, 48], [41, 86]]]], "input--filled"], [], ["loc", [null, [41, 43], [41, 104]]]], " ", ["subexpr", "if", [["subexpr", "and", [["get", "displayErrors", ["loc", [null, [41, 115], [41, 128]]]], ["get", "firstMilestone.errors.description", ["loc", [null, [41, 129], [41, 162]]]]], [], ["loc", [null, [41, 110], [41, 163]]]], "error", ""], [], ["loc", [null, [41, 105], [41, 176]]]]]]], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "firstMilestone.description", ["loc", [null, [42, 40], [42, 66]]]]], [], []], "class", "input__field input__field--ruri"], ["loc", [null, [42, 14], [42, 108]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 6
                },
                "end": {
                  "line": 70,
                  "column": 6
                }
              },
              "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row row-plain-milestone");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-3 col-md-3 col-sm-3 col-lg-offset-2 col-md-offset-2 col-sm-offset-2");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4, "class", "input__label input__label--ruri");
              dom.setAttribute(el4, "for", "input-name");
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "input__label-content input__label-content--ruri");
              var el6 = dom.createTextNode("% PAYMENT");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-6 col-md-6 col-sm-6");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4, "class", "input__label input__label--ruri");
              dom.setAttribute(el4, "for", "input-name");
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "input__label-content input__label-content--ruri");
              var el6 = dom.createTextNode("DESCRIPTION");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [1, 1]);
              var element4 = dom.childAt(element2, [3, 1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element3, 'class');
              morphs[1] = dom.createMorphAt(element3, 1, 1);
              morphs[2] = dom.createAttrMorph(element4, 'class');
              morphs[3] = dom.createMorphAt(element4, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["input input--ruri ", ["subexpr", "if", [["subexpr", "not-empty", [["get", "milestone.percentage", ["loc", [null, [53, 59], [53, 79]]]]], [], ["loc", [null, [53, 48], [53, 80]]]], "input--filled"], [], ["loc", [null, [53, 43], [53, 98]]]], " ", ["subexpr", "if", [["subexpr", "and", [["get", "displayErrors", ["loc", [null, [53, 109], [53, 122]]]], ["get", "milestone.errors.percentage", ["loc", [null, [53, 123], [53, 150]]]]], [], ["loc", [null, [53, 104], [53, 151]]]], "error", ""], [], ["loc", [null, [53, 99], [53, 164]]]]]]], ["inline", "input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "milestone.percentage", ["loc", [null, [54, 42], [54, 62]]]]], [], []], "class", "input__field input__field--ruri"], ["loc", [null, [54, 14], [54, 104]]]], ["attribute", "class", ["concat", ["input input--ruri ", ["subexpr", "if", [["subexpr", "not-empty", [["get", "milestone.description", ["loc", [null, [62, 59], [62, 80]]]]], [], ["loc", [null, [62, 48], [62, 81]]]], "input--filled"], [], ["loc", [null, [62, 43], [62, 99]]]], " ", ["subexpr", "if", [["subexpr", "and", [["get", "displayErrors", ["loc", [null, [62, 110], [62, 123]]]], ["get", "milestone.errors.description", ["loc", [null, [62, 124], [62, 152]]]]], [], ["loc", [null, [62, 105], [62, 153]]]], "error", ""], [], ["loc", [null, [62, 100], [62, 166]]]]]]], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "milestone.description", ["loc", [null, [63, 40], [63, 61]]]]], [], []], "class", "input__field input__field--ruri"], ["loc", [null, [63, 14], [63, 103]]]]],
            locals: ["milestone"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 4
              },
              "end": {
                "line": 78,
                "column": 4
              }
            },
            "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row row-plain-milestone");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "pull-right add-and-remove-buttons");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "btn btn-blue btn-round");
            var el4 = dom.createTextNode("-");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "btn btn-green btn-round");
            var el4 = dom.createTextNode("+");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [3, 1]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element8, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createElementMorph(element9);
            morphs[3] = dom.createElementMorph(element10);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "firstMilestone", ["loc", [null, [20, 12], [20, 26]]]]], [], 0, null, ["loc", [null, [20, 6], [49, 13]]]], ["block", "each", [["get", "restMilestones", ["loc", [null, [50, 14], [50, 28]]]]], [], 1, null, ["loc", [null, [50, 6], [70, 15]]]], ["element", "action", ["removePlainMilestone"], [], ["loc", [null, [74, 46], [74, 79]]]], ["element", "action", ["addPlainMilestone"], [], ["loc", [null, [75, 47], [75, 77]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 8
                },
                "end": {
                  "line": 86,
                  "column": 8
                }
              },
              "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          Due Upon Completion\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 8
                },
                "end": {
                  "line": 93,
                  "column": 8
                }
              },
              "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          Due Before Starting\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 78,
                "column": 4
              },
              "end": {
                "line": 95,
                "column": 4
              }
            },
            "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "radio-buttons-wrapper");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element1, 1, 1);
            morphs[1] = dom.createMorphAt(element1, 2, 2);
            return morphs;
          },
          statements: [["block", "radio-button", [], ["value", "one_payment_completion", "groupValue", ["subexpr", "@mut", [["get", "onePaymentMilestone.description", ["loc", [null, [82, 21], [82, 52]]]]], [], []], "name", "one-payment-chx"], 0, null, ["loc", [null, [80, 8], [86, 25]]]], ["block", "radio-button", [], ["value", "one_payment_starting", "groupValue", ["subexpr", "@mut", [["get", "onePaymentMilestone.description", ["loc", [null, [89, 21], [89, 52]]]]], [], []], "name", "one-payment-chx"], 1, null, ["loc", [null, [87, 8], [93, 25]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 98,
                  "column": 6
                },
                "end": {
                  "line": 100,
                  "column": 6
                }
              },
              "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("Remaining: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("%");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["final-payment ", ["subexpr", "if", [["subexpr", "not-eq", [["get", "finalPayment", ["loc", [null, [99, 47], [99, 59]]]], 0], [], ["loc", [null, [99, 39], [99, 62]]]], "error", ""], [], ["loc", [null, [99, 34], [99, 75]]]]]]], ["content", "finalPayment", ["loc", [null, [99, 94], [99, 110]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 97,
                "column": 4
              },
              "end": {
                "line": 101,
                "column": 4
              }
            },
            "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "not-eq", [["get", "finalPayment", ["loc", [null, [98, 20], [98, 32]]]], 0], [], ["loc", [null, [98, 12], [98, 35]]]]], [], 0, null, ["loc", [null, [98, 6], [100, 13]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 106,
              "column": 0
            }
          },
          "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("center");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2, "class", "headline");
          var el3 = dom.createTextNode("payment schedule");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "c-payment-schedule__gratuity");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "checkbox-switch-wrapper");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4, "for", "chx-is-gratuity");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "gratuity-label");
          var el4 = dom.createTextNode("Enable Gratuity");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Here you can configure what payment terms to offer your clients.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "nav__tabs");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("Payment Schedule");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("One Payment");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("All amounts are represented as a percentage of the Proposal total.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-gray");
          var el3 = dom.createTextNode("Cancel");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-green");
          var el3 = dom.createTextNode("Update");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var element12 = dom.childAt(element11, [7]);
          var element13 = dom.childAt(element12, [1]);
          var element14 = dom.childAt(element12, [2]);
          var element15 = dom.childAt(element11, [15]);
          var element16 = dom.childAt(element11, [17]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [3, 1]), 1, 1);
          morphs[1] = dom.createAttrMorph(element13, 'class');
          morphs[2] = dom.createElementMorph(element13);
          morphs[3] = dom.createAttrMorph(element14, 'class');
          morphs[4] = dom.createElementMorph(element14);
          morphs[5] = dom.createMorphAt(element11, 11, 11);
          morphs[6] = dom.createMorphAt(element11, 13, 13);
          morphs[7] = dom.createElementMorph(element15);
          morphs[8] = dom.createElementMorph(element16);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "checkbox", "id", "chx-is-gratuity", "class", "checkbox-switch", "checked", ["subexpr", "@mut", [["get", "gratuity", ["loc", [null, [7, 85], [7, 93]]]]], [], []]], ["loc", [null, [7, 8], [7, 95]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "selectedIndividual", ["loc", [null, [16, 22], [16, 40]]]], "active"], [], ["loc", [null, [16, 17], [16, 51]]]]]]], ["element", "action", ["setScheduledPayment", true], [], ["loc", [null, [16, 53], [16, 90]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "selectedIndividual", ["loc", [null, [16, 132], [16, 150]]]], false], [], ["loc", [null, [16, 128], [16, 157]]]], "active"], [], ["loc", [null, [16, 123], [16, 168]]]]]]], ["element", "action", ["setScheduledPayment", false], [], ["loc", [null, [16, 170], [16, 208]]]], ["block", "if", [["get", "selectedIndividual", ["loc", [null, [19, 10], [19, 28]]]]], [], 0, 1, ["loc", [null, [19, 4], [95, 11]]]], ["block", "if", [["get", "selectedIndividual", ["loc", [null, [97, 10], [97, 28]]]]], [], 2, null, ["loc", [null, [97, 4], [101, 11]]]], ["element", "action", ["closeModal"], [], ["loc", [null, [103, 12], [103, 35]]]], ["element", "action", ["updateSettings"], [], ["loc", [null, [104, 12], [104, 39]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 107,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/proposals/edit/payment-settings.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-dialog", [], ["close", "closeModal", "translucentOverlay", true, "wrapperClassNames", "c-payment-schedule ember-modal-wrapper-static", "targetAttachment", "none"], 0, null, ["loc", [null, [1, 0], [106, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});