define('flowprosal-web-client/mixins/intro/intro-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    isIntroVisile: false,
    introSteps: null,
    introDelayForFirstStart: 0,

    resetIntrojs: function resetIntrojs() {
      this.set('introSteps', null);
      this.set('isIntroVisile', false);
    },

    initIntrojs: function initIntrojs() {
      throw "Not implemented!";
    },

    startIntroAutomatically: (function () {
      var currentUser = this.get('session.currentUser'),
          introType = _ember['default'].String.camelize('intro_' + this.get('introType'));

      if (currentUser.get(introType) === false || introType.indexOf('SignUp') > -1) {
        _ember['default'].run.later(this, function () {
          this.send('startIntro');
        }, this.get('introDelayForFirstStart'));
      }
    }).on('init'),

    actions: {
      startIntro: function startIntro() {
        this.initIntrojs();
        this.set('isIntroVisile', true);
      },

      introEnded: function introEnded() {
        this.set('isIntroVisile', false);

        var currentUser = this.get('session.currentUser'),
            introType = _ember['default'].String.camelize('intro_' + this.get('introType'));

        if (currentUser.get(introType) === false) {
          currentUser.set(introType, true);
          currentUser.save();
        }
      }
    }
  });
});