define('flowprosal-web-client/routes/dashboard', ['exports', 'ember', 'flowprosal-web-client/config/environment'], function (exports, _ember, _flowprosalWebClientConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel() {
      var companyId = window.customStorage.getItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier);
      var session = this.get('session');
      var _this = this;
      if (!companyId) {
        this.transitionTo('company-selector');
      } else {
        return this.store.findRecord('company', companyId).then(function (company) {
          session.set('content.currentCompany', company);
        })['catch'](function () {
          _this.transitionTo('company-selector');
        });
      }
    },

    model: function model() {
      var currentUser = this.session.get('currentUser');
      var currentCompany = this.session.get('currentCompany');
      //NOTE: Fetching the user object is necessary, because ED does not launch
      //its serialize/deserialize mechanism when fetching the session since that
      //is a manual XHR call (see torii-providers/application.js)
      // const user = this.store.findRecord('user', currentUser.get('id'), { reload: true });
      // const company = this.store.findRecord('company', currentCompany.get('id'), { reload: true });

      return _ember['default'].RSVP.hash({
        user: currentUser,
        company: currentCompany,
        followUpClients: this.store.queryRecord('client', { page: { number: 1, size: 2 }, sort: '-created_at' }),
        checkInClients: this.store.queryRecord('client', { page: { number: 1, size: 2 }, sort: '-created_at' })
      });
    },

    actions: {
      logout: function logout() {
        this.store.unloadAll();
        this.session.close();
        this.transitionTo('login');
      },

      accessDenied: function accessDenied() {
        this.transitionTo('login');
      },

      showAlmostDone: function showAlmostDone() {
        this.notifications.addNotification({
          message: 'So close... You got this!',
          type: 'success',
          autoClear: true,
          clearDuration: 2200
        });
      },
      showOpen: function showOpen() {
        this.notifications.addNotification({
          message: 'You have some opportunities on the horizon... Keep it up!',
          type: 'info',
          autoClear: true,
          clearDuration: 2200
        });
      },

      transitionToSearch: function transitionToSearch() {
        this.transitionTo('dashboard.search');
      },

      leaveSearchRoute: function leaveSearchRoute() {
        this.controllerFor('dashboard.search').send('closeSearch');
      },

      doProxySearch: function doProxySearch(term) {
        this.controllerFor('dashboard.search').send('doSearch', term);
      },

      createNewProposal: function createNewProposal() {
        var _this2 = this;

        var proposal = this.store.createRecord('proposal', { title: 'My Proposal', user: this.get('session.currentUser'), company: this.get('session.currentCompany') });

        proposal.save().then(function () {
          _this2.transitionTo('proposals.edit', proposal);
        });
      }
    }

  });
});