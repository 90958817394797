define('flowprosal-web-client/components/modal-ask-signature', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      cancelSignature: function cancelSignature() {
        this.sendAction('cancelSignature');
      },

      submitESignature: function submitESignature() {
        this.sendAction('submitESignature', this.get('signature'));
      }
    }
  });
});