define('flowprosal-web-client/components/intro-js', ['exports', 'ember', 'ember-introjs/components/intro-js'], function (exports, _ember, _emberIntrojsComponentsIntroJs) {

  var INTRO_JS_OPTIONS = ['next-label', 'prev-label', 'skip-label', 'done-label', 'tooltip-position', 'tooltip-class', 'highlightClass', 'exit-on-esc', 'exit-on-overlay-click', 'show-step-numbers', 'show-step-numbers', 'keyboard-navigation', 'show-buttons', 'show-bullets', 'show-progress', 'scroll-to-element', 'overlay-opacity', 'disable-interaction'];

  exports['default'] = _emberIntrojsComponentsIntroJs['default'].extend({
    'show-bullets': false,
    'show-progress': false,
    'show-step-numbers': false,
    'next-label': 'Next',
    'prev-label': 'Back',

    /**
     * Options passed to IntroJS. You can specify the options when using the
     * Handlebars helper:
     *
     * ```handlebars
     * {{intro-js steps=steps show-bullets=true}}
     * ```
     *
     * Or you could extend your own base class to override defaults
     * instead of specifying them every time in the Handlebars helper:
     *
     * ```javascript
     * myapp/app/components/my-intro-js.js
     *
     * import IntroJSComponent from 'ember-introjs/components/intro-js';
     *
     * export default IntroJSComponent.extend({
     *   'exit-on-esc': true
     * });
     * ```
     *
     * You can also reopen the class:
     *
     * ```javascript
     * import IntroJSComponent from 'ember-introjs/components/intro-js';
     *
     * IntroJSComponent.reopen({
     *   'exit-on-esc': true
     * });
     * ```
     *
     * @property
    */
    introJSOptions: _ember['default'].computed('next-label', 'prev-label', 'skip-label', 'done-label', 'tooltip-position', 'tooltip-class', 'highlightClass', 'exit-on-esc', 'exit-on-overlay-click', 'show-step-numbers', 'show-step-numbers', 'keyboard-navigation', 'show-buttons', 'show-bullets', 'show-progress', 'scroll-to-element', 'overlay-opacity', 'disable-interaction', 'steps', function () {
      var camelize = _ember['default'].String.camelize;
      var underscore = _ember['default'].String.underscore;

      var options = {};

      INTRO_JS_OPTIONS.forEach(function (option) {
        var normalizedName = camelize(underscore(option));

        var value = this.get(option);

        if (value !== null && value !== undefined) {
          options[normalizedName] = value;
        }
      }, this);

      options.steps = this.get('steps');

      return options;
    })
  });
});