define("flowprosal-web-client/templates/components/modal-payment-schedule", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 10
                  },
                  "end": {
                    "line": 28,
                    "column": 10
                  }
                },
                "moduleName": "flowprosal-web-client/templates/components/modal-payment-schedule.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "checkbox-switch-wrapper");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                dom.setAttribute(el2, "for", "chx-is-deposit");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "input", [], ["type", "checkbox", "id", "chx-is-deposit", "class", "checkbox-switch", "checked", ["subexpr", "@mut", [["get", "firstMilestone.deposit", ["loc", [null, [25, 88], [25, 110]]]]], [], []]], ["loc", [null, [25, 12], [25, 112]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 6
                },
                "end": {
                  "line": 54,
                  "column": 6
                }
              },
              "moduleName": "flowprosal-web-client/templates/components/modal-payment-schedule.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row row-plain-milestone c-payment-schedule__line");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-2 col-md-2 col-sm-2");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "deposit-label");
              var el4 = dom.createTextNode("Deposit");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-3 col-md-3 col-sm-3");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4, "class", "input__label input__label--ruri");
              dom.setAttribute(el4, "for", "input-name");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "input__label-content input__label-content--ruri");
              var el6 = dom.createTextNode("% Payment");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-5 col-md-5 col-sm-5");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4, "class", "input__label input__label--ruri");
              dom.setAttribute(el4, "for", "input-name");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "input__label-content input__label-content--ruri");
              var el6 = dom.createTextNode("Description");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-2 col-md-2 col-sm-2 no-padding");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "c-payment-schedule__controls");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("button");
              dom.setAttribute(el4, "class", "btn btn-green");
              var el5 = dom.createTextNode("+");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [3, 1]);
              var element10 = dom.childAt(element8, [5, 1]);
              var element11 = dom.childAt(element8, [7, 1, 2]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
              morphs[1] = dom.createAttrMorph(element9, 'class');
              morphs[2] = dom.createMorphAt(element9, 1, 1);
              morphs[3] = dom.createAttrMorph(element10, 'class');
              morphs[4] = dom.createMorphAt(element10, 1, 1);
              morphs[5] = dom.createElementMorph(element11);
              return morphs;
            },
            statements: [["block", "tooltip-message", [], ["message", "Make this payment required after Proposal is Accepted", "position", "top", "theme", "dark"], 0, null, ["loc", [null, [23, 10], [28, 30]]]], ["attribute", "class", ["concat", ["input-deposit input input--ruri ", ["subexpr", "if", [["subexpr", "not-empty", [["get", "firstMilestone.percentage", ["loc", [null, [32, 71], [32, 96]]]]], [], ["loc", [null, [32, 60], [32, 97]]]], "input--filled"], [], ["loc", [null, [32, 55], [32, 115]]]]]]], ["inline", "input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "firstMilestone.percentage", ["loc", [null, [33, 40], [33, 65]]]]], [], []], "class", "input__field input__field--ruri", "min", 0, "max", 100, "required", true], ["loc", [null, [33, 12], [33, 136]]]], ["attribute", "class", ["concat", ["input-deposit input input--ruri ", ["subexpr", "if", [["subexpr", "not-empty", [["get", "firstMilestone.description", ["loc", [null, [40, 71], [40, 97]]]]], [], ["loc", [null, [40, 60], [40, 98]]]], "input--filled"], [], ["loc", [null, [40, 55], [40, 116]]]]]]], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "firstMilestone.description", ["loc", [null, [41, 38], [41, 64]]]]], [], []], "class", "input__field input__field--ruri", "required", true], ["loc", [null, [41, 12], [41, 120]]]], ["element", "action", ["addScheduleLine"], [], ["loc", [null, [50, 42], [50, 70]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 6
                },
                "end": {
                  "line": 80,
                  "column": 6
                }
              },
              "moduleName": "flowprosal-web-client/templates/components/modal-payment-schedule.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row row-plain-milestone c-payment-schedule__line");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-3 col-md-3 col-sm-3 col-md-offset-2 col-sm-offset-2");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4, "class", "input__label input__label--ruri");
              dom.setAttribute(el4, "for", "input-name");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "input__label-content input__label-content--ruri");
              var el6 = dom.createTextNode("% Payment");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-5 col-md-5 col-sm-5");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4, "class", "input__label input__label--ruri");
              dom.setAttribute(el4, "for", "input-name");
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "input__label-content input__label-content--ruri");
              var el6 = dom.createTextNode("Description");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-lg-2 col-md-2 col-sm-2 no-padding");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "c-payment-schedule__controls");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("button");
              dom.setAttribute(el4, "class", "btn btn-blue c-payment-schedule__controls--remove");
              var el5 = dom.createTextNode("-");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("button");
              dom.setAttribute(el4, "class", "btn btn-green");
              var el5 = dom.createTextNode("+");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [1, 1]);
              var element4 = dom.childAt(element2, [3, 1]);
              var element5 = dom.childAt(element2, [5, 1]);
              var element6 = dom.childAt(element5, [1]);
              var element7 = dom.childAt(element5, [3]);
              var morphs = new Array(6);
              morphs[0] = dom.createAttrMorph(element3, 'class');
              morphs[1] = dom.createMorphAt(element3, 1, 1);
              morphs[2] = dom.createAttrMorph(element4, 'class');
              morphs[3] = dom.createMorphAt(element4, 1, 1);
              morphs[4] = dom.createElementMorph(element6);
              morphs[5] = dom.createElementMorph(element7);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["input-deposit input input--ruri ", ["subexpr", "if", [["subexpr", "not-empty", [["get", "pay.percentage", ["loc", [null, [58, 71], [58, 85]]]]], [], ["loc", [null, [58, 60], [58, 86]]]], "input--filled"], [], ["loc", [null, [58, 55], [58, 104]]]]]]], ["inline", "input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "pay.percentage", ["loc", [null, [59, 40], [59, 54]]]]], [], []], "class", "input__field input__field--ruri", "min", 0, "max", 100, "required", true], ["loc", [null, [59, 12], [59, 125]]]], ["attribute", "class", ["concat", ["input-deposit input input--ruri ", ["subexpr", "if", [["subexpr", "not-empty", [["get", "pay.description", ["loc", [null, [66, 71], [66, 86]]]]], [], ["loc", [null, [66, 60], [66, 87]]]], "input--filled"], [], ["loc", [null, [66, 55], [66, 105]]]]]]], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "pay.description", ["loc", [null, [67, 38], [67, 53]]]]], [], []], "class", "input__field input__field--ruri", "required", true], ["loc", [null, [67, 12], [67, 109]]]], ["element", "action", ["removeScheduleLine", ["get", "pay", ["loc", [null, [75, 108], [75, 111]]]]], [], ["loc", [null, [75, 78], [75, 113]]]], ["element", "action", ["addScheduleLine"], [], ["loc", [null, [76, 42], [76, 70]]]]],
            locals: ["pay"],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 83,
                  "column": 4
                },
                "end": {
                  "line": 85,
                  "column": 4
                }
              },
              "moduleName": "flowprosal-web-client/templates/components/modal-payment-schedule.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("Remaining: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("%");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["c-payment-schedule__remaining text-center ", ["subexpr", "if", [["get", "updateIsDisabled", ["loc", [null, [84, 63], [84, 79]]]], "error", ""], [], ["loc", [null, [84, 58], [84, 92]]]]]]], ["content", "scheduleRemaining", ["loc", [null, [84, 111], [84, 132]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 2
              },
              "end": {
                "line": 86,
                "column": 2
              }
            },
            "moduleName": "flowprosal-web-client/templates/components/modal-payment-schedule.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("center");
            dom.setAttribute(el1, "class", "c-payment-schedule__lines");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element12, 1, 1);
            morphs[1] = dom.createMorphAt(element12, 2, 2);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "firstMilestone", ["loc", [null, [20, 12], [20, 26]]]]], [], 0, null, ["loc", [null, [20, 6], [54, 13]]]], ["block", "each", [["get", "restMilestones", ["loc", [null, [55, 14], [55, 28]]]]], [], 1, null, ["loc", [null, [55, 6], [80, 15]]]], ["block", "if", [["subexpr", "not-eq", [["get", "scheduleRemaining", ["loc", [null, [83, 18], [83, 35]]]], 0], [], ["loc", [null, [83, 10], [83, 38]]]]], [], 2, null, ["loc", [null, [83, 4], [85, 11]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 8
                },
                "end": {
                  "line": 95,
                  "column": 8
                }
              },
              "moduleName": "flowprosal-web-client/templates/components/modal-payment-schedule.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          Due Upon Completion\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 96,
                  "column": 8
                },
                "end": {
                  "line": 102,
                  "column": 8
                }
              },
              "moduleName": "flowprosal-web-client/templates/components/modal-payment-schedule.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          Due Before Starting\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 2
              },
              "end": {
                "line": 105,
                "column": 2
              }
            },
            "moduleName": "flowprosal-web-client/templates/components/modal-payment-schedule.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "radio-buttons-wrapper");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element0, 1, 1);
            morphs[1] = dom.createMorphAt(element0, 2, 2);
            return morphs;
          },
          statements: [["block", "radio-button", [], ["value", "one_payment_completion", "groupValue", ["subexpr", "@mut", [["get", "onePaymentModel.firstObject.description", ["loc", [null, [91, 21], [91, 60]]]]], [], []], "name", "one-payment-chx"], 0, null, ["loc", [null, [89, 8], [95, 25]]]], ["block", "radio-button", [], ["value", "one_payment_starting", "groupValue", ["subexpr", "@mut", [["get", "onePaymentModel.firstObject.description", ["loc", [null, [98, 21], [98, 60]]]]], [], []], "name", "one-payment-chx"], 1, null, ["loc", [null, [96, 8], [102, 25]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["multiple-nodes", "wrong-type"]
          },
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 110,
              "column": 0
            }
          },
          "moduleName": "flowprosal-web-client/templates/components/modal-payment-schedule.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1, "class", "c-payment-schedule__heading text-center");
          var el2 = dom.createTextNode("Payment schedule");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "c-payment-schedule__gratuity");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "checkbox-switch-wrapper");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3, "for", "chx-is-gratuity");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "gratuity-label");
          var el3 = dom.createTextNode("Enable Gratuity");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "text-center");
          var el2 = dom.createTextNode("Here you can configure what payment terms to offer your clients.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "href", "#");
          dom.setAttribute(el2, "class", "c-payment-schedule__schedule-link");
          var el3 = dom.createTextNode("Payment schedule");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "href", "#");
          dom.setAttribute(el2, "class", "c-payment-schedule__one-link");
          var el3 = dom.createTextNode("One payment");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "text-center");
          var el2 = dom.createTextNode("All amounts are represented as a percentage of the Proposal total.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "c-payment-schedule__footer text-center");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn");
          var el3 = dom.createTextNode("Cancel");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-green");
          var el3 = dom.createTextNode("Update");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [7]);
          var element14 = dom.childAt(element13, [1]);
          var element15 = dom.childAt(element13, [2]);
          var element16 = dom.childAt(fragment, [13]);
          var element17 = dom.childAt(element16, [1]);
          var element18 = dom.childAt(element16, [3]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]), 1, 1);
          morphs[1] = dom.createAttrMorph(element13, 'class');
          morphs[2] = dom.createElementMorph(element14);
          morphs[3] = dom.createElementMorph(element15);
          morphs[4] = dom.createMorphAt(fragment, 11, 11, contextualElement);
          morphs[5] = dom.createElementMorph(element17);
          morphs[6] = dom.createAttrMorph(element18, 'disabled');
          morphs[7] = dom.createElementMorph(element18);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "checkbox", "id", "chx-is-gratuity", "class", "checkbox-switch", "checked", ["subexpr", "@mut", [["get", "user.gratuity", ["loc", [null, [6, 83], [6, 96]]]]], [], []]], ["loc", [null, [6, 6], [6, 98]]]], ["attribute", "class", ["concat", ["c-payment-schedule__selector text-center ", ["subexpr", "if", [["get", "isShowingSchedule", ["loc", [null, [13, 58], [13, 75]]]], "c-payment-schedule__selector--schedule", "c-payment-schedule__selector--one"], [], ["loc", [null, [13, 53], [13, 154]]]]]]], ["element", "action", ["showSchedulePanel"], [], ["loc", [null, [14, 58], [14, 88]]]], ["element", "action", ["showOnePaymentPanel"], [], ["loc", [null, [14, 158], [14, 190]]]], ["block", "if", [["get", "isShowingSchedule", ["loc", [null, [17, 8], [17, 25]]]]], [], 0, 1, ["loc", [null, [17, 2], [105, 9]]]], ["element", "action", ["closeModal"], [], ["loc", [null, [107, 24], [107, 47]]]], ["attribute", "disabled", ["get", "updateIsDisabled", ["loc", [null, [108, 70], [108, 86]]]]], ["element", "action", ["updateTerms"], [], ["loc", [null, [108, 34], [108, 58]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 111,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/components/modal-payment-schedule.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-dialog", [], ["wrapperClassNames", "ember-modal-wrapper-static", "container-class", "c-payment-schedule", "close", "closeModal", "translucentOverlay", true, "targetAttachment", "none"], 0, null, ["loc", [null, [1, 0], [110, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});