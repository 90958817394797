define('flowprosal-web-client/controllers/proposals/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    paymentMilestones: _ember['default'].computed('model.paymentMilestones.length', function () {
      var paymentMilestones = [];
      if (this.get('model.scheduledPayment')) {
        this.get('model.paymentMilestones').forEach(function (milestone) {
          if (!milestone.get('paymentMilestoneType') || milestone.get('paymentMilestoneType') === 'Change Request') {
            paymentMilestones.push(milestone);
          }
        });
      } else {
        var onePaymentMilestone = this.get('model.paymentMilestones').findBy('paymentMilestoneType', 'one_payment');
        paymentMilestones.push(onePaymentMilestone);
        this.get('model.paymentMilestones').forEach(function (milestone) {
          if (milestone.get('paymentMilestoneType') === 'Change Request') {
            paymentMilestones.push(milestone);
          }
        });
      }

      return paymentMilestones;
    }),

    actions: {
      sendMilestoneEmail: function sendMilestoneEmail(milestone) {
        milestone.set('status', 'under_sending');
        return milestone.save();
      },

      completed: function completed(status) {
        var _this = this;
        if (status === false) {
          this.get('model').completed({ status: 'not_completed' }).then(function (item) {
            _this.get('model').set('status', item['data']['attributes']['status']);
          });
        } else {
          this.get('model').completed({ status: 'completed' }).then(function (item) {
            _this.get('model').set('status', item['data']['attributes']['status']);
          });
        }
      },

      sendReminder: function sendReminder(change) {
        var _this = this;
        change.sendReminder().then(function () {
          _this.get('notifications').addNotification({
            message: 'Reminder has been sent',
            type: 'success',
            autoClear: true,
            clearDuration: 2200
          });
        });
      },

      markAsPaid: function markAsPaid(milestone) {
        milestone.set('status', 'under_paying');
        return milestone.save();
      }
    }
  });
});