define('flowprosal-web-client/routes/dashboard/settings/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return this.store.findRecord('user', this.get('session.currentUser.id'), { reload: true });
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('currentCompany', this.get('session.currentCompany'));
    },

    deactivate: function deactivate() {
      Dropzone.instances.forEach(function (dz) {
        dz.destroy();
      });
    },

    actions: {
      openPaymentScheduleModal: function openPaymentScheduleModal() {
        this.controller.set('isShowingPaymentScheduleModal', true);
      },

      closePaymentScheduleModal: function closePaymentScheduleModal() {
        this.controller.set('isShowingPaymentScheduleModal', false);
      },

      updateTerms: function updateTerms(newTerms, gratuity) {
        var _this = this;
        this.get('session.currentUser').updatePaymentSettings({ gratuity: gratuity, payment_milestones: newTerms }).then(function (response) {
          _this.store.pushPayload(response);
        });
      },

      openUpdateSubscriptionModal: function openUpdateSubscriptionModal() {
        this.controller.set('isShowingUpdateSubscription', true);
      },

      closeUpdateSubscriptionModal: function closeUpdateSubscriptionModal() {
        this.controller.set('isShowingUpdateSubscription', false);
      },

      checkoutToken: function checkoutToken(token, subscriptionLevel) {
        var _this = this;
        if (token) {
          this.get('session.currentUser').subscription({ stripe_token: token.id, subscription: subscriptionLevel }).then(function (company) {
            _this.store.pushPayload(company);
          });
        }
      }
    }

  });
});