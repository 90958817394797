define('flowprosal-web-client/routes/proposals/show/payment-milestones/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.store.findRecord('payment-milestone', params.payment_milestone_id, { reload: true });
    },

    afterModel: function afterModel(model) {
      model.reload();
    }
  });
});