define('flowprosal-web-client/controllers/uikit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isShowingModal: false,
    testInputValue: '',
    testInputValue2: '',
    paymentBirthDate: '1988.09.29',
    selectedColor: '00a888',

    dzAddedFile: function dzAddedFile() {
      if (this.dropzone.files.length > 1) {
        this.dropzone.removeFile(this.dropzone.files[0]);
      }
    },

    phoneNum: "+36209920738",

    gsOptions: {
      height: 10,
      width: 12,
      vertical_margin: 0,
      static_grid: false,
      float: true,
      animate: true
    },

    staticGridOptions: {
      height: 10,
      width: 12,
      vertical_margin: 0,
      static_grid: true,
      float: true
    },

    rawItems: [{ w: 1, h: 1, x: 1, y: 1, content: { id: "12" } }, { w: 1, h: 3, x: 3, y: 0, content: { id: "13" } }],

    items: [{ id: "12", name: 'hello 1' }, { id: "13", name: 'hello 2' }],

    gsItems: _ember['default'].computed('rawItems.[].{w,h,x,y}', 'items.[]', function () {
      var ret = [];
      var items = this.get('items');
      this.get('rawItems').forEach(function (it) {
        var copy = _ember['default'].copy(it);
        copy.content = items.findBy('id', it.content.id);
        ret.pushObject(copy);
      });
      return ret;
    }),

    showGridStack: true,
    gridCnt: 2,

    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isShowingModal');
      },

      addGridItem: function addGridItem() {
        var cnt = this.get('gridCnt');
        this.incrementProperty('gridCnt');
        var content = { id: 13 + cnt + '', name: 'hello ' + cnt };
        this.get('items').pushObject(content);
        this.get('rawItems').pushObject({ w: 1, h: 1, x: 1, y: 4, content: { id: content.id } });
      },

      removeGridItem: function removeGridItem(item) {
        this.get('rawItems').removeObject(this.get('rawItems').findBy('content.id', _ember['default'].get(item, 'content.id')));
        this.get('items').removeObject(this.get('items').findBy('id', _ember['default'].get(item, 'content.id')));
      },

      onGridChanged: function onGridChanged(updatedItems) {
        var myItems = this.get('rawItems');
        updatedItems.forEach(function (it) {
          var thisItem = myItems.findBy('content.id', _ember['default'].get(it, 'content.id'));
          if (thisItem) {
            _ember['default'].set(thisItem, 'x', it.x);
            _ember['default'].set(thisItem, 'y', it.y);
            _ember['default'].set(thisItem, 'w', it.w);
            _ember['default'].set(thisItem, 'h', it.h);
          }
        });
        console.log(JSON.stringify(this.get('rawItems')));
      }
    }
  });
});