define('flowprosal-web-client/mixins/col-pick-circle-mixin', ['exports', 'ember', 'ember-colpick/mixins/col-pick'], function (exports, _ember, _emberColpickMixinsColPick) {
  exports['default'] = _emberColpickMixinsColPick['default'].reopen({
    polyfill: false,

    _setupColpick: function _setupColpick() {
      var layout = this.get('colpickLayout');
      var colorScheme = this.get('colorScheme');

      if (layout && colorScheme) {
        var colpick = this._colpick = this.$('.trigger').colpick({
          layout: layout,
          colorScheme: colorScheme,
          submit: 0,
          flat: this.get('flat'),
          polyfill: this.get('polyfill'),
          onChange: _ember['default'].run.bind(this, function (hsb, hex, rgb, el, bySetColor) {
            if (this.get('useHashtag')) {
              hex = '#' + hex;
            }

            this.set('previewValue', hex);

            if (!bySetColor) {
              this.set('value', hex);
            }
          }),
          onHide: _ember['default'].run.bind(this, function () {
            this.sendAction('onHide');
          })
        });

        colpick.find('input[type=text]').keyup(function () {
          var hexInputVal = this.value;
          if (hexInputVal.length === 6) {
            colpick.colpickSetColor(hexInputVal);
          }
        });

        var value = this.get('value');
        if (value) {
          colpick.colpickSetColor(value);
        }
      }
    }
  });
});