define('flowprosal-web-client/torii-adapters/application', ['exports', 'ember', 'flowprosal-web-client/config/environment', 'flowprosal-web-client/utils/camelize-keys'], function (exports, _ember, _flowprosalWebClientConfigEnvironment, _flowprosalWebClientUtilsCamelizeKeys) {
  exports['default'] = _ember['default'].Object.extend({
    open: function open(authorization) {
      var user = this.store.createRecord('user', (0, _flowprosalWebClientUtilsCamelizeKeys['default'])(authorization.data.attributes));
      user.set('id', authorization.data.id);

      var companyId = '';

      window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].authTokenName, user.get('authenticationToken'));

      if (authorization.data.attributes['single-company']) {
        companyId = authorization.data.attributes['single-company'];
        window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier, companyId);
      }

      if (companyId) {
        var company = this.store.peekRecord('company', companyId);
        if (!company) {
          company = this.store.createRecord('company');
          company.set('id', companyId);
        }

        return { currentUser: user, currentCompany: company };
      } else {
        return { currentUser: user };
      }
    },

    fetch: function fetch() {
      var _this = this;

      var token = window.customStorage.getItem(_flowprosalWebClientConfigEnvironment['default'].authTokenName);
      var companyId = window.customStorage.getItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax({
          url: _flowprosalWebClientConfigEnvironment['default'].apiHost + '/api/v2/sessions',
          type: 'GET',
          dataType: 'json',
          headers: {
            Authorization: token
          },
          success: _ember['default'].run.bind(null, resolve),
          error: _ember['default'].run.bind(null, reject)
        });
      }).then(function (authorization) {
        var userId = authorization.data.id;
        _this.store.pushPayload(authorization);
        var user = _this.store.peekRecord('user', userId);
        if (!user) {
          user = _this.store.createRecord('user', (0, _flowprosalWebClientUtilsCamelizeKeys['default'])(authorization.data.attributes));
          user.set('id', authorization.data.id);
        }

        if (!companyId && authorization.data.attributes['single-company']) {
          companyId = authorization.data.attributes['single-company'];
          window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier, companyId);
        }
        return { currentUser: user };

        // if (companyId) {
        //   let company = this.store.findRecord('company', companyId);
        //   if (!company) {
        //     company = this.store.createRecord('company');
        //     company.set('id', companyId);
        //   }

        //   return Ember.RSVP.hash({ currentUser: user, currentCompany: company });
        // } else {
        //   return Ember.RSVP.hash({ currentUser: user });
        // }
      });
    },

    close: function close() {
      window.customStorage.removeItem(_flowprosalWebClientConfigEnvironment['default'].authTokenName);
      window.customStorage.removeItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier);
    }
  });
});