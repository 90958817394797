define('flowprosal-web-client/components/company-avatar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    imageUrl: _ember['default'].computed('model', 'model.avatar', function () {
      return this.get('model.avatar') || '/images/no_image.jpg';
    }),

    name: _ember['default'].computed('model', 'model.name', function () {
      return this.get('model.name');
    })
  });
});