define('flowprosal-web-client/models/segment', ['exports', 'ember', 'ember-data', 'ember-api-actions'], function (exports, _ember, _emberData, _emberApiActions) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr(),
    content: _emberData['default'].attr(),
    sectionHeading: _emberData['default'].attr('boolean'),
    sectionSubheading: _emberData['default'].attr('boolean'),
    position: _emberData['default'].attr('number'),
    price: _emberData['default'].attr('number'),
    indent: _emberData['default'].attr('number', { defaultValue: 0 }),
    segmentType: _emberData['default'].attr({ defaultValue: '' }),
    segmentTemplateId: _emberData['default'].attr(),
    favorite: _emberData['default'].attr('boolean', { defaultValue: false }),
    library: _emberData['default'].attr('boolean', { defaultValue: false }),
    required: _emberData['default'].attr('boolean'),
    visible: _emberData['default'].attr('boolean'),
    clientAccepted: _emberData['default'].attr('boolean', { defaultValue: true }),
    gridData: _emberData['default'].attr(),
    imageUrl: _emberData['default'].attr(),
    createdAt: _emberData['default'].attr(),
    updatedAt: _emberData['default'].attr(),
    section: _emberData['default'].attr(),

    comments: _emberData['default'].hasMany('comment', { async: true }),
    "gallery-images": _emberData['default'].hasMany('gallery-image', { async: true }),

    proposal: _emberData['default'].belongsTo('proposal', { async: true }),
    company: _emberData['default'].belongsTo('company', { async: true }),
    client: _emberData['default'].belongsTo('client', { async: true }),
    user: _emberData['default'].belongsTo('user', { async: true }),

    favoriteGallery: (0, _emberApiActions.memberAction)({ path: 'favorite_gallery' }),

    gridItems: _ember['default'].computed('gridData.@each.{x,y,w,h}', 'gallery-images.[]', 'segmentType', function () {
      var ret = [],
          _this = this;
      if (this.get('segmentType') === 'gallery' && this.get('gridData')) {
        this.get('gallery-images').then(function (items) {
          _this.get('gridData').forEach(function (it) {
            var copy = _ember['default'].copy(it);
            copy.content = items.findBy('id', it.content.id);
            ret.pushObject(copy);
          });
        });
      }

      return ret;
    }),

    galleryImageIds: _ember['default'].computed.mapBy('gallery-images', 'image.id')
  });
});