define('flowprosal-web-client/mixins/intro/sign-up-company-intro-mixin', ['exports', 'ember', 'flowprosal-web-client/mixins/intro/intro-mixin', 'ember-introjs/components/intro-js'], function (exports, _ember, _flowprosalWebClientMixinsIntroIntroMixin, _emberIntrojsComponentsIntroJs) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Mixin.create(_flowprosalWebClientMixinsIntroIntroMixin['default'], {
    introType: 'signUpCompany',

    initIntrojs: function initIntrojs() {
      if (this.get('introSteps') === null) {
        var steps = [{
          intro: '<h2>THANKS FOR JOINING!</h2>\n          <p>Now, we need to collect some info about your business…</p>\n          <p>Help us tell your story! You can select from a pre-defined script to fill in your company information, or just paste in a company bio you already have.</p>',
          tooltipClass: 'signup-modal'
        }];
        this.set('introSteps', steps);
      }
    },

    actions: {
      introBeforeChange: function introBeforeChange(currentStep, nextStep) {
        if (nextStep.name === 'userMenu') {
          _ember['default'].run.later(function () {
            $('#dLabel2').trigger('click');
          });
        }

        if (currentStep.name === 'userMenu') {
          $(document.body).trigger('click');
        }
      }
    }
  });

  _emberIntrojsComponentsIntroJs['default'].reopen({
    'done-label': 'NEXT >'
  });
});