define('flowprosal-web-client/routes/segments/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return this.store.findAll('segment-template');
    },

    actions: {

      remove: function remove(model) {
        if (confirm('Are you sure?')) {
          model.destroyRecord();
        }
      }

    }

  });
});