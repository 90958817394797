define('flowprosal-web-client/routes/clients/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      return this.store.findRecord('client', params.client_id);
    },

    actions: {
      updateNotes: function updateNotes(model, notes) {
        model.set('notes', notes);
        model.save();
      },

      deleteClient: function deleteClient(client) {
        if (client.get('proposals.length') > 0) {
          this.get('notifications').addNotification({
            message: 'You cannot delete this client, because it has associated proposal(s).',
            type: 'error',
            autoClear: true,
            clearDuration: 2200
          });
        } else {
          if (confirm("Are you sure?")) {
            client.destroyRecord();
            this.transitionTo('clients.index');
          }
        }
      }
    }

  });
});