define('flowprosal-web-client/controllers/bootstrap/services', ['exports', 'ember', 'flowprosal-web-client/mixins/intro/sign-up-segment-intro-mixin'], function (exports, _ember, _flowprosalWebClientMixinsIntroSignUpSegmentIntroMixin) {

  function segmentKey(segment) {
    return segment.toString();
  }

  exports['default'] = _ember['default'].Controller.extend(_flowprosalWebClientMixinsIntroSignUpSegmentIntroMixin['default'], {
    segments: [],
    areSegmentsValid: {},
    displayErrors: false,
    isShowingWarningModal: false,
    modalConfirmAction: null,

    addSegment: function addSegment() {
      var segment = this.store.createRecord('segmentTemplate', { favorite: true, user: this.get('session.currentUser') });
      this.get('segments').pushObject(segment);
      this.get('areSegmentsValid')[segmentKey(segment)] = false;
    },

    addFirstSegment: _ember['default'].on('init', function () {
      this.addSegment();
    }),

    initChildrenArray: _ember['default'].on('init', function () {
      this.set('childrenArray', _ember['default'].A([]));
    }),

    validSegments: function validSegments() {
      var segments = this.get('segments');
      var areSegmentsValid = this.get('areSegmentsValid');
      return segments.filter(function (segment) {
        return areSegmentsValid[segmentKey(segment)];
      });
    },

    saveSegments: function saveSegments() {
      var _this = this;

      var validSegments = this.validSegments();
      var segmentSaves = validSegments.map(function (segment) {
        segment.set('company', _this.get('model'));
        return segment.save();
      });
      this.set('isUploadInProgress', true);
      return _ember['default'].RSVP.all(segmentSaves).then(function () {
        return _ember['default'].RSVP.all(_this.get('childrenArray').map(function (comp) {
          return comp.uploadSegmentImage();
        }));
      }).then(function () {
        _this.set('isUploadInProgress', false);
        return _this.transitionToRoute('dashboard');
      })['catch'](function () {
        _this.flashMessages.danger("Some services could not be saved.");
      });
    },

    allSegmentsValid: function allSegmentsValid() {
      return this.validSegments().length === this.get('segments').length;
    },

    actions: {

      removeSegment: function removeSegment(segment) {
        this.get('segments').removeObject(segment);
        delete this.get('areSegmentsValid')[segmentKey(segment)];
      },

      updateSegment: function updateSegment(segment, property, value, isValid) {
        //some wierd testing issues: calling set on destroyed object
        if (!(segment.get('isDestroyed') || segment.get('isDestroying'))) {
          segment.set(property, value);
          this.get('areSegmentsValid')[segmentKey(segment)] = isValid;
        }
      },

      validateAndSave: function validateAndSave() {
        if (!this.allSegmentsValid()) {
          this.setProperties({
            displayErrors: true,
            isShowingWarningModal: true,
            modalConfirmAction: 'save'
          });
          return;
        }
        return this.saveSegments();
      },

      save: function save() {
        return this.saveSegments();
      },

      confirmCancel: function confirmCancel() {
        if (!this.allSegmentsValid()) {
          this.setProperties({
            isShowingWarningModal: true,
            modalConfirmAction: 'cancelSignupFlow'
          });
          return;
        }

        this.send('cancelSignupFlow');
      },

      cancelSignupFlow: function cancelSignupFlow() {
        this.transitionToRoute('dashboard');
      },

      transitionBack: function transitionBack() {
        this.transitionToRoute('bootstrap.team', this.get('session.content.currentCompany.id'));
      },

      toggleWarningModal: function toggleWarningModal() {
        this.toggleProperty('isShowingWarningModal');
      },

      addSegment: function addSegment() {
        this.addSegment();
      },

      registerServiceSegmentTemplate: function registerServiceSegmentTemplate(comp) {
        this.get('childrenArray').pushObject(comp);
      },

      teardownServiceSegmentTemplate: function teardownServiceSegmentTemplate(comp) {
        this.get('childrenArray').removeObject(comp);
      }

    }

  });
});