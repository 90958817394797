define('flowprosal-web-client/controllers/dashboard/settings/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),

    dropzoneUrl: _ember['default'].computed('session.currentUser.id', function () {
      return this.container.lookup('adapter:application').buildURL('users', this.get('session.currentUser.id')) + '/upload_profile_image';
    }),

    dropzoneUrlForCompanyLogo: _ember['default'].computed('session.currentCompany.id', function () {
      return this.container.lookup('adapter:application').buildURL('companies', this.get('session.currentCompany.id')) + '/upload_avatar';
    }),

    dropzoneHeaders: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return { "Authorization": this.get('session.currentUser.authenticationToken'), "CompanyIdentifier": this.get('session.currentCompany.id') };
    }),

    dzAddedFile: function dzAddedFile(file) {
      if (this.dropzone.files.length > 1) {
        if (this.dropzone.files[0].status === 'success') {
          this.dropzone.removeFile(this.dropzone.files[0]);
        } else {
          this.dropzone.removeFile(file);
        }
      }
    },

    actions: {

      profileImageUploadComplete: function profileImageUploadComplete(file) {
        var jsonResponse = JSON.parse(file.xhr.response);
        this.get('store').pushPayload(jsonResponse);
        _ember['default'].$('.user-profile-image').attr('src', jsonResponse.data.attributes.avatar);

        this.get('notifications').addNotification({
          message: 'Image upload completed.',
          type: 'success',
          autoClear: true,
          clearDuration: 2200
        });
      },

      logoImageUploadComplete: function logoImageUploadComplete(file) {
        var currentCompanyId = this.get('session.currentCompany.id');
        var jsonResponse = JSON.parse(file.xhr.response);
        this.get('store').pushPayload(jsonResponse);
        var updatedCompany = this.store.peekRecord('company', currentCompanyId);
        this.get('session').set('currentCompany', updatedCompany);
        this.set('currentCompany', updatedCompany);
        try {
          _ember['default'].$('.company-avatar-image').attr('src', jsonResponse.data.attributes.avatar);
        } catch (e) {}

        this.get('notifications').addNotification({
          message: 'Image upload completed.',
          type: 'success',
          autoClear: true,
          clearDuration: 2200
        });
      },

      showEditUserModal: function showEditUserModal() {
        this.set('isShowingEditUserModal', true);
      },

      closeEditUserModal: function closeEditUserModal() {
        this.set('isShowingEditUserModal', false);
      },

      saveUserModel: function saveUserModel(model) {
        var _this = this;
        model.save().then(function (savedModel) {
          _this.get('session').set('currentUser', savedModel);
          _this.set('isShowingEditUserModal', false);
        });
      },

      showEditCompanyModal: function showEditCompanyModal() {
        this.set('isShowingEditCompanyModal', true);
      },

      closeEditCompanyModal: function closeEditCompanyModal() {
        this.set('isShowingEditCompanyModal', false);
      },

      saveCompanyModel: function saveCompanyModel(model) {
        var _this = this;
        model.save().then(function (savedModel) {
          _this.get('session').set('currentCompany', savedModel);
          _this.set('isShowingEditCompanyModal', false);
        });
      },

      updateUserSettings: function updateUserSettings() {
        var _this = this;
        this.get('session.currentUser').updateUserSettings({
          color_primary: _this.get('model.colorPrimary'),
          color_accent: _this.get('model.colorAccent'),
          color_text: _this.get('model.colorText'),
          expire_after: _this.get('model.expireAfter')
        }).then(function (response) {
          _this.store.pushPayload(response);
        });
      }
    }
  });
});