define('flowprosal-web-client/components/signature-pad', ['exports', 'ember'], function (exports, _ember) {
  /* global SignaturePad */
  /**
   * Usage example:
      <h3>Canvas:</h3>
     {{#signature-pad signature=signature canvasClassnames="c-signature-pad" canvasWidth=780 canvasHeight=340 as |reset|}}
       <button {{action reset}}>reset</button>
     {{/signature-pad}}
     <h3>Image:</h3>
     <img src="{{signature}}" />
   */

  exports['default'] = _ember['default'].Component.extend({

    signaturePad: null,

    signature: null,

    canvasClassnames: '',

    canvasWidth: 640,
    canvasHeight: 320,

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      var canvas = this.$('canvas');
      var _this = this;

      this.signaturePad = new SignaturePad(canvas[0], {
        onEnd: function onEnd() {
          var ua = window.navigator.userAgent;
          var msie = ua.indexOf("MSIE ");

          if (msie > 0) {
            _this.set('signature', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==');
          } else {
            _this.set('signature', _this.signaturePad.toDataURL());
          }
        }
      });

      if (this.get('signature')) {
        this.signaturePad.fromDataURL(this.get('signature'));
      }
    }),

    onWillDestroyElement: _ember['default'].on('willDestroyElement', function () {
      this.signaturePad.off();
    }),

    actions: {
      clear: function clear() {
        this.signaturePad.clear();
        this.set('signature', this.signaturePad.toDataURL());
      }
    }

  });
});