define('flowprosal-web-client/adapters/public', ['exports', 'ember', 'ember-data', 'flowprosal-web-client/config/environment'], function (exports, _ember, _emberData, _flowprosalWebClientConfigEnvironment) {
  exports['default'] = _emberData['default'].JSONAPIAdapter.extend({
    namespace: 'api/v2/public',
    host: _flowprosalWebClientConfigEnvironment['default'].apiHost,
    headers: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return {
        Authorization: this.get('session.currentUser.authenticationToken'),
        CompanyIdentifier: this.get('session.currentCompany') ? this.get('session.currentCompany.id') : ''
      };
    })
  });
});