define("flowprosal-web-client/templates/components/signup-progress-bar", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/components/signup-progress-bar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("1");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("2");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("3");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createAttrMorph(element2, 'class');
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["signup-step ", ["subexpr", "if", [["subexpr", "eq", [["get", "activeStep", ["loc", [null, [1, 33], [1, 43]]]], 1], [], ["loc", [null, [1, 29], [1, 46]]]], "active"], [], ["loc", [null, [1, 24], [1, 57]]]], " ", ["subexpr", "if", [["get", "isActiveStepOver1", ["loc", [null, [1, 63], [1, 80]]]], "signup-step--green"], [], ["loc", [null, [1, 58], [1, 103]]]]]]], ["attribute", "class", ["concat", ["signup-step ", ["subexpr", "if", [["subexpr", "eq", [["get", "activeStep", ["loc", [null, [2, 33], [2, 43]]]], 2], [], ["loc", [null, [2, 29], [2, 46]]]], "active"], [], ["loc", [null, [2, 24], [2, 57]]]], " ", ["subexpr", "if", [["get", "isActiveStepOver2", ["loc", [null, [2, 63], [2, 80]]]], "signup-step--green"], [], ["loc", [null, [2, 58], [2, 103]]]]]]], ["attribute", "class", ["concat", ["signup-step ", ["subexpr", "if", [["subexpr", "eq", [["get", "activeStep", ["loc", [null, [3, 33], [3, 43]]]], 3], [], ["loc", [null, [3, 29], [3, 46]]]], "active"], [], ["loc", [null, [3, 24], [3, 57]]]]]]]],
      locals: [],
      templates: []
    };
  })());
});