define('flowprosal-web-client/mirage/factories/company', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = _emberCliMirage['default'].Factory.extend({
    name: _emberCliMirage.faker.company.companyName,
    streetAddress: _emberCliMirage.faker.address.streetAddress,
    addressLine2: _emberCliMirage.faker.address.streetSuffix,
    email: _emberCliMirage.faker.internet.email,
    city: _emberCliMirage.faker.address.city,
    state: _emberCliMirage.faker.address.state,
    zip: _emberCliMirage.faker.address.zipCode,
    phone: _emberCliMirage.faker.phone.phoneNumber,
    foundingYear: '2015',
    foundingMonth: '1',
    product: _emberCliMirage.faker.commerce.product
  });
});
/*
  This is an example factory definition.

  Create more files in this directory to define additional factories.
*/