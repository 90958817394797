define('flowprosal-web-client/controllers/public-proposal/payment-milestones/pay', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
    'public': _ember['default'].inject.service('public'),
    displayErrors: false,
    gratuity: null,

    priceWithGratuity: _ember['default'].computed('gratuity', 'model.price', function () {
      if (this.get('gratuity')) {
        return parseFloat(this.get('model.price')) + parseFloat(this.get('gratuity'));
      } else {
        return parseFloat(this.get('model.price'));
      }
    }),

    bankCard: {
      number: null,
      cvc: null,
      expiry: null
    },

    validations: {
      'bankCard.number': {
        inline: (0, _emberValidations.validator)(function () {
          if (!Stripe.card.validateCardNumber(this.get('bankCard.number'))) {
            return "Wrong card number.";
          }
        })
      },

      'bankCard.cvc': {
        inline: (0, _emberValidations.validator)(function () {
          if (!Stripe.card.validateCVC(this.get('bankCard.cvc'))) {
            return "Wrong cvc number.";
          }
        })
      },

      'bankCard.expiry': {
        inline: (0, _emberValidations.validator)(function () {
          if (!Stripe.card.validateExpiry(this.get('bankCard.expiry'))) {
            return "Wrong exp date.";
          }
        })
      },

      'gratuity': {
        numericality: { allowBlank: true }
      }
    },

    actions: {
      closeModal: function closeModal() {
        console.log('Close modal');
      },

      setGratuity: function setGratuity(percentage) {
        var gratuity = parseFloat(this.get('model.price')) / 100 * percentage;
        this.set('gratuity', gratuity);
      },

      pay: function pay() {
        this.set('displayErrors', true);

        if (!this.get('isValid')) {
          return false;
        }

        var paymentMilestone = this.get('model'),
            _this = this,
            bankCard = {
          number: this.get('bankCard.number'),
          cvc: this.get('bankCard.cvc'),
          exp: this.get('bankCard.expiry')
        };

        Stripe.card.createToken(bankCard, function (status, response) {
          paymentMilestone.pay({ stripeToken: response.id, graduity: _this.get('gratuity') }).then(function (updatedPaymentMilestone) {
            _this.get('public').pushPayload(updatedPaymentMilestone);
            alert('Charge was successful!');
          }, function (response) {
            if (response.errors) {
              if (parseInt(response.errors[0].status) >= 500) {
                alert('Something went wrong on our servers, please try again later.');
              } else {
                if (response.errors[0].code === 'milestone.paid') {
                  paymentMilestone.reload();
                }

                alert('Error: ' + response.errors[0].message);
              }
            }
          });
        });
      }
    }
  });
});