define('flowprosal-web-client/components/contact-list-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: "row contact-row",
    classNameBindings: ['isOpened:contact-opened'],
    isOpened: false,

    actions: {
      toggleOpen: function toggleOpen() {
        this.toggleProperty('isOpened');
      }
    }
  });
});