define('flowprosal-web-client/routes/public-proposal/change-requests/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    'public': _ember['default'].inject.service('public'),

    model: function model(params) {
      return this.get('public').findRecord('change-request', params.change_request_id);
    },

    actions: {

      closeModal: function closeModal() {
        //do nothing - the modal should stay in place
      },

      changeStatus: function changeStatus(status) {
        var route = this;
        this.currentModel.set('status', status);
        this.currentModel.save().then(function (cr) {
          route.store.pushPayload(cr);
          route.transitionTo('public-proposal');
        });
      }
    }

  });
});