define('flowprosal-web-client/components/input-mask', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].TextField.extend({
    initializeMask: (function () {
      this.$().mask(this.get('mask'));
    }).on('didInsertElement'),

    valueChanged: _ember['default'].observer('value', function () {
      _ember['default'].run.later(this, function () {
        this.$().trigger("keyup");
      });
    })
  });
});