define("flowprosal-web-client/templates/dashboard/loading", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 47,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/dashboard/loading.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("article");
        dom.setAttribute(el1, "class", "buffer loading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el2 = dom.createElement("svg");
        dom.setAttribute(el2, "width", "100%");
        dom.setAttribute(el2, "height", "100%");
        dom.setAttribute(el2, "x", "0px");
        dom.setAttribute(el2, "y", "0px");
        dom.setAttribute(el2, "viewBox", "0 0 395 451");
        dom.setAttribute(el2, "version", "1.1");
        dom.setAttribute(el2, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el2, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "layer opacity");
        dom.setAttribute(el3, "d", "M9,39.9985406 L9,395.001459 C9,412.669748 23.3268457,427 40.9937196,427 L337.00628,427 C354.67069,427 369,412.670954 369,395.001459 L369,39.9985406 C369,22.3302523 354.673154,8 337.00628,8 L40.9937196,8 C23.3293098,8 9,22.3290461 9,39.9985406 Z M1,39.9985406 C1,17.9079566 18.9138432,0 40.9937196,0 L337.00628,0 C359.094202,0 377,17.9147443 377,39.9985406 L377,395.001459 C377,417.092043 359.086157,435 337.00628,435 L40.9937196,435 C18.9057982,435 1,417.085256 1,395.001459 L1,39.9985406 Z");
        dom.setAttribute(el3, "id", "RectangleGreen");
        dom.setAttribute(el3, "fill", "#A7D057");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "class", "layer opacity");
        dom.setAttribute(el3, "d", "M28,55.9985406 L28,411.001459 C28,428.669748 42.3268457,443 59.9937196,443 L356.00628,443 C373.67069,443 388,428.670954 388,411.001459 L388,55.9985406 C388,38.3302523 373.673154,24 356.00628,24 L59.9937196,24 C42.3293098,24 28,38.3290461 28,55.9985406 Z M20,55.9985406 C20,33.9079566 37.9138432,16 59.9937196,16 L356.00628,16 C378.094202,16 396,33.9147443 396,55.9985406 L396,411.001459 C396,433.092043 378.086157,451 356.00628,451 L59.9937196,451 C37.9057982,451 20,433.085256 20,411.001459 L20,55.9985406 Z");
        dom.setAttribute(el3, "id", "RectangleBlue");
        dom.setAttribute(el3, "fill", "#2CADD7");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polygon");
        dom.setAttribute(el3, "class", "layer opacity");
        dom.setAttribute(el3, "id", "Line");
        dom.setAttribute(el3, "fill", "#2CADD7");
        dom.setAttribute(el3, "points", "41.4718974 76.9402042 159.415824 77.4718974 159.471897 66.0035906 41.5279705 65.4718974");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polygon");
        dom.setAttribute(el3, "class", "layer opacity");
        dom.setAttribute(el3, "id", "Line-Copy");
        dom.setAttribute(el3, "fill", "#2CADD7");
        dom.setAttribute(el3, "points", "41.4718974 118.940204 159.415824 119.471897 159.471897 108.003591 41.5279705 107.471897");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polygon");
        dom.setAttribute(el3, "class", "layer opacity");
        dom.setAttribute(el3, "id", "Line-Copy-2");
        dom.setAttribute(el3, "fill", "#2CADD7");
        dom.setAttribute(el3, "points", "41.4901559 161.992024 357.470484 162.490156 357.490156 150.988288 41.5098279 150.490156");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polygon");
        dom.setAttribute(el3, "class", "layer opacity");
        dom.setAttribute(el3, "id", "Line-Copy-3");
        dom.setAttribute(el3, "fill", "#2CADD7");
        dom.setAttribute(el3, "points", "41.4901559 204.992024 357.470484 205.490156 357.490156 193.988288 41.5098279 193.490156");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polygon");
        dom.setAttribute(el3, "class", "layer opacity");
        dom.setAttribute(el3, "id", "Line-Copy-4");
        dom.setAttribute(el3, "fill", "#2CADD7");
        dom.setAttribute(el3, "points", "41.4901559 246.992024 357.470484 247.490156 357.490156 235.988288 41.5098279 235.490156");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polygon");
        dom.setAttribute(el3, "class", "layer opacity");
        dom.setAttribute(el3, "id", "Line-Copy-5");
        dom.setAttribute(el3, "fill", "#2CADD7");
        dom.setAttribute(el3, "points", "41.4901559 289.992024 357.470484 290.490156 357.490156 278.988288 41.5098279 278.490156");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polygon");
        dom.setAttribute(el3, "class", "layer opacity");
        dom.setAttribute(el3, "id", "Line-Copy-6");
        dom.setAttribute(el3, "fill", "#2CADD7");
        dom.setAttribute(el3, "points", "41.4901559 332.992024 357.470484 333.490156 357.490156 321.988288 41.5098279 321.490156");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("polygon");
        dom.setAttribute(el3, "class", "layer opacity");
        dom.setAttribute(el3, "id", "Line-Copy-7");
        dom.setAttribute(el3, "fill", "#2CADD7");
        dom.setAttribute(el3, "points", "41.4901559 375.992024 357.470484 376.490156 357.490156 364.988288 41.5098279 364.490156");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("style");
        dom.setAttribute(el1, "type", "text/css");
        var el2 = dom.createTextNode("\n  body {\n  background: #fff; }\n.buffer {\n  width: 15%;\n  position: absolute;\n  left: 50%;\n  top: 25%;\n  -webkit-transform: translateX(-50%);\n      -ms-transform: translateX(-50%);\n          transform: translateX(-50%); }\n.opacity {\n  animation-duration: 1.5s;\n  animation-name: filling;\n  animation-iteration-count: infinite;\n  /*animation-direction: alternate;*/\n        }\n@-webkit-keyframes filling {\n  0% {\n    opacity: 0.1; }\n  50% {\n    opacity: 1; }\n  100% {\n    opacity: 0.1; } }\n@keyframes filling {\n  0% {\n    opacity: 0.1; }\n  50% {\n    opacity: 1; }\n  100% {\n    opacity: 0.1; } }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});