define('flowprosal-web-client/components/modal-update-subscription', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    actions: {

      closeModal: function closeModal() {
        this.sendAction('closeUpdateSubscriptionModal');
      },

      selectFree: function selectFree() {},

      selectSilver: function selectSilver() {
        this.set('selectedPlanLevel', 1);
        this.set('amount', 39 * 100);
        this.set('description', 'Silver Plan');
        this.set('isShowingStripeCheckout', true);
      },

      selectGold: function selectGold() {
        this.set('selectedPlanLevel', 2);
        this.set('amount', 99 * 100);
        this.set('description', 'Gold Plan');
        this.set('isShowingStripeCheckout', true);
      },

      checkoutClosed: function checkoutClosed() {
        console.log('removing stripe-checkout component');
        this.set('isShowingStripeCheckout', false);
      },

      checkoutToken: function checkoutToken(token, level) {
        this.sendAction('checkoutToken', token, level);
        this.set('isShowingStripeCheckout', false);
      }

    }

  });
});