define('flowprosal-web-client/components/wrapped-phone-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      _ember['default'].run.later(this, function () {
        this.$('.intl-tel-input > input').keyup();

        this.$('.intl-tel-input').append('<label class="input__label input__label--ruri" for="' + this.get('inputId') + '"><span class="input__label-content input__label-content--ruri">' + this.get('label') + '</span></label>');
      });
    }

  });
});