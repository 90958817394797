define('flowprosal-web-client/routes/dashboard/settings/payment', ['exports', 'ember', 'flowprosal-web-client/mixins/save-model-mixin-factory'], function (exports, _ember, _flowprosalWebClientMixinsSaveModelMixinFactory) {
  exports['default'] = _ember['default'].Route.extend((0, _flowprosalWebClientMixinsSaveModelMixinFactory['default'])(), {
    model: function model() {
      return this.store.peekRecord('company', this.get('session.currentCompany.id'));
    },

    actions: {
      closeModal: function closeModal() {
        this.transitionTo('dashboard');
      }
    }
  });
});