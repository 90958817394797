define('flowprosal-web-client/routes/proposals/edit/send', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      closeModal: function closeModal() {
        var proposal = this.modelFor('proposals.edit');

        if (proposal.get('isSubmitted')) {
          this.notifications.addNotification({
            message: 'We sent your proposal successfully to your client.',
            type: 'success',
            autoClear: true,
            position: 'top',
            clearDuration: 4200
          });

          this.transitionTo('dashboard');
        } else {
          this.transitionTo('proposals.edit');
        }
      },

      sendProposal: function sendProposal() {
        var proposal = this.modelFor('proposals.edit');
        proposal.set('status', 'under_submission');
        return proposal.save();
      }
    }
  });
});