define('flowprosal-web-client/components/profile-image', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    imageUrl: _ember['default'].computed('user.avatar', function () {
      return this.get('user.avatar') || '/images/no_image.jpg';
    }),

    userName: _ember['default'].computed('user.fullName', function () {
      return this.get('user.fullName');
    })
  });
});