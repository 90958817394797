define('flowprosal-web-client/models/company', ['exports', 'ember', 'ember-data', 'ember-api-actions'], function (exports, _ember, _emberData, _emberApiActions) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr(),
    streetAddress: _emberData['default'].attr(),
    addressLine2: _emberData['default'].attr(),
    city: _emberData['default'].attr(),
    state: _emberData['default'].attr(),
    zip: _emberData['default'].attr(),
    phone: _emberData['default'].attr(),
    email: _emberData['default'].attr(),
    foundingYear: _emberData['default'].attr('number'),
    foundingMonth: _emberData['default'].attr('number'),
    product: _emberData['default'].attr(),
    differentiator: _emberData['default'].attr(),
    employeeTitle: _emberData['default'].attr(),
    vision: _emberData['default'].attr(),
    slug: _emberData['default'].attr(),
    subscription: _emberData['default'].attr(),
    avatar: _emberData['default'].attr(),
    description: _emberData['default'].attr(),

    openProposals: _emberData['default'].attr(),
    openRevenue: _emberData['default'].attr(),

    paymentRoutingNumber: _emberData['default'].attr(),
    paymentAccountNumber: _emberData['default'].attr(),

    stripeVerificationStatus: _emberData['default'].attr(),
    rawStripeHookData: _emberData['default'].attr(),
    paymentCountry: _emberData['default'].attr(),
    paymentCurrency: _emberData['default'].attr(),
    paymentType: _emberData['default'].attr(),
    paymentFirstName: _emberData['default'].attr(),
    paymentLastName: _emberData['default'].attr(),
    paymentBirthDate: _emberData['default'].attr(),
    paymentLastSsn: _emberData['default'].attr(),
    paymentAddress: _emberData['default'].attr(),
    paymentAddress2: _emberData['default'].attr(),
    paymentCity: _emberData['default'].attr(),
    paymentState: _emberData['default'].attr(),
    paymentZip: _emberData['default'].attr(),
    paymentFullSsnOrPersonalIdNumber: _emberData['default'].attr(),

    paymentBusinessName: _emberData['default'].attr(),
    paymentBusinessEin: _emberData['default'].attr(),
    paymentBusinessRepresentativeFirstName: _emberData['default'].attr(),
    paymentBusinessRepresentativeLastName: _emberData['default'].attr(),

    connectToStripe: (0, _emberApiActions.memberAction)({ path: 'connect_to_stripe' }),

    users: _emberData['default'].hasMany(),
    clients: _emberData['default'].hasMany(),
    proposals: _emberData['default'].hasMany(),
    segmentTemplates: _emberData['default'].hasMany('segment-template', { async: true }),
    workGalleries: _emberData['default'].hasMany('work-gallery', { async: true }),
    images: _emberData['default'].hasMany('image', { async: true }),
    subscriptionPayments: _emberData['default'].hasMany('subscription-payment', { async: true }),

    subscriptionLabel: _ember['default'].computed('subscription', function () {
      var l = this.get('subscription');
      return l === 0 ? 'Free' : l === 1 ? 'Silver' : 'Gold';
    }),

    subscriptionColorClass: _ember['default'].computed('subscription', function () {
      var l = this.get('subscription');
      return l === 0 ? 'subscription-free' : l === 1 ? 'subscription-silver' : 'subscription-gold';
    })

  });
});