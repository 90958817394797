define('flowprosal-web-client/controllers/public-proposal', ['exports', 'ember', 'flowprosal-web-client/config/environment'], function (exports, _ember, _flowprosalWebClientConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    'public': _ember['default'].inject.service('public'),

    plainSegments: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.filter(function (segment) {
        if (segment.get('section') !== 'tos' && segment.get('section') !== 'appendix') {
          var segmentType = segment.get('segmentType');
          return segmentType.length === 0 || segmentType === 'gallery';
        }
      });
    }),

    segmentsSotring: ['position:asc'],
    sortedPLainSegments: _ember['default'].computed.sort('plainSegments', 'segmentsSotring'),

    appendixSegments: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.filter(function (segment) {
        var section = segment.get('section');
        return section === "appendix";
      });
    }),

    tosSegments: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.filter(function (segment) {
        var section = segment.get('section');
        return section === "tos";
      });
    }),

    coverLetterSegment: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.find(function (segment) {
        return segment.get('segmentType') === 'cover_letter';
      });
    }),

    appendixSegment: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.find(function (segment) {
        return segment.get('segmentType') === 'appendix';
      });
    }),

    tosSegment: _ember['default'].computed('model.segments', function () {
      var segments = this.get('model.segments');

      return segments.find(function (segment) {
        return segment.get('segmentType') === 'tos';
      });
    }),

    depositPayments: _ember['default'].computed.filterBy('model.paymentMilestones', 'deposit', true),
    hasDeposit: _ember['default'].computed.gt('depositPayments.length', 0),

    depositPayment: _ember['default'].computed.alias('depositPayments.firstObject'),
    depositAmount: _ember['default'].computed('depositPayment', 'model.finalTotalPrice', function () {
      return this.get('model.finalTotalPrice') * (+this.get('depositPayment.percentage') / 100);
    }),

    isShowingWellDone: false,
    isShowingPayDeposit: false,

    actions: {
      invertSegmentAccaptedValue: function invertSegmentAccaptedValue(segment) {
        segment.set('clientAccepted', !segment.get('clientAccepted'));
        segment.save();
      },

      addNewComment: function addNewComment(segment, text) {
        if (!text || text.trim() === '') {
          return false;
        }

        var comment = this.get('public').createRecord('comment');

        comment.set('segment', segment);
        comment.set('text', text);
        comment.set('visitorId', window.customStorage.getItem(_flowprosalWebClientConfigEnvironment['default'].visitorIdKey));
        comment.set('visitorName', window.customStorage.getItem(_flowprosalWebClientConfigEnvironment['default'].visitorNameKey));

        return comment.save();
      },

      acceptProposal: function acceptProposal() {
        this.set('model.status', 'under_acception');
        var _this = this;
        return this.get('model').save().then(function () {
          _this.set('isShowingWellDone', true);
        });
      },

      declineProposal: function declineProposal() {
        this.set('model.status', 'under_rejection');
        return this.get('model').save();
      },

      closeWellDone: function closeWellDone() {
        this.set('isShowingWellDone', false);
        console.log(this.get('model.scheduledPayment'));
        if (this.get('hasDeposit') && this.get('model.scheduledPayment')) {
          this.set('isShowingPayDeposit', true);
        }
      },

      closePayDeposit: function closePayDeposit() {
        this.set('isShowingPayDeposit', false);
      },

      payDeposit: function payDeposit() {
        this.set('isShowingPayDeposit', false);
        this.transitionToRoute('public-proposal.payment-milestones.show', this.get('depositPayment.id'));
      }
    }
  });
});