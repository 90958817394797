define('flowprosal-web-client/components/form-edit-segment', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    session: _ember['default'].inject.service('session'),

    dzAddedFile: function dzAddedFile() {
      if (this.dropzone.files.length > 1) {
        this.dropzone.removeFile(this.dropzone.files[0]);
      }
    },

    setHeaders: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return { "Authorization": this.get('session.currentUser.authenticationToken'), "CompanyIdentifier": this.get('session.currentCompany.id') };
    }),

    actions: {

      save: function save() {
        var _this = this;
        this.get('model').save().then(function (savedModel) {
          var currentDropZone = Dropzone.forElement(".c-segment-edit__dropzone .dropzone"),
              adapter = _this.container.lookup('adapter:application'),
              url = adapter.buildURL('segment-templates', savedModel.id) + '/upload_image';

          currentDropZone.options.url = url;
          currentDropZone.options.headers = _this.get('setHeaders');

          currentDropZone.on('success', function () {
            _this.sendAction('on-success');
          });

          currentDropZone.processQueue();
        });
      }
    }

  });
});