define('flowprosal-web-client/components/button-upload-segment-image', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    classNames: 'dropzone-segment-image',

    imageUploadUrl: _ember['default'].computed('model.id', function () {
      return this.container.lookup('adapter:application').buildURL('segments', this.get('model.id')) + '/upload_image';
    }),

    setHeaders: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return { "Authorization": this.get('session.currentUser.authenticationToken'), "CompanyIdentifier": this.get('session.currentCompany.id') };
    }),

    actions: {
      uploadComplete: function uploadComplete(file) {
        if (file.status === 'success') {
          var jsonResponse = undefined;
          try {
            jsonResponse = JSON.parse(file.xhr.response);
          } catch (e) {
            jsonResponse = null;
          }
          if (jsonResponse && jsonResponse.data.id) {
            this.get('store').pushPayload(jsonResponse);
          }
        }
      }
    }

  });
});