define('flowprosal-web-client/routes/bootstrap/company', ['exports', 'ember', 'flowprosal-web-client/config/environment'], function (exports, _ember, _flowprosalWebClientConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var user = this.session.get('currentUser');
      var _this = this;
      return this.store.createRecord('company', { users: [user] }).save().then(function (company) {
        window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier, company.id);
        _this.session.set('content.currentCompany', company);
        return company;
      });
    },

    resetController: function resetController(controller) {
      controller.setProperties({
        isShowingWarningModal: false,
        isShowingCustomBioModal: false
      });
    }
  });
});