define('flowprosal-web-client/helpers/nl2br', ['exports', 'ember'], function (exports, _ember) {
  exports.nl2br = nl2br;

  function nl2br(params) {
    var text = _ember['default'].Handlebars.Utils.escapeExpression(params[0]),
        breakTag = '<br>';

    return _ember['default'].String.htmlSafe((text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'));
  }

  exports['default'] = _ember['default'].Helper.helper(nl2br);
});