define('flowprosal-web-client/components/form-edit-proposal-payment-schedule', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    plainMilestones: _ember['default'].computed('proposal.paymentMilestones.length', function () {
      return this.get('proposal.paymentMilestones').filter(function (milestone) {
        return !milestone.get('paymentMilestoneType');
      });
    }),

    onePaymentMilestone: _ember['default'].computed('proposal.paymentMilestones.length', function () {
      return this.get('proposal.paymentMilestones').findBy('paymentMilestoneType', 'one_payment');
    })
  });
});