define("flowprosal-web-client/templates/components/modal-gallery-images", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 19,
                    "column": 6
                  },
                  "end": {
                    "line": 23,
                    "column": 6
                  }
                },
                "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        Drag a photo here\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "dz-message__or");
                var el2 = dom.createTextNode("Or");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "dz-message__btn-select");
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Select a photo");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 6
                  },
                  "end": {
                    "line": 30,
                    "column": 6
                  }
                },
                "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "dz-preview dz-file-preview");
                dom.setAttribute(el1, "style", "display:none;");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2, "data-dz-thumbnail", "");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "dz-progress");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "dz-upload");
                dom.setAttribute(el3, "data-dz-uploadprogress", "");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 4
                },
                "end": {
                  "line": 31,
                  "column": 4
                }
              },
              "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "default-message", [], ["register", ["subexpr", "@mut", [["get", "register", ["loc", [null, [19, 34], [19, 42]]]]], [], []]], 0, null, ["loc", [null, [19, 6], [23, 26]]]], ["block", "preview-template", [], ["register", ["subexpr", "@mut", [["get", "register", ["loc", [null, [25, 35], [25, 43]]]]], [], []]], 1, null, ["loc", [null, [25, 6], [30, 27]]]]],
            locals: ["register"],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 36,
                    "column": 62
                  },
                  "end": {
                    "line": 36,
                    "column": 133
                  }
                },
                "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" of ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "file-size", [["get", "f.bytesSent", ["loc", [null, [36, 95], [36, 106]]]]], [], ["loc", [null, [36, 83], [36, 108]]]], ["inline", "file-size", [["get", "f.total", ["loc", [null, [36, 124], [36, 131]]]]], [], ["loc", [null, [36, 112], [36, 133]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 36,
                    "column": 133
                  },
                  "end": {
                    "line": 36,
                    "column": 154
                  }
                },
                "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "f.message", ["loc", [null, [36, 141], [36, 154]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 4
                },
                "end": {
                  "line": 41,
                  "column": 4
                }
              },
              "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "c-gallery-images__progress");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "c-gallery-images__progress-filename");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "c-gallery-images__progress-progress-text");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3, "class", "c-gallery-images__progress-cancel");
              var el4 = dom.createTextNode("x");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "c-gallery-images__progress-progress-bar");
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "c-gallery-images__progress-progress-bar-inner");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var element5 = dom.childAt(element4, [3]);
              var element6 = dom.childAt(element5, [2]);
              var element7 = dom.childAt(element4, [5, 0]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(element5, 0, 0);
              morphs[2] = dom.createAttrMorph(element6, 'disabled');
              morphs[3] = dom.createElementMorph(element6);
              morphs[4] = dom.createAttrMorph(element7, 'style');
              return morphs;
            },
            statements: [["content", "f.name", ["loc", [null, [35, 57], [35, 67]]]], ["block", "if", [["get", "f.isUploading", ["loc", [null, [36, 68], [36, 81]]]]], [], 0, 1, ["loc", [null, [36, 62], [36, 161]]]], ["attribute", "disabled", ["subexpr", "if", [["subexpr", "not", [["get", "isUploading", ["loc", [null, [37, 107], [37, 118]]]]], [], ["loc", [null, [37, 102], [37, 119]]]], "disabled"], [], ["loc", [null, [37, 97], [37, 132]]]]], ["element", "action", ["cancelUpload", ["get", "f", ["loc", [null, [37, 84], [37, 85]]]]], [], ["loc", [null, [37, 60], [37, 87]]]], ["attribute", "style", ["concat", ["width:", ["get", "f.progress", ["loc", [null, [39, 135], [39, 145]]]], "%;"]]]],
            locals: ["f"],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 43,
                "column": 2
              }
            },
            "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "c-gallery-images__progress-container");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "drop-zone", [], ["url", ["subexpr", "@mut", [["get", "galleryUploadUrl", ["loc", [null, [9, 10], [9, 26]]]]], [], []], "headers", ["subexpr", "@mut", [["get", "setHeaders", ["loc", [null, [10, 14], [10, 24]]]]], [], []], "autoProcessQueue", true, "method", "put", "class", "dropzone-default c-gallery-images__dropzone", "onAddedFile", ["subexpr", "action", ["galleryFileAdded"], [], ["loc", [null, [14, 18], [14, 45]]]], "onComplete", ["subexpr", "action", ["galleryUploadComplete"], [], ["loc", [null, [15, 17], [15, 49]]]], "uploadprogress", ["subexpr", "action", ["uploadProgress"], [], ["loc", [null, [16, 21], [16, 46]]]]], 0, null, ["loc", [null, [8, 4], [31, 18]]]], ["block", "each", [["get", "uploadingFiles", ["loc", [null, [33, 12], [33, 26]]]]], [], 1, null, ["loc", [null, [33, 4], [41, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 50,
                    "column": 12
                  },
                  "end": {
                    "line": 52,
                    "column": 12
                  }
                },
                "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "fa fa-heart-o");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 52,
                    "column": 12
                  },
                  "end": {
                    "line": 54,
                    "column": 12
                  }
                },
                "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "fa fa-heart");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 6
                },
                "end": {
                  "line": 57,
                  "column": 6
                }
              },
              "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "c-gallery-images__existing-wrapper");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2, "class", "c-gallery-image");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "class", "c-gallery-images__add-button btn btn-green");
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "fa fa-check");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "class", "c-gallery-images__add-button-fav");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1]);
              var element2 = dom.childAt(element0, [3]);
              var element3 = dom.childAt(element0, [5]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element1, 'src');
              morphs[1] = dom.createElementMorph(element2);
              morphs[2] = dom.createElementMorph(element3);
              morphs[3] = dom.createMorphAt(element3, 1, 1);
              return morphs;
            },
            statements: [["attribute", "src", ["concat", [["get", "img.file", ["loc", [null, [47, 46], [47, 54]]]]]]], ["element", "action", ["selectExistingImage", ["get", "img", ["loc", [null, [48, 100], [48, 103]]]]], [], ["loc", [null, [48, 69], [48, 105]]]], ["element", "action", ["favoriteExistingImage", ["get", "img", ["loc", [null, [49, 92], [49, 95]]]]], [], ["loc", [null, [49, 59], [49, 97]]]], ["block", "if", [["get", "img.favorite", ["loc", [null, [50, 18], [50, 30]]]]], [], 0, 1, ["loc", [null, [50, 12], [54, 19]]]]],
            locals: ["img"],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 2
              },
              "end": {
                "line": 59,
                "column": 2
              }
            },
            "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "c-gallery-images__existing");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "companyImages", ["loc", [null, [45, 14], [45, 27]]]]], [], 0, null, ["loc", [null, [45, 6], [57, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["multiple-nodes", "wrong-type"]
          },
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 63,
              "column": 0
            }
          },
          "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1, "class", "c-gallery-images__heading text-center");
          var el2 = dom.createTextNode("Add images");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "text-center");
          var el2 = dom.createTextNode("Add new images to your segment using the upload tool below.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "href", "#");
          dom.setAttribute(el2, "class", "c-gallery-images__upload-link");
          var el3 = dom.createTextNode("Upload new");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "href", "#");
          dom.setAttribute(el2, "class", "c-gallery-images__existing-link");
          var el3 = dom.createTextNode("Use existing");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "c-gallery-images__footer text-center");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-green");
          var el3 = dom.createTextNode("Done");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [5]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element8, [2]);
          var element11 = dom.childAt(fragment, [9, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element8, 'class');
          morphs[1] = dom.createElementMorph(element9);
          morphs[2] = dom.createElementMorph(element10);
          morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[4] = dom.createAttrMorph(element11, 'disabled');
          morphs[5] = dom.createElementMorph(element11);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["c-gallery-images__selector text-center ", ["subexpr", "if", [["get", "isShowingUpload", ["loc", [null, [4, 56], [4, 71]]]], "c-gallery-images__selector--upload", "c-gallery-images__selector--existing"], [], ["loc", [null, [4, 51], [4, 149]]]]]]], ["element", "action", ["showUploadPanel"], [], ["loc", [null, [5, 54], [5, 82]]]], ["element", "action", ["showSelectPanel"], [], ["loc", [null, [5, 149], [5, 177]]]], ["block", "if", [["get", "isShowingUpload", ["loc", [null, [7, 8], [7, 23]]]]], [], 0, 1, ["loc", [null, [7, 2], [59, 9]]]], ["attribute", "disabled", ["subexpr", "if", [["get", "uploadCount", ["loc", [null, [61, 90], [61, 101]]]], "disabled"], [], ["loc", [null, [61, 85], [61, 114]]]]], ["element", "action", ["closeUploadGalleryImageModal"], [], ["loc", [null, [61, 34], [61, 75]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 64,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/components/modal-gallery-images.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-dialog", [], ["container-class", "c-gallery-images", "close", "closeUploadGalleryImageModal", "translucentOverlay", true], 0, null, ["loc", [null, [1, 0], [63, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});