define('flowprosal-web-client/routes/contacts/show', ['exports', 'ember', 'flowprosal-web-client/mixins/save-model-mixin-factory'], function (exports, _ember, _flowprosalWebClientMixinsSaveModelMixinFactory) {
  exports['default'] = _ember['default'].Route.extend((0, _flowprosalWebClientMixinsSaveModelMixinFactory['default'])('contacts'), {

    selectedClient: [],

    afterModel: function afterModel() {
      var _this = this;
      return this.store.findAll('client').then(function (result) {
        _this.set('selectedClient', result);
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('clients', this.get('selectedClient'));
    },

    actions: {
      deleteContact: function deleteContact(model) {
        if (model.get('proposals.length') > 0) {
          this.get('notifications').addNotification({
            message: 'You cannot delete this contact, because it has associated proposal(s).',
            type: 'error',
            autoClear: true,
            clearDuration: 2200
          });
        } else {
          if (confirm('Are you sure?')) {
            model.destroyRecord();
            this.transitionTo('contacts');
          }
        }
      },

      updateNotes: function updateNotes(model, notes) {
        model.set('notes', notes);
        model.save();
      }
    }
  });
});