define('flowprosal-web-client/mirage/factories/client', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = _emberCliMirage['default'].Factory.extend({
    name: _emberCliMirage.faker.name.firstName,
    phone: _emberCliMirage.faker.phone.phoneNumber,
    email: _emberCliMirage.faker.internet.email,
    summary: _emberCliMirage.faker.lorem.sentence
  });
});
/*
  This is an example factory definition.

  Create more files in this directory to define additional factories.
*/