define('flowprosal-web-client/components/dynamic-biography', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], {
    _inbound_actions_setup: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.set('actionReceiver', this);
      });
    }).on('init'),

    validations: {
      companyName: {
        presence: {
          message: "Company name is required"
        }
      }
    },

    fragments: _ember['default'].computed('template', function () {
      var rawFragments = this.get('template').split(/({{[^}]+}})/),
          finalFragments = [],
          finalFragment = null;

      rawFragments.forEach(function (fragment) {
        if (fragment !== '') {

          if (fragment.lastIndexOf('{{', 0) === 0) {
            finalFragment = {
              label: fragment.substring(2, fragment.length - 2),
              propertyName: fragment.substring(2, fragment.length - 2).camelize()
            };
          } else {
            finalFragment = {
              text: fragment
            };
          }

          finalFragments.push(finalFragment);
        }
      });

      return finalFragments;
    }),

    getBio: function getBio() {
      var _this = this;

      var normalizedFragments = this.get('fragments').map(function (fragment) {
        if (fragment.text) {
          return fragment.text;
        } else {
          return _this.get(fragment.propertyName);
        }
      });

      return normalizedFragments.join('');
    },

    isFilledCompletely: function isFilledCompletely() {
      var _this = this;

      return !this.get('fragments').any(function (fragment) {
        return fragment.propertyName && !_this.get(fragment.propertyName);
      });
    }
  });
});