define('flowprosal-web-client/controllers/segments/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    gridStackOptions: {
      height: 12,
      width: 12,
      cell_height: 30,
      vertical_margin: 0,
      static_grid: false,
      float: true,
      animate: true
    },

    uploadingFiles: [],

    galleryUploadUrl: _ember['default'].computed('model.id', function () {
      return this.container.lookup('adapter:application').buildURL('segment-templates', this.get('model.id')) + '/upload_gallery_image';
    }),

    imageUploadUrl: _ember['default'].computed('model.id', function () {
      return this.container.lookup('adapter:application').buildURL('segment-templates', this.get('model.id')) + '/upload_image';
    }),

    setHeaders: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return { "Authorization": this.get('session.currentUser.authenticationToken'), "CompanyIdentifier": this.get('session.currentCompany.id') };
    }),

    filteredImages: _ember['default'].computed('model.company.images.@each.id', 'model.imageIds.[]', function () {
      var ret = _ember['default'].A([]);
      var company = this.get('model.company');
      var alreadyUsed = this.get('model.imageIds');

      if (alreadyUsed) {
        if (company.get('images')) {
          company.get('images').forEach(function (im) {
            if (alreadyUsed.indexOf(im.get('id')) < 0) {
              ret.pushObject(im);
            }
          });
        }
      }

      return ret;
    }),

    actions: {
      saveModel: function saveModel() {
        this.get('model').save();
      },

      showImagesModal: function showImagesModal() {
        this.set('isShowingImagesModal', true);
      },

      showImagesModalForSingleImage: function showImagesModalForSingleImage() {
        this.set('isShowingSingleImageModal', true);
      },

      closeImagesModal: function closeImagesModal() {
        if (this.get('uploadingFiles.length') < 1) {
          this.set('isShowingImagesModal', false);
          this.set('isShowingSingleImageModal', false);
        }
      },

      removeGalleryImage: function removeGalleryImage(item) {
        var model = this.get('model');
        model.get('gridData').removeObject(model.get('gridData').findBy('content.id', _ember['default'].get(item, 'content.id')));
      },

      galleryFileAdded: function galleryFileAdded(file) {
        this.get('uploadingFiles').pushObject(file.name);
      },

      galleryUploadComplete: function galleryUploadComplete(file) {
        this.get('uploadingFiles').removeObject(file.name);
        if (file.status === 'success') {
          var jsonResponse = undefined;
          try {
            jsonResponse = JSON.parse(file.xhr.response);
          } catch (e) {
            jsonResponse = null;
          }
          if (jsonResponse && jsonResponse.data.id) {
            this.get('store').pushPayload(jsonResponse);
            var model = this.get('model');
            model.get('gridData').push({ x: 5, y: 5, h: 2, w: 2, content: { id: jsonResponse.data.id } });
            model.save();
            model.get('company').get('images').reload();
          }
        }
      },

      galleryUploadAbort: function galleryUploadAbort(file) {
        this.get('uploadingFiles').removeObject(file.name);
      },

      segmentImageUploadComplete: function segmentImageUploadComplete(file) {
        this.get('uploadingFiles').removeObject(file.name);
        if (file.status === 'success') {
          var jsonResponse = undefined;
          try {
            jsonResponse = JSON.parse(file.xhr.response);
          } catch (e) {
            jsonResponse = null;
          }
          if (jsonResponse && jsonResponse.data.id) {
            this.get('store').pushPayload(jsonResponse);
          }
        }
        this.set('isShowingSingleImageModal', false);
      },

      closeUploadGalleryImageModal: function closeUploadGalleryImageModal() {
        if (this.get('uploadingFiles.length') < 1) {
          this.set('uploadTargetSegment', null);
          this.set('isShowingUploadGalleryImageModal', false);
        }
      },

      closeImagesModalForSingleImage: function closeImagesModalForSingleImage() {
        if (this.get('uploadingFiles.length') < 1) {
          this.set('isShowingSingleImageModal', false);
        }
      },

      onGridChanged: function onGridChanged(segment, updatedItems) {
        var hasChanged = false;
        var myItems = segment.get('gridData');
        updatedItems.forEach(function (it) {
          var thisItem = myItems.findBy('content.id', _ember['default'].get(it, 'content.id'));
          if (thisItem && (_ember['default'].get(thisItem, 'x') !== it.x || _ember['default'].get(thisItem, 'y') !== it.y || _ember['default'].get(thisItem, 'w') !== it.w || _ember['default'].get(thisItem, 'h') !== it.h)) {
            _ember['default'].set(thisItem, 'x', it.x);
            _ember['default'].set(thisItem, 'y', it.y);
            _ember['default'].set(thisItem, 'w', it.w);
            _ember['default'].set(thisItem, 'h', it.h);
            hasChanged = true;
          }
        });
        if (hasChanged) {
          _ember['default'].run.throttle(segment, segment.save, 700);
        }
      },

      addExistingImageToGrid: function addExistingImageToGrid(image) {
        var model = this.get('model');
        model.get('gridData').push({ x: 5, y: 5, h: 2, w: 2, content: { id: image.get('id') } });
        model.save();
      },

      addExistingImageToSegment: function addExistingImageToSegment(image) {
        var model = this.get('model');
        model.set('image', image);
        model.save();
        this.set('isShowingSingleImageModal', false);
      }

    }

  });
});