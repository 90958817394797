define('flowprosal-web-client/routes/dashboard/search/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      q: {
        refreshModel: true
      }
    },

    model: function model(params) {
      if (params.q) {
        this.controllerFor('dashboard').set('searchTerm', params.q);

        return _ember['default'].RSVP.hash({
          proposals: this.store.queryRecord('proposal', { filter: { search: params.q } }),
          contacts: this.store.queryRecord('contact', { filter: { search: params.q } }),
          companies: this.store.queryRecord('company', { filter: { search: params.q } }),
          clients: this.store.queryRecord('client', { filter: { search: params.q } })
        });
      } else {
        return _ember['default'].RSVP.hash({
          proposals: this.store.queryRecord('proposal', { filter: { limit: 6 } }),
          contacts: this.store.queryRecord('contact', { filter: { limit: 6 } }),
          companies: this.store.queryRecord('company', { filter: { limit: 6 } }),
          clients: this.store.queryRecord('client', { filter: { limit: 6 } })
        });
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('q', '');
      }
    }

  });
});