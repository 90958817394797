define('flowprosal-web-client/controllers/contacts/new', ['exports', 'ember', 'ember-validations', 'flowprosal-web-client/mixins/save-contact-mixin'], function (exports, _ember, _emberValidations, _flowprosalWebClientMixinsSaveContactMixin) {
  exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], _flowprosalWebClientMixinsSaveContactMixin['default'], {
    actions: {
      clientSelected: function clientSelected(client) {
        this.set('selectedContact', client);
        if (this.get('client.addNewClient')) {
          this.transitionToRoute('clients.new');
        }
      },

      showNewRecordForm: function showNewRecordForm() {
        this.set('displayNewRecordForm', true);
      },
      showSelectExistingForm: function showSelectExistingForm() {
        this.set('displayNewRecordForm', false);
      },

      assignExistingContact: function assignExistingContact() {
        var _this = this;

        var proposal = this.get('proposal');

        proposal.set('contact', this.get('selectedContact'));

        proposal.save().then(function () {
          _this.transitionToRoute('proposals.edit', proposal);
        });
      }
    }
  });
});