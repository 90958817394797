define('flowprosal-web-client/components/intl-tel-input', ['exports', 'ember-intl-tel-input/components/intl-tel-input'], function (exports, _emberIntlTelInputComponentsIntlTelInput) {
  exports['default'] = _emberIntlTelInputComponentsIntlTelInput['default'].extend({

    allowDropdown: false,

    didInsertElement: function didInsertElement() {
      var notifyPropertyChange = this.notifyPropertyChange.bind(this, 'value');

      this.$().change(notifyPropertyChange);

      this.$().intlTelInput({
        allowDropdown: this.get('allowDropdown'),
        allowExtensions: this.get('allowExtensions'),
        autoFormat: this.get('autoFormat'),
        autoHideDialCode: this.get('autoHideDialCode'),
        autoPlaceholder: this.get('autoPlaceholder'),
        defaultCountry: this.get('defaultCountry'),
        geoIpLookup: this.get('geoIpLookup'),
        nationalMode: this.get('nationalMode'),
        numberType: this.get('numberType'),
        onlyCountries: this.get('onlyCountries'),
        preferredCountries: this.get('preferredCountries')
      });
    }

  });
});