define('flowprosal-web-client/components/modal-payment-schedule', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    onDidInitAttrs: _ember['default'].on('didInitAttrs', function (_ref) {
      var attrs = _ref.attrs;

      var modelCopy = _ember['default'].get(attrs, 'model.value').map(function (it) {
        return { deposit: it.deposit, percentage: it.percentage, description: it.description };
      });
      if (modelCopy.length === 1) {
        this.set('isShowingSchedule', false);
      } else {
        this.set('isShowingSchedule', true);
      }

      if (this.get('isShowingSchedule')) {
        this.set('scheduleModel', _ember['default'].A(modelCopy));
        this.set('onePaymentModel', _ember['default'].A([{ deposit: false, percentage: 100, description: 'one_payment_completion' }]));
      } else {
        this.set('scheduleModel', _ember['default'].A({ deposit: false, percentage: 0, description: 'Upon completion' }));
        this.set('onePaymentModel', modelCopy);
      }
    }),

    schedulePercentages: _ember['default'].computed.mapBy('scheduleModel', 'percentage'),

    scheduleNumberPercentages: _ember['default'].computed('schedulePercentages', function () {
      return this.get('schedulePercentages').map(function (it) {
        return +it;
      });
    }),

    scheduleTotalPercentages: _ember['default'].computed.sum('scheduleNumberPercentages'),

    scheduleRemaining: _ember['default'].computed('scheduleTotalPercentages', function () {
      return 100 - this.get('scheduleTotalPercentages');
    }),

    updateIsDisabled: _ember['default'].computed('isShowingSchedule', 'scheduleRemaining', function () {
      return this.get('isShowingSchedule') ? this.get('scheduleRemaining') !== 0 : false;
    }),

    firstMilestone: _ember['default'].computed.alias('scheduleModel.firstObject'),

    restMilestones: _ember['default'].computed('scheduleModel.[]', function () {
      return this.get('scheduleModel').without(this.get('firstMilestone'));
    }),

    actions: {

      closeModal: function closeModal() {
        this.sendAction('closePaymentScheduleModal');
      },

      showSchedulePanel: function showSchedulePanel() {
        this.set('isShowingSchedule', true);
      },

      showOnePaymentPanel: function showOnePaymentPanel() {
        this.set('isShowingSchedule', false);
      },

      addScheduleLine: function addScheduleLine() {
        this.get('scheduleModel').pushObject({ deposit: false, percentage: 0, description: "Description" });
      },

      removeScheduleLine: function removeScheduleLine(line) {
        this.get('scheduleModel').removeObject(line);
      },

      updateTerms: function updateTerms() {
        var updatedModel = (this.get('isShowingSchedule') ? this.get('scheduleModel') : this.get('onePaymentModel')).map(function (line) {
          return { deposit: _ember['default'].get(line, 'deposit'), percentage: +_ember['default'].get(line, 'percentage'), description: _ember['default'].get(line, 'description') };
        });
        var gratuity = this.get('user.gratuity');

        if (this.get('isShowingSchedule')) {
          if (!this.get('updateIsDisabled')) {
            this.sendAction('updateTerms', updatedModel, gratuity);
            this.sendAction('closePaymentScheduleModal');
          }
        } else {
          this.sendAction('updateTerms', updatedModel, gratuity);
          this.sendAction('closePaymentScheduleModal');
        }
      }

    }

  });
});