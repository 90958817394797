define('flowprosal-web-client/routes/dashboard/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('user', model.user);
      controller.set('company', model.company);
      controller.set('followUpClients', model.followUpClients);
      controller.set('checkInClients', model.checkInClients);
    },

    actions: {
      didTransition: function didTransition() {
        this.get('controller').resetIntrojs();
      }
    }
  });
});