define('flowprosal-web-client/services/date', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    monthNames: 'January February March April\n               May June July August\n               September October November December'.w(),

    monthAt: function monthAt(index) {
      return this.get('monthNames')[index];
    },

    yearsBack: function yearsBack(endAfter) {
      var currentYear = new Date().getFullYear();
      var years = [];

      for (var y = currentYear; y > currentYear - endAfter; y--) {
        years.push(y);
      }
      return years;
    }

  });
});