define('flowprosal-web-client/models/proposal', ['exports', 'ember', 'ember-data', 'ember-api-actions'], function (exports, _ember, _emberData, _emberApiActions) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string', { defaultValue: 'unsubmitted' }),
    publicToken: _emberData['default'].attr('string'),
    scheduledPayment: _emberData['default'].attr('boolean'),
    gratuity: _emberData['default'].attr('boolean'),

    companyName: _emberData['default'].attr('string'),
    companyUserName: _emberData['default'].attr('string'),
    companyUserPhone: _emberData['default'].attr('string'),
    companyUserEmail: _emberData['default'].attr('string'),

    companyStreetAddress: _emberData['default'].attr('string'),
    companyCity: _emberData['default'].attr('string'),
    companyState: _emberData['default'].attr('string'),
    companyZip: _emberData['default'].attr('string'),
    companyAddressLine2: _emberData['default'].attr('string'),
    proposalTemplate: _emberData['default'].attr('string', { defaultValue: 'minimalist' }),

    colorPrimary: _emberData['default'].attr(),
    colorAccent: _emberData['default'].attr(),
    colorText: _emberData['default'].attr(),

    viewCount: _emberData['default'].attr('number'),

    template: _emberData['default'].attr('boolean'),
    position: _emberData['default'].attr('number'),
    companyId: _emberData['default'].attr(),

    //Relationships
    segments: _emberData['default'].hasMany('segment', { async: true }),
    activities: _emberData['default'].hasMany('activity', { async: true }),
    paymentMilestones: _emberData['default'].hasMany('payment-milestones', { async: true }),
    changeRequests: _emberData['default'].hasMany('change-request', { async: true }),
    company: _emberData['default'].belongsTo('company', { async: true }),
    contact: _emberData['default'].belongsTo('contact', { async: true }),
    client: _emberData['default'].belongsTo('client', { async: true }),
    user: _emberData['default'].belongsTo('user', { async: true }),

    segmentPrices: _ember['default'].computed.mapBy('segments', 'price'),
    price: _ember['default'].computed.sum('segmentPrices'),

    'e-sign': _emberData['default'].attr('string'),
    updatedAt: _emberData['default'].attr('date'),
    createdAt: _emberData['default'].attr('date'),
    submittedAt: _emberData['default'].attr('date'),
    acceptedAt: _emberData['default'].attr('date'),

    //Custom actions
    duplicate: (0, _emberApiActions.memberAction)({ path: 'duplicate' }),
    visit: (0, _emberApiActions.memberAction)({ path: 'visit' }),
    completed: (0, _emberApiActions.memberAction)({ path: 'completed' }),

    isUnsubmitted: _ember['default'].computed.equal('status', 'unsubmitted'),
    isExactlySubmitted: _ember['default'].computed.equal('status', 'submitted'),
    isAccepted: _ember['default'].computed.equal('status', 'accepted'),
    isRejected: _ember['default'].computed.equal('status', 'rejected'),
    isCompleted: _ember['default'].computed.equal('status', 'completed'),
    isClosed: _ember['default'].computed.equal('status', 'closed'),

    simplifiedStatus: _ember['default'].computed('status', function () {
      if (this.get('isUnsubmitted')) {
        return 'draft';
      } else {
        return this.get('status');
      }
    }),

    isSubmitted: _ember['default'].computed.or('isExactlySubmitted', 'isAccepted', 'isRejected'),

    isInFinalStatus: _ember['default'].computed.or('isAccepted', 'isRejected', 'isCompleted', 'isClosed'),

    isSigned: _ember['default'].computed.notEmpty('e-sign'),

    totalPrice: _ember['default'].computed('segments.length', 'segments.@each.price', 'segments.content.@each.price', 'segments.@each.required', 'segments.content.@each.required', function () {
      var totalPrice = 0;

      this.get('segments').forEach(function (segment) {
        if (segment.get('required')) {
          totalPrice += segment.get('price') * 1;
        }
      });

      return totalPrice;
    }),

    finalTotalPrice: _ember['default'].computed('segments.length', 'segments.@each.price', 'segments.content.@each.price', 'segments.@each.required', 'segments.content.@each.required', 'segments.content.@each.clientAccepted', function () {
      var totalPrice = 0;

      this.get('segments').forEach(function (segment) {
        if (segment.get('required') || segment.get('clientAccepted')) {
          totalPrice += segment.get('price') * 1;
        }
      });

      return totalPrice;
    }),

    paidPayments: _ember['default'].computed.filterBy('paymentMilestones', 'status', 'paid'),

    paidPaymentPrices: _ember['default'].computed.mapBy('paidPayments', 'price'),
    sumPaidPaymentPrices: _ember['default'].computed.sum('paidPaymentPrices'),

    publicUrl: _ember['default'].computed('publicToken', 'company.didLoad', 'company.slug', function () {
      return window.location.protocol + '//' + window.location.host + '/' + this.get('company').get('slug') + '/proposals/' + this.get('publicToken');
    })
  });
});