define('flowprosal-web-client/controllers/bootstrap/team', ['exports', 'ember', 'flowprosal-web-client/mixins/intro/sign-up-team-intro-mixin'], function (exports, _ember, _flowprosalWebClientMixinsIntroSignUpTeamIntroMixin) {
  exports['default'] = _ember['default'].Controller.extend(_flowprosalWebClientMixinsIntroSignUpTeamIntroMixin['default'], {
    teamBios: [],
    isShowingWarningModal: false,
    isShowingCustomBioModal: false,
    modalConfirmAction: null,

    company: _ember['default'].computed.alias('model'),

    bioStyles: _ember['default'].computed('property', function () {
      return this.store.query('biography', { 'filter[team]': true });
    }),

    newMemberBio: function newMemberBio() {
      return this.store.createRecord('segmentTemplate', { sourceType: 'team_member', content: null, favorite: true });
    },

    addFirstMemberBio: _ember['default'].on('init', function () {
      this.get('teamBios').pushObject(this.newMemberBio());
    }),

    filledBios: function filledBios() {
      return this.get('teamBios').reject(function (memberBio) {
        if (memberBio.get('selectedStyle')) {
          return !memberBio.get('dynamicBiographyComponent').isFilledCompletely();
        }

        return !memberBio.get('customBio');
      });
    },

    saveFilled: function saveFilled() {
      var company = this.get('company');
      var filled = this.filledBios();
      var currentUser = this.get('session.currentUser');
      var id = 1,
          content = undefined;

      var segmentSaves = filled.map(function (segmentTemplate) {
        if (segmentTemplate.get('selectedStyle')) {
          content = segmentTemplate.get('dynamicBiographyComponent').getBio();
        } else {
          content = segmentTemplate.get('customBio');
        }

        segmentTemplate.setProperties({
          company: company,
          user: currentUser,
          title: 'Member ' + id++,
          favorite: true,
          content: content
        });
        return segmentTemplate.save();
      });

      return _ember['default'].RSVP.all(segmentSaves);
    },

    saveFilledAndProceed: function saveFilledAndProceed() {
      var _this = this;

      var promise = this.saveFilled().then(function () {
        _this.transitionToRoute('bootstrap.services', _this.get('company'));
      });

      return this.addErrorHandling(promise);
    },

    addErrorHandling: function addErrorHandling(promise) {
      var _this2 = this;

      promise['catch'](function () {
        _this2.set('isShowingWarningModal', false);
        _this2.flashMessages.danger("Some bios could not be saved.");
      });
    },

    hasIncompleteBios: function hasIncompleteBios() {
      return this.filledBios().length < this.get('teamBios.length');
    },

    actions: {
      addTeamMemberBio: function addTeamMemberBio() {
        this.get('teamBios').pushObject(this.newMemberBio());
      },

      showCustomBioModal: function showCustomBioModal(memberBio) {
        this.set('selectedMemberBio', memberBio);
        this.set('isShowingCustomBioModal', true);
      },

      addCustomBio: function addCustomBio(customBio) {
        this.set('selectedMemberBio.customBio', customBio);
        this.send('closeCustomBioModal');
      },

      closeCustomBioModal: function closeCustomBioModal() {
        this.set('selectedMemberBio', null);
        this.set('isShowingCustomBioModal', false);
      },

      // TODO: Remove unused action
      removeTeamMemberBio: function removeTeamMemberBio(memberBio) {
        this.get('teamBios').removeObject(memberBio);
      },

      deleteSegmentTemplate: function deleteSegmentTemplate(segmentTemplate) {
        this.get('existingTeamBios').removeObject(segmentTemplate);
        segmentTemplate.destroyRecord();
      },

      validateAndSave: function validateAndSave() {
        if (this.hasIncompleteBios()) {
          this.setProperties({
            isShowingWarningModal: true,
            modalConfirmAction: 'saveAndProceed'
          });
          return;
        }

        return this.saveFilledAndProceed();
      },

      confirmCancel: function confirmCancel() {
        if (this.hasIncompleteBios()) {
          this.setProperties({
            isShowingWarningModal: true,
            modalConfirmAction: 'saveAndCancel'
          });
        }
      },

      saveAndProceed: function saveAndProceed() {
        return this.saveFilledAndProceed();
      },

      saveAndCancel: function saveAndCancel() {
        var _this3 = this;

        var promise = this.saveFilled().then(function () {
          _this3.transitionToRoute('dashboard');
        });

        return this.addErrorHandling(promise);
      },

      toggleWarningModal: function toggleWarningModal() {
        this.toggleProperty('isShowingWarningModal');
      }
    }
  });
});