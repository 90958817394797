define('flowprosal-web-client/controllers/clients/new', ['exports', 'ember', 'flowprosal-web-client/mixins/save-client-mixin'], function (exports, _ember, _flowprosalWebClientMixinsSaveClientMixin) {
  exports['default'] = _ember['default'].Controller.extend(_flowprosalWebClientMixinsSaveClientMixin['default'], {
    clients: _ember['default'].computed('session.currentCompany.clients.length', function () {
      return this.get('session.currentCompany.clients');
    }),

    actions: {
      showNewRecordForm: function showNewRecordForm() {
        this.set('displayNewRecordForm', true);
      },
      showSelectExistingForm: function showSelectExistingForm() {
        this.set('displayNewRecordForm', false);
      },

      assignExistingClient: function assignExistingClient() {
        var _this = this;

        var proposal = this.get('proposal');

        proposal.set('client', this.get('selectedClient'));
        proposal.set('contact', null);

        proposal.save().then(function () {
          _this.transitionToRoute('proposals.edit', proposal);
        });
      }
    }
  });
});