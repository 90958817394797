define('flowprosal-web-client/mixins/save-model-mixin-factory', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = saveModelMixinFactory;

  function saveModelMixinFactory(targetRouteName) {

    return _ember['default'].Mixin.create({

      actions: {

        save: function save() {
          var route = this;
          this.currentModel.save().then(function () {
            route.transitionTo(targetRouteName);
          }, function () {
            console.log('Failed to save the model');
          });
        }

      },

      deactivate: function deactivate() {
        if (this.currentModel.get('isNew')) {
          this.currentModel.deleteRecord();
        } else {
          this.currentModel.rollbackAttributes();
        }
      }
    });
  }
});