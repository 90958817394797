define('flowprosal-web-client/components/custom-company-bio-form', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], {
    classNames: ['custom-bio-form'],
    tagName: 'form',

    companyName: '',
    companyBio: '',

    saveAction: null,
    cancelAction: null,

    displayErrors: false,

    validations: {
      companyName: {
        presence: {
          message: "Company name is required"
        }
      },
      companyBio: {
        presence: {
          message: "Company bio is required"
        }
      }
    },

    submit: _ember['default'].on('submit', function () {
      var _this = this;

      this.set('displayErrors', false);
      this.validate().then(function () {
        return _this.attrs.saveAction({
          companyName: _this.get('companyName'),
          companyBio: _this.get('companyBio')
        });
      })['catch'](function (error) {
        // depending on whether we got here from the failed branch of `this.validate` or
        // `this.save()`, the error is either the `Errors` object EmberValidation creates
        // or an error object returned by Ember Data
        if (error instanceof _ember['default'].Object) {
          return;
        }
        _this.set('errors.base', "Could not save company");
      })['finally'](function () {
        _this.set('displayErrors', true);
      });
    }),

    actions: {
      submit: function submit() {
        return this.submit();
      },

      cancel: function cancel() {
        return this.attrs.cancelAction();
      }
    }
  });
});