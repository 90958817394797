define('flowprosal-web-client/models/client', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    phone: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    summary: _emberData['default'].attr('string'),
    notes: _emberData['default'].attr('string'),
    contacts: _emberData['default'].hasMany('contact', { async: true }),
    proposals: _emberData['default'].hasMany('proposal', { async: true }),
    company: _emberData['default'].belongsTo('company'),

    addressLine1: _emberData['default'].attr('string'),
    addressLine2: _emberData['default'].attr('string'),
    city: _emberData['default'].attr('string'),
    state: _emberData['default'].attr('string'),
    zip: _emberData['default'].attr('string')
  });
});