define('flowprosal-web-client/instance-initializers/body-class', ['exports', 'ember-body-class/instance-initializers/body-class'], function (exports, _emberBodyClassInstanceInitializersBodyClass) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberBodyClassInstanceInitializersBodyClass['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberBodyClassInstanceInitializersBodyClass.initialize;
    }
  });
});