define('flowprosal-web-client/controllers/bootstrap/company', ['exports', 'ember', 'flowprosal-web-client/config/environment', 'flowprosal-web-client/mixins/intro/sign-up-company-intro-mixin'], function (exports, _ember, _flowprosalWebClientConfigEnvironment, _flowprosalWebClientMixinsIntroSignUpCompanyIntroMixin) {
  exports['default'] = _ember['default'].Controller.extend(_flowprosalWebClientMixinsIntroSignUpCompanyIntroMixin['default'], {
    dateService: _ember['default'].inject.service('date'),

    bioStyles: _ember['default'].computed('property', function () {
      return this.store.query('biography', { 'filter[team]': false });
    }),
    selectedStyle: null,

    displayErrors: false,

    modalConfirmAction: null,

    isShowingCustomBioModal: false,
    isShowingWarningModal: false,

    saveAll: function saveAll(params) {
      var _this = this;

      var company = params.company;
      var segment = params.segment;

      var currentUser = this.get('session.currentUser');

      function showCompanyError() {
        this.flashMessages.danger('Could not save company.');
      }

      function showBioError() {
        this.flashMessages.danger('Could not save bio.');
      }

      return company.save().then(function (savedCompany) {
        segment.set('company', savedCompany);
        segment.set('user', currentUser);

        _this.session.set('content.currentCompany', savedCompany);

        return segment.save();
      }, showCompanyError).then(function () {
        _this.send('proceedToTeamBios');
      }, showBioError)['catch'](function () {
        _this.flashMessages.danger("Something has gone wrong.");
      });
    },

    dropzoneUrlForCompanyLogo: _ember['default'].computed('session.currentCompany.id', function () {
      return this.container.lookup('adapter:application').buildURL('companies', this.get('session.currentCompany.id')) + '/upload_avatar';
    }),

    dropzoneHeaders: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return { "Authorization": this.get('session.currentUser.authenticationToken'), "CompanyIdentifier": this.get('session.currentCompany.id') };
    }),

    dzAddedFile: function dzAddedFile(file) {
      if (this.dropzone.files.length > 1) {
        if (this.dropzone.files[0].status === 'success') {
          this.dropzone.removeFile(this.dropzone.files[0]);
        } else {
          this.dropzone.removeFile(file);
        }
      }
    },

    actions: {

      logoImageUploadComplete: function logoImageUploadComplete(file) {
        var currentCompanyId = this.get('session.currentCompany.id');
        var jsonResponse = JSON.parse(file.xhr.response);
        this.get('store').pushPayload(jsonResponse);
        var updatedCompany = this.store.peekRecord('company', currentCompanyId);
        this.get('session').set('currentCompany', updatedCompany);
        this.set('currentCompany', updatedCompany);
      },

      validateAndProceed: function validateAndProceed() {
        this.set('displayErrors', true);

        if (!this.get('dynamicBiographyComponent.isValid')) {
          return;
        }

        if (!this.get('dynamicBiographyComponent').isFilledCompletely()) {
          this.set('isShowingWarningModal', true);
          this.set('modalConfirmAction', 'saveCompany');
          return;
        }

        var company = this.get('model'),
            companyDescription = this.get('dynamicBiographyComponent').getBio();

        company.setProperties({
          name: this.get('dynamicBiographyComponent.companyName'),
          description: companyDescription
        });

        var segment = this.store.createRecord('segmentTemplate', {
          title: company.get('name'),
          content: companyDescription,
          company: company,
          user: this.get('session.currentUser'),
          sourceType: 'company'
        });

        return this.saveAll({ company: company, segment: segment });
      },

      saveCompany: function saveCompany() {
        var _this2 = this;

        var company = this.get('model');
        company.set('name', this.get('dynamicBiographyComponent.companyName'));

        var controller = this;
        return company.save().then(function (company) {
          window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier, company.id);
          controller.session.set('content.currentCompany', company);
          _this2.store.unloadAll('proposal');
          _this2.store.unloadAll('segment');
          _this2.store.unloadAll('segment-template');
          _this2.store.unloadAll('contact');
          _this2.store.unloadAll('client');
          _this2.send('proceedToTeamBios');
        })['catch'](function () {
          _this2.flashMessages.danger("Could not save company.");
        });
      },

      saveCustom: function saveCustom(properties) {
        var companyName = properties.companyName;
        var companyBio = properties.companyBio;

        var company = this.get('model');
        var segment = this.store.createRecord('segmentTemplate', {
          title: companyName,
          content: companyBio,
          company: company,
          sourceType: 'company'
        });

        company.set('name', companyName);
        company.set('description', companyBio);

        return this.saveAll({ company: company, segment: segment });
      },

      addCustomBio: function addCustomBio() {
        this.set('isShowingCustomBioModal', true);
      },

      proceedToTeamBios: function proceedToTeamBios() {
        this.transitionToRoute('bootstrap.team', this.get('model'));
      },

      redirectToDashboard: function redirectToDashboard() {
        this.transitionToRoute('dashboard');
      },

      closeCustomBioModal: function closeCustomBioModal() {
        this.setProperties({
          customCompanyName: '',
          customCompanyBio: ''
        });
        this.toggleProperty('isShowingCustomBioModal');
      },

      toggleWarningModal: function toggleWarningModal() {
        this.toggleProperty('isShowingWarningModal');
      },

      cancelSignupFlow: function cancelSignupFlow() {
        if (!this.get('isFilledCompletely')) {
          this.set('modalConfirmAction', 'redirectToDashboard');
          this.toggleProperty('isShowingWarningModal');
        }
      }
    }
  });
});