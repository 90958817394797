define('flowprosal-web-client/routes/proposals/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      return this.store.findRecord('proposal', params.proposal_id, { reload: true });
    },

    actions: {

      openAddChangeRequestModal: function openAddChangeRequestModal() {
        var cr = this.store.createRecord('change-request', { scope: true, proposal: this.get('currentModel') });
        this.get('controller').set('changeRequestModel', cr);
        this.get('controller').set('isShowingChangeRequestModal', true);
      },

      closeAddChangeRequestModal: function closeAddChangeRequestModal() {
        var cr = this.get('controller.changeRequestModel');
        if (cr.get('isNew')) {
          cr.destroyRecord();
        }
        this.get('controller').set('changeRequestModel', null);
        this.get('controller').set('isShowingChangeRequestModal', false);
        this.get('controller').set('isShowingChangeRequestConfirmModal', false);
      },

      reviewChange: function reviewChange() {
        this.get('controller').set('isShowingChangeRequestModal', false);
        this.get('controller').set('isShowingChangeRequestConfirmModal', true);
      },

      requestChange: function requestChange() {
        var _this = this;
        var changeReq = this.get('controller.changeRequestModel');
        changeReq.save().then(function () {
          _this.get('controller').set('changeRequestModel', null);
          _this.get('controller').set('isShowingChangeRequestModal', false);
          _this.get('controller').set('isShowingChangeRequestConfirmModal', false);
        });
      },

      openMarkAsPaidModal: function openMarkAsPaidModal(milestone) {
        this.get('controller').set('markAsPaidModel', milestone);
        this.get('controller').set('isShowingMarkAsPaidModal', true);
      },

      closeMarkAsPaidModal: function closeMarkAsPaidModal() {
        this.get('controller').set('markAsPaidModel', null);
        this.get('controller').set('isShowingMarkAsPaidModal', false);
      },

      duplicateProposal: function duplicateProposal() {
        var proposal = this.get('currentModel');
        var route = this;
        proposal.duplicate().then(function (proposal) {
          route.transitionTo('proposals.edit', proposal.data.id);
        });
      }
    }
  });
});