define('flowprosal-web-client/mirage/scenarios/default', ['exports'], function (exports) {
  exports['default'] = function (server) {

    // Seed your development database using your factories. This
    // data will not be loaded in your tests.

    // server.createList('contact', 10);
    server.createList('user', 1);
    server.createList('company', 10);
    server.createList('contact', 10);
    server.createList('segment-template', 10);
    server.createList('client', 10);
    server.createList('proposal', 2);
  };
});