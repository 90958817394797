define('flowprosal-web-client/mixins/intro/select-segment-intro-mixin', ['exports', 'ember', 'flowprosal-web-client/mixins/intro/intro-mixin'], function (exports, _ember, _flowprosalWebClientMixinsIntroIntroMixin) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Mixin.create(_flowprosalWebClientMixinsIntroIntroMixin['default'], {
    introType: 'selectSegment',
    introDelayForFirstStart: 200,

    initIntrojs: function initIntrojs() {
      this.set('introSteps', [{
        element: $('.c-select-segment__drawer')[0],
        intro: '<h2>Segment drawer</h2>\n        <p>This is the Segment drawer. From here you can add Segments from your library or create new ones to add to your proposal</p>',
        position: 'left',
        tooltipClass: 'intro-tooltip-select-segment introjs-last'
      }]);
    }
  });
});