define('flowprosal-web-client/routes/dashboard/account/payments', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return this.get('session.currentCompany.subscriptionPayments');
    },

    actions: {
      showSubscriptionPayment: function showSubscriptionPayment(subscriptionPayment) {
        this.transitionTo('dashboard.account.payments.show', subscriptionPayment);
      }
    }
  });
});