define('flowprosal-web-client/controllers/proposals/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: {
      q: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    q: null,
    status: 'default',

    availableStatuses: ['ALL', 'DRAFT', 'SUBMITTED', 'ACCEPTED'],

    filteredProposals: _ember['default'].computed('status', function () {
      this.transitionToRoute({ queryParams: { status: this.get('status') } });
    }),

    actions: {
      searchContact: function searchContact() {
        this.transitionToRoute({ queryParams: { q: this.get('filterTerm') } });
      }
    }
  });
});