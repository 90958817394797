define('flowprosal-web-client/controllers/proposals/edit/select-segment', ['exports', 'ember', 'ember-validations', 'flowprosal-web-client/macros/error-if-empty'], function (exports, _ember, _emberValidations, _flowprosalWebClientMacrosErrorIfEmpty) {
  exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {

    queryParams: ['q', 'section'],
    q: null,
    section: null,

    proposalController: _ember['default'].inject.controller('proposals/edit'),

    newSegment: _ember['default'].computed('proposalController.model.segments.length', function () {
      return this.store.createRecord('segment');
    }),

    isSectionExist: _ember['default'].computed('section', function () {
      if (this.get('section') === 'appendix' || this.get('section') === 'tos') {
        return this.get('section');
      } else {
        return '';
      }
    }),

    segmentTitleError: (0, _flowprosalWebClientMacrosErrorIfEmpty['default'])('newSegment.title', 'Title is required'),
    segmentContentError: (0, _flowprosalWebClientMacrosErrorIfEmpty['default'])('newSegment.content', 'Content is required'),

    hasError: _ember['default'].computed.or('segmentTitleError', 'segmentContentError'),
    isValid: _ember['default'].computed.not('hasError'),
    displayErrors: false,

    actions: {
      addSegment: function addSegment(segmentTemplate, isGallery) {
        var model = this.get('proposalController.model');
        var attrs = {
          section: this.get('section'),
          proposal: this.get('proposalController.model'),
          client: this.get('proposalController.model.client'),
          company: this.session.get('currentCompany'),
          user: this.session.get('currentUser')
        };

        if (!isGallery) {
          attrs.segmentTemplateId = segmentTemplate.get('id');
          attrs.segmentType = segmentTemplate.get('segmentType');
        } else {
          attrs.segmentType = 'gallery';
        }

        return this.store.createRecord('segment', attrs).save().then(function (segment) {
          model.get('segments').pushObject(segment);
        });
      },

      createNewSegment: function createNewSegment(newSegment) {
        if (!this.get('isValid')) {
          this.set('displayErrors', true);
          return false;
        }

        this.set('displayErrors', false);

        newSegment.set('proposal', this.get('proposalController.model'));
        newSegment.set('client', this.get('proposalController.model.client'));
        newSegment.set('company', this.session.get('currentCompany'));
        newSegment.set('user', this.session.get('currentUser'));
        newSegment.set('section', this.get('section'));

        return newSegment.save().then(function (segment) {
          return segment;
        });
      },

      reloadSegment: function reloadSegment(segment) {
        this.store.findRecord('segment', segment.id, { reload: true });
      }
    }
  });
});