define('flowprosal-web-client/routes/proposals/edit', ['exports', 'ember', 'flowprosal-web-client/mixins/save-model-mixin-factory'], function (exports, _ember, _flowprosalWebClientMixinsSaveModelMixinFactory) {
  exports['default'] = _ember['default'].Route.extend((0, _flowprosalWebClientMixinsSaveModelMixinFactory['default'])('proposals'), {
    afterModel: function afterModel(proposal) {
      var _this = this;

      if (proposal.get('isInFinalStatus')) {
        return _this.transitionTo('proposals.show', proposal);
      } else {
        return proposal.get('segments');
      }
    },

    actions: {
      didTransition: function didTransition() {
        this.get('controller').resetIntrojs();
      },

      duplicateProposal: function duplicateProposal() {
        var proposal = this.currentModel;
        var route = this;
        proposal.duplicate().then(function (proposal) {
          route.transitionTo('proposals.edit', proposal.data.id);
        });
      },

      deleteProposal: function deleteProposal() {
        var proposal = this.currentModel;
        var route = this;
        proposal.destroyRecord();
        route.transitionTo('proposals.index');
      }
    }
  });
});