define('flowprosal-web-client/routes/proposals/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _ember['default'].RSVP.hash({
        clients: this.store.findAll('client')
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model.proposal);
      controller.set('clients', model.clients.filterBy('isNew', false));

      var clientId = controller.get('clientId');
      if (clientId) {
        var client = this.store.peekRecord('client', clientId);
        controller.set('selectedClient', client);

        var newContact = this.store.createRecord('contact', { client: client });
        controller.set('newContact', newContact);
      }

      var contactId = controller.get('contactId');
      if (contactId) {
        var contact = this.store.peekRecord('contact', contactId);
        controller.set('selectedContact', contact);

        var proposal = this.store.createRecord('proposal', { contact: contact });
        controller.set('proposal', proposal);

        var currentCompany = this.controllerFor('dashboard').get('currentCompany');
        controller.set('currentCompany', currentCompany);
      }
    }
  });
});