define('flowprosal-web-client/routes/public-proposal', ['exports', 'ember', 'flowprosal-web-client/config/environment', 'ember-uuid'], function (exports, _ember, _flowprosalWebClientConfigEnvironment, _emberUuid) {

  /* global window */

  exports['default'] = _ember['default'].Route.extend({
    'public': _ember['default'].inject.service('public'),

    visitorName: null,
    visitorId: null,

    model: function model(params) {
      return this.get('public').queryRecord('proposal', { filter: { publicToken: params.proposal_token } });
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model[0]);

      var visitorName = window.customStorage.getItem(_flowprosalWebClientConfigEnvironment['default'].visitorNameKey);
      var visitorId = window.customStorage.getItem(_flowprosalWebClientConfigEnvironment['default'].visitorIdKey);
      if (!(visitorId && visitorName)) {
        controller.set('showNameModalDialog', true);
      }
    },

    actions: {
      closeModal: function closeModal() {
        this.transitionTo('public-proposal');
      },

      setName: function setName(name) {
        var token = (0, _emberUuid.v1)();
        var route = this;
        window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].visitorNameKey, name);
        window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].visitorIdKey, token);
        this.controller.get('model').visit({ visitor_name: name, visitor_id: token }).then(function () {
          route.controller.set('showNameModalDialog', false);
        });
      },

      askSignature: function askSignature() {
        this.controller.set('showSignaturePad', true);
      },

      submitESignature: function submitESignature(signatureBase64) {
        var model = this.controller.get('model');
        var _this = this;
        model.set('e-sign', signatureBase64);
        this.controller.set('savingSignature', true);
        model.save().then(function () {
          _this.controller.set('showSignaturePad', false);
          _this.controller.set('savingSignature', false);
        });
      },

      cancelSignature: function cancelSignature() {
        this.controller.set('showSignaturePad', false);
      }
    }
  });
});