define('flowprosal-web-client/routes/contacts/edit', ['exports', 'ember', 'flowprosal-web-client/mixins/save-model-mixin-factory'], function (exports, _ember, _flowprosalWebClientMixinsSaveModelMixinFactory) {
  exports['default'] = _ember['default'].Route.extend((0, _flowprosalWebClientMixinsSaveModelMixinFactory['default'])('contacts'), {

    afterModel: function afterModel() {
      var _this = this;
      return this.store.findAll('client').then(function (result) {
        _this.set('clients', result);
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('clients', this.get('clients'));
    },

    actions: {
      remove: function remove(model) {
        if (confirm('Are you sure?')) {
          model.destroyRecord();
        }
      }
    }
  });
});