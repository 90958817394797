define('flowprosal-web-client/components/ember-inplace-edit', ['exports', 'ember-inplace-edit/components/ember-inplace-edit', 'ember'], function (exports, _emberInplaceEditComponentsEmberInplaceEdit, _ember) {
  exports['default'] = _emberInplaceEditComponentsEmberInplaceEdit['default'].extend({
    model: null,
    classNames: ['ember-inplace-edit'],
    type: 'input',
    enabled: true,
    value: null,
    bindData: true,

    keyPress: function keyPress(event) {
      if (event.keyCode === 13 && this.get('type') !== "textarea") {
        event.preventDefault();
        this.toggleProperty('isEditing');
        var val = this.get('bindData') ? this.get('value') : this.get('newValue');
        this.sendAction('action', this.get('model'), val);
      }
    },

    displayPlaceholder: (function () {
      var text = this.get('text');
      return text === undefined || text === null || text.toString() === "";
    }).property('text'),

    focus: (function () {
      var _this = this;

      if (_this.get('isEditing')) {
        _ember['default'].run.later(function () {
          if (_this.get('type') === 'input') {
            _ember['default'].$("#" + _this.get("elementId") + " input").focus();
          } else {
            _ember['default'].$("#" + _this.get("elementId") + " textarea").focus();
          }

          _this.sendAction('onActivated', _this.$());
        });
      }
    }).observes('isEditing'),

    actions: {
      toggleEditing: function toggleEditing() {
        if (this.get('enabled')) {
          this.toggleProperty('isEditing');
        }
      },

      doneEditing: function doneEditing() {
        if (this.get('isEditing') === true) {
          this.toggleProperty('isEditing');
          var val = this.get('bindData') ? this.get('value') : this.get('newValue');
          this.sendAction('action', this.get('model'), val);
        }
      }
    }
  });
});