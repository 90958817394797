define('flowprosal-web-client/controllers/proposals/show/payment-milestones/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      closeModal: function closeModal() {
        this.transitionToRoute('proposals.show', this.get('model.proposal'));
      },

      sendMilestoneEmail: function sendMilestoneEmail(milestone) {
        var _this = this;
        milestone.set('status', 'under_sending');
        milestone.save().then(function () {
          _this.transitionToRoute('proposals.show', _this.get('model.proposal'));
        });
      }
    }
  });
});