define("flowprosal-web-client/templates/components/custom-company-bio-form", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/components/custom-company-bio-form.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Enter your company's name and fill in the bio below.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "input__label input__label--ruri");
        dom.setAttribute(el2, "for", "input-name");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "input__label-content input__label-content--ruri");
        var el4 = dom.createTextNode("Company name");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "controls");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-gray");
        var el3 = dom.createTextNode("Cancel");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "type", "submit");
        dom.setAttribute(el2, "class", "btn btn-green");
        var el3 = dom.createTextNode("Save");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [4]);
        var element2 = dom.childAt(fragment, [6]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        morphs[4] = dom.createElementMorph(element3);
        morphs[5] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["input input--ruri ", ["subexpr", "if", [["subexpr", "not-empty", [["get", "companyName", ["loc", [null, [3, 47], [3, 58]]]]], [], ["loc", [null, [3, 36], [3, 59]]]], "input--filled"], [], ["loc", [null, [3, 31], [3, 77]]]], " ", ["subexpr", "if", [["subexpr", "and", [["get", "displayErrors", ["loc", [null, [3, 88], [3, 101]]]], ["get", "errors.companyName", ["loc", [null, [3, 102], [3, 120]]]]], [], ["loc", [null, [3, 83], [3, 121]]]], "error"], [], ["loc", [null, [3, 78], [3, 131]]]]]]], ["inline", "input", [], ["type", "text", "id", "custom_company_name", "value", ["subexpr", "@mut", [["get", "companyName", ["loc", [null, [4, 53], [4, 64]]]]], [], []], "class", "input__field input__field--ruri", "id", "input-name"], ["loc", [null, [4, 2], [4, 122]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "and", [["get", "displayErrors", ["loc", [null, [10, 22], [10, 35]]]], ["get", "errors.companyBio", ["loc", [null, [10, 36], [10, 53]]]]], [], ["loc", [null, [10, 17], [10, 54]]]], "error", ""], [], ["loc", [null, [10, 12], [10, 67]]]]]]], ["inline", "textarea", [], ["name", "company_bio", "id", "custom_company_bio", "class", "textarea-default", "value", ["subexpr", "@mut", [["get", "companyBio", ["loc", [null, [13, 11], [13, 21]]]]], [], []], "placeholder", "Company bio"], ["loc", [null, [11, 2], [15, 5]]]], ["element", "action", ["cancel"], [], ["loc", [null, [19, 31], [19, 50]]]], ["element", "action", ["submit"], [], ["loc", [null, [20, 46], [20, 65]]]]],
      locals: [],
      templates: []
    };
  })());
});