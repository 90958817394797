define("flowprosal-web-client/templates/components/user-settings-colors", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/components/user-settings-colors.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-4 col-sm-4");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        dom.setAttribute(el2, "class", "upper");
        var el3 = dom.createElement("strong");
        var el4 = dom.createTextNode("Default colors:");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "c-dashboard-settings__colors-box");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "c-dashboard-settings__colors-box__color-setting clearfix");
        var el4 = dom.createTextNode("\n      Primary ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "c-dashboard-settings__colors-box__color-setting clearfix");
        var el4 = dom.createTextNode("\n      Accent ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "c-dashboard-settings__colors-box__color-setting clearfix");
        var el4 = dom.createTextNode("\n      Text ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 3]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
        return morphs;
      },
      statements: [["inline", "col-pick-circle", [], ["value", ["subexpr", "@mut", [["get", "user.colorPrimary", ["loc", [null, [5, 38], [5, 55]]]]], [], []], "onHide", "updateUserSettings"], ["loc", [null, [5, 14], [5, 85]]]], ["inline", "col-pick-circle", [], ["value", ["subexpr", "@mut", [["get", "user.colorAccent", ["loc", [null, [8, 37], [8, 53]]]]], [], []], "onHide", "updateUserSettings"], ["loc", [null, [8, 13], [8, 83]]]], ["inline", "col-pick-circle", [], ["value", ["subexpr", "@mut", [["get", "user.colorText", ["loc", [null, [11, 35], [11, 49]]]]], [], []], "onHide", "updateUserSettings"], ["loc", [null, [11, 11], [11, 79]]]]],
      locals: [],
      templates: []
    };
  })());
});