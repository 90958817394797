define("flowprosal-web-client/templates/proposals/edit/select-segment", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "flowprosal-web-client/templates/proposals/edit/select-segment.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "select-segment", [], ["cancelSelectSegment", "cancelSelectSegment", "addSegment", ["subexpr", "action", ["addSegment"], [], ["loc", [null, [4, 15], [4, 36]]]], "segmentTemplates", ["subexpr", "@mut", [["get", "model", ["loc", [null, [5, 21], [5, 26]]]]], [], []], "search", "search", "origSearchTerm", ["subexpr", "@mut", [["get", "q", ["loc", [null, [7, 19], [7, 20]]]]], [], []], "isSectionExist", ["subexpr", "@mut", [["get", "isSectionExist", ["loc", [null, [8, 19], [8, 33]]]]], [], []], "newSegment", ["subexpr", "@mut", [["get", "newSegment", ["loc", [null, [9, 15], [9, 25]]]]], [], []], "createNewSegment", ["subexpr", "action", ["createNewSegment"], [], ["loc", [null, [10, 21], [10, 48]]]], "reloadSegment", "reloadSegment", "segmentTitleError", ["subexpr", "@mut", [["get", "segmentTitleError", ["loc", [null, [12, 22], [12, 39]]]]], [], []], "segmentContentError", ["subexpr", "@mut", [["get", "segmentContentError", ["loc", [null, [13, 24], [13, 43]]]]], [], []], "displayErrors", ["subexpr", "@mut", [["get", "displayErrors", ["loc", [null, [14, 18], [14, 31]]]]], [], []]], ["loc", [null, [2, 2], [15, 4]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/proposals/edit/select-segment.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "ember-wormhole", [], ["to", "wormhole-target"], 0, null, ["loc", [null, [1, 0], [16, 19]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});