define('flowprosal-web-client/components/modal-confirm-change', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    actions: {
      closeModal: function closeModal() {
        this.sendAction('on-cancel');
      },

      sendChange: function sendChange() {
        this.sendAction('sendChange');
      }
    }
  });
});