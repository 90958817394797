define('flowprosal-web-client/models/activity', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    activityType: _emberData['default'].attr(),
    visitorName: _emberData['default'].attr(),
    visitorId: _emberData['default'].attr(),
    createdAt: _emberData['default'].attr(),
    updatedAt: _emberData['default'].attr(),

    proposal: _emberData['default'].belongsTo('company'),
    comment: _emberData['default'].belongsTo('company')
  });
});