define('flowprosal-web-client/components/grid-stack-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['grid-stack-item'],
    attributeBindings: ['data-gs-x', 'data-gs-y', 'data-gs-width', 'data-gs-height', 'data-custom-id'],

    'data-gs-x': _ember['default'].computed.reads('item.x'),
    'data-gs-y': _ember['default'].computed.reads('item.y'),
    'data-gs-width': _ember['default'].computed.reads('item.w'),
    'data-gs-height': _ember['default'].computed.reads('item.h'),
    'data-custom-id': _ember['default'].computed.reads('item.content.id'),

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      //console.log('grid-stack-item::didInsertElement');
      this.get('grid').registerChild(this.$());
    }),

    onWillDestroyElement: _ember['default'].on('willDestroyElement', function () {
      //console.log('grid-stack-item::willDestroyElement');
      this.get('grid').unregisterChild(this.$());
    })

  });
});