define('flowprosal-web-client/controllers/dashboard/transactions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['q'],
    q: null,

    actions: {
      filterTransactions: function filterTransactions() {
        this.transitionToRoute({ queryParams: { q: this.get('filterTerm') } });
      }
    }
  });
});