define('flowprosal-web-client/components/modal-edit-user', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], {
    displayErrors: false,

    validations: {
      'model.firstName': {
        presence: true
      },
      'model.lastName': {
        presence: true
      },
      'model.phone': {
        presence: true
      },
      'model.email': {
        presence: true,
        email: true
      }
    },

    actions: {
      save: function save() {
        this.set('displayErrors', !this.get('isValid'));

        if (!this.get('isValid')) {
          return false;
        }

        this.sendAction('on-save', this.get('model'));
      },

      cancel: function cancel() {
        this.sendAction('on-cancel');
      }
    }

  });
});