define('flowprosal-web-client/mirage/factories/proposal', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = _emberCliMirage['default'].Factory.extend({
    title: _emberCliMirage.faker.lorem.sentence,
    status: '',
    template: _emberCliMirage.faker.random.boolean,
    userId: 1,
    companyId: function companyId() {
      return _emberCliMirage.faker.random.number({ min: 1, max: 10 });
    },
    teamId: function teamId() {
      return _emberCliMirage.faker.random.number({ min: 1, max: 10 });
    }
  });
});
// TODO: include these as relationship links in responses
// segments:   DS.hasMany('segment', { async: true }),
// contact:    DS.belongsTo('contact', { async: true }),