define("flowprosal-web-client/templates/components/wrapped-phone-input", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/components/wrapped-phone-input.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "intl-tel-input", [], ["class", ["subexpr", "@mut", [["get", "class", ["loc", [null, [2, 8], [2, 13]]]]], [], []], "id", ["subexpr", "@mut", [["get", "inputId", ["loc", [null, [3, 5], [3, 12]]]]], [], []], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [4, 8], [4, 13]]]]], [], []], "number", ["subexpr", "@mut", [["get", "number", ["loc", [null, [5, 9], [5, 15]]]]], [], []], "isValidNumber", ["subexpr", "@mut", [["get", "isValidNumber", ["loc", [null, [6, 16], [6, 29]]]]], [], []], "validationError", ["subexpr", "@mut", [["get", "validationError", ["loc", [null, [7, 18], [7, 33]]]]], [], []], "allowDropdown", false, "autoFormat", false, "autoHideDialCode", false, "nationalMode", false], ["loc", [null, [1, 0], [12, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});