define("flowprosal-web-client/templates/components/company-avatar", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 83
          }
        },
        "moduleName": "flowprosal-web-client/templates/components/company-avatar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("img");
        dom.setAttribute(el1, "class", "company-avatar-image");
        dom.setAttribute(el1, "width", "175px");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'src');
        morphs[1] = dom.createAttrMorph(element0, 'alt');
        return morphs;
      },
      statements: [["attribute", "src", ["concat", [["get", "imageUrl", ["loc", [null, [1, 41], [1, 49]]]]]]], ["attribute", "alt", ["concat", [["get", "name", ["loc", [null, [1, 74], [1, 78]]]]]]]],
      locals: [],
      templates: []
    };
  })());
});