define('flowprosal-web-client/routes/signup', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('dashboard');
      }
    },

    actions: {
      authenticate: function authenticate(provider) {
        var controller = this.controller;
        var route = this;
        controller.set('error', null);
        this.get('session').open(provider).then(function () {
          route.transitionTo('bootstrap.company');
        }, function (error) {
          controller.set('error', error);
        });
      }
    }
  });
});