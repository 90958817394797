define('flowprosal-web-client/components/custom-team-member-bio-form', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], {
    classNames: ['custom-bio-form'],
    displayErrors: false,

    validations: {
      teamMemberBio: {
        presence: {
          message: "Team member bio is required"
        }
      }
    },

    setTeamMemberBio: _ember['default'].on('init', function () {
      this.set('teamMemberBio', this.get('predefinedBio'));
    }),

    submit: function submit() {
      this.set('displayErrors', true);

      if (this.get('isValid')) {
        this.attrs.saveAction(this.get('teamMemberBio'));
      }
    },

    actions: {
      submit: function submit() {
        this.submit();
      },

      cancel: function cancel() {
        return this.attrs.cancelAction();
      }
    }
  });
});