define('flowprosal-web-client/initializers/local-storage', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() {
    function getStorage() {
      var storageImpl;

      try {
        localStorage.setItem('storage', '');
        localStorage.removeItem('storage');
        storageImpl = localStorage;
      } catch (_) {
        storageImpl = new LocalStorageAlternative();
      }

      return storageImpl;
    }

    function LocalStorageAlternative() {

      var structureLocalStorage = {};

      this.setItem = function (key, value) {
        structureLocalStorage[key] = value;
      };

      this.getItem = function (key) {
        if (typeof structureLocalStorage[key] !== 'undefined') {
          return structureLocalStorage[key];
        } else {
          return null;
        }
      };

      this.removeItem = function (key) {
        structureLocalStorage[key] = undefined;
      };
    }

    window.customStorage = getStorage();
  }

  exports['default'] = {
    name: 'local-storage',
    initialize: initialize
  };
});