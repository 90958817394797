define('flowprosal-web-client/components/grid-stack', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['grid-stack'],
    classNameBindings: ['isEditable:editing'],
    isEditable: false,

    onDidInsertElement: _ember['default'].on('didInsertElement', function () {
      var _this = this;
      this.$().gridstack(this.get('options'));
      var gs = this.$().data('gridstack');
      this.set('grid', gs);
      this.$().on('change', function (e, items) {
        _this.onChangeHandler(e, items);
      });
    }),

    onChangeHandler: function onChangeHandler(e, items) {
      var _this2 = this;

      if (this.attrs.gridChanged) {
        (function () {
          var myItems = _this2.get('items');
          var changed = false;
          if (items && items.length) {
            items.forEach(function (it) {
              var updateThis = myItems.findBy('content.id', it.el.attr('data-custom-id'));
              var gsNode = it.el.data('_gridstack_node');
              if (updateThis && gsNode && (updateThis.x !== gsNode.x || updateThis.y !== gsNode.y || updateThis.w !== gsNode.width || updateThis.h !== gsNode.height)) {
                _ember['default'].set(updateThis, 'x', gsNode.x);
                _ember['default'].set(updateThis, 'y', gsNode.y);
                _ember['default'].set(updateThis, 'w', gsNode.width);
                _ember['default'].set(updateThis, 'h', gsNode.height);
                changed = true;
              }
            });
          }
          if (changed) {
            _this2.attrs.gridChanged(_this2.get('items'));
          }
        })();
      }
    },

    registerChild: function registerChild(el) {
      if (this.get('grid')) {
        this.get('grid').add_widget(el);
      }
    },

    unregisterChild: function unregisterChild(el) {
      if (this.get('grid')) {
        this.get('grid').remove_widget(el, false);
      }
    },

    onWillDestroy: _ember['default'].on('willDestroyElement', function () {
      if (this.get('grid')) {
        if (this.get('options.static_grid')) {
          //prevent error in gridstack - it tries to call .draggable('disable') on not
          //initialized elements, which results in an error
          this.$('.grid-stack-item').draggable();
          this.$('.grid-stack-item').resizable();
        }
        this.get('grid').destroy();
        this.set('grid', null);
      }
    })

  });
});