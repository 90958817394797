define('flowprosal-web-client/models/segment-template', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr(),
    content: _emberData['default'].attr(),
    shortDescription: _emberData['default'].attr(),
    price: _emberData['default'].attr('number'),
    favorite: _emberData['default'].attr('boolean'),
    library: _emberData['default'].attr('boolean', { defaultValue: false }),
    gridData: _emberData['default'].attr(),
    segmentType: _emberData['default'].attr({ defaultValue: '' }),
    section: _emberData['default'].attr({ defaultValue: '' }),

    createdAt: _emberData['default'].attr(),
    updatedAt: _emberData['default'].attr(),
    company: _emberData['default'].belongsTo(),
    user: _emberData['default'].belongsTo(),
    image: _emberData['default'].belongsTo('image'),
    imageUrl: _emberData['default'].attr(),
    sourceType: _emberData['default'].attr(),

    isGallery: _ember['default'].computed.equal('segmentType', 'gallery'),
    imageIds: _ember['default'].computed.mapBy('gridData', 'content.id'),

    galleryImages: _ember['default'].computed('isGallery', 'imageIds.[]', 'company.images.[].id', function () {
      var ret = _ember['default'].A();
      var _this = this;
      if (this.get('isGallery')) {
        this.get('company').then(function (c) {
          return c.get('images');
        }).then(function (companyImages) {
          _this.get('imageIds').forEach(function (it) {
            var img = companyImages.findBy('id', it);
            if (img) {
              ret.pushObject(img);
            }
          });
        });
      }

      return ret;
    }),

    gridItems: _ember['default'].computed('gridData.@each.{x,y,w,h}', 'galleryImages.[]', 'isGallery', function () {
      var ret = _ember['default'].A(),
          _this = this;
      if (this.get('isGallery') && this.get('gridData')) {
        this.get('gridData').forEach(function (it) {
          var copy = _ember['default'].copy(it);
          copy.content = _this.get('galleryImages').findBy('id', it.content.id);
          ret.pushObject(copy);
        });
      }

      return ret;
    })

  });
});