define('flowprosal-web-client/routes/dashboard/search', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    renderTemplate: function renderTemplate() {
      this.render('dashboard/search', {
        outlet: 'searchOutlet'
      });
    },

    activate: function activate() {
      var dashboardController = this.controllerFor('dashboard');
      _ember['default'].run.later(dashboardController, function () {
        dashboardController.set('isSearchOpen', true);
      }, 50);
    },

    deactivate: function deactivate() {
      var _this = this;

      var dashboardController = this.controllerFor('dashboard');
      dashboardController.set('isSearchOpen', false);
      _ember['default'].$('.morphsearch-input').blur();
      _ember['default'].run.later(dashboardController, function () {
        dashboardController.set('isSearchInputHidden', true);
        _ember['default'].run.later(_this, function () {
          dashboardController.set('isSearchInputHidden', false);
          dashboardController.set('searchTerm', null);
        }, 300);
      }, 500);
    },

    actions: {
      closeSearch: function closeSearch() {
        //get the previous, not search route name and transition into it
        if (this.get('router.location.history.state.path').indexOf('search?q=') > -1) {
          this.transitionTo('dashboard');
        } else {
          this.get('router.location.history').back();
        }
      },

      doSearch: function doSearch(term) {
        this.transitionTo({ queryParams: { q: term } });
      }
    }
  });
});