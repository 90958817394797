define('flowprosal-web-client/components/segment-list-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: "c-segment-list-item",

    gridStackOptions: {
      height: 12,
      width: 12,
      cell_height: 30,
      vertical_margin: 0,
      static_grid: true,
      float: true
    },

    actions: {
      deleteSegmentTemplate: function deleteSegmentTemplate(segmentTemplate) {
        this.sendAction('deleteAction', segmentTemplate);
      }
    }

  });
});