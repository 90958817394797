define('flowprosal-web-client/helpers/activity-type', ['exports', 'ember'], function (exports, _ember) {
  exports.activityType = activityType;

  // import momentFormat from 'ember-moment/helpers/moment-format';
  // import EmberInplaceEditComponent from 'ember-inplace-edit/components/ember-inplace-edit';
  // import momentFormat from 'moment-format';

  function activityType(params) {
    var type = params[0],
        activityMap = {
      'comment': 'commented',
      'submitted': 'sent',
      'accepted': 'accepted',
      'visit': 'viewed',
      'declined': 'declined',
      'deposit_paid': 'deposit paid',
      'milestone_paid': 'milestone paid'
    };

    return activityMap[type];
  }

  exports['default'] = _ember['default'].Helper.helper(activityType);
});