define("flowprosal-web-client/templates/proposals/edit/partials/canvas-styles", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 32,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/proposals/edit/partials/canvas-styles.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        dom.setAttribute(el1, "type", "text/css");
        dom.setAttribute(el1, "scoped", "");
        var el2 = dom.createTextNode("\n  .proposal-color-primary {\n    background-color: #");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(";\n    color: #");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(";\n  }\n\n  .proposal-color-accent {\n    color: #");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(";\n  }\n\n  .proposal-color-text {\n    color: #");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("!important;\n  }\n\n  .proposal-header__align-right .col-right {\n    border-color: #");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(";\n  }\n\n  .right-align-border-color {\n    border-color: #");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("!important;\n  }\n\n  .proposal-cover-letter__footer .accent{\n    background-color: #");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(";\n  }\n\n  .proposal-cover-letter__footer .primary{\n    background-color: #");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(";\n    color: #");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(";\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(element0, 5, 5);
        morphs[3] = dom.createMorphAt(element0, 7, 7);
        morphs[4] = dom.createMorphAt(element0, 9, 9);
        morphs[5] = dom.createMorphAt(element0, 11, 11);
        morphs[6] = dom.createMorphAt(element0, 13, 13);
        morphs[7] = dom.createMorphAt(element0, 15, 15);
        morphs[8] = dom.createMorphAt(element0, 17, 17);
        return morphs;
      },
      statements: [["content", "model.colorPrimary", ["loc", [null, [3, 23], [3, 45]]]], ["content", "model.colorText", ["loc", [null, [4, 12], [4, 31]]]], ["content", "model.colorAccent", ["loc", [null, [8, 12], [8, 33]]]], ["content", "model.colorText", ["loc", [null, [12, 12], [12, 31]]]], ["content", "model.colorAccent", ["loc", [null, [16, 19], [16, 40]]]], ["content", "model.colorAccent", ["loc", [null, [20, 19], [20, 40]]]], ["content", "model.colorAccent", ["loc", [null, [24, 23], [24, 44]]]], ["content", "model.colorPrimary", ["loc", [null, [28, 23], [28, 45]]]], ["content", "model.colorText", ["loc", [null, [29, 12], [29, 31]]]]],
      locals: [],
      templates: []
    };
  })());
});