define('flowprosal-web-client/components/col-pick-circle', ['exports', 'ember', 'flowprosal-web-client/mixins/col-pick-circle-mixin'], function (exports, _ember, _flowprosalWebClientMixinsColPickCircleMixin) {
  exports['default'] = _ember['default'].Component.extend(_flowprosalWebClientMixinsColPickCircleMixin['default'], {
    flat: false,

    normalizedColor: _ember['default'].computed('value', {
      get: function get() {
        return '#' + this.escapeColor(this.get('value'));
      },
      set: function set(_, value) {
        var valueToSave = value;

        if (valueToSave[0] === '#') {
          valueToSave = valueToSave.substring(1);
        }

        this.set('value', valueToSave);
        return value;
      }
    }),

    colorStyles: _ember['default'].computed('normalizedColor', function () {
      return _ember['default'].String.htmlSafe('background: ' + this.get('normalizedColor'));
    }),

    escapeColor: function escapeColor(str) {
      return str.replace(/[^0-9a-f]+/gi, '');
    }
  });
});