define('flowprosal-web-client/mixins/intro/dashboard-intro-mixin', ['exports', 'ember', 'flowprosal-web-client/mixins/intro/intro-mixin'], function (exports, _ember, _flowprosalWebClientMixinsIntroIntroMixin) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Mixin.create(_flowprosalWebClientMixinsIntroIntroMixin['default'], {
    introType: 'dashboard',

    initIntrojs: function initIntrojs() {
      if (this.get('introSteps') === null) {
        var steps = [{
          intro: '<h2>Welcome</h2>\n          <p>Thank you for signing up for FlowProsal<br>We\'d like to take you on a little tour of the product to help you get productive quicker.</p>\n          <p>This takes most humans 3.5 minutes to complete and is totally worth your time, we promise!</p>\n          <p>First, here is the Dashboard. You can get right to everything you need from here.</p>'
        }, {
          element: $('#new-proposal a')[0],
          intro: '<h2>CREATE A PROPOSAL</h2>\n          <p>Everything starts by creating a proposal.</p>\n          <p>Click here and we’ll build your first proposal together.</p>'
        }, {
          element: $('#c-user-menu')[0],
          intro: '<h2>ACCOUNT SETTINGS</h2>\n          <p>This is the User Menu. From here you can go to the settings page to manage the default settings for your company.</p>',
          name: 'userMenu',
          position: 'left',
          highlightClass: 'intro-tooltip-highlight-dashboard-user-menu',
          tooltipClass: 'intro-tooltip-dashboard-user-menu'
        }, {
          element: $('#morphsearch')[0],
          intro: '<h2>ACCOUNT SEARCH</h2>\n          <p>Use this search interface for quick access to all of the Proposals, Contacts or Clients within your account.</p>',
          position: 'bottom-middle-aligned',
          highlightClass: 'intro-tooltip-highlight-dashboard-search'
        }];

        if ($('.btn-verify-account-identity')[0]) {
          steps.push({
            element: $('.btn-verify-account-identity')[0],
            intro: '<h2>VERIFY ACCOUNT</h2>\n          <p>Make sure to verify your account identity so your Clients can make payments and you can get paid!</p>',
            position: 'bottom-middle-aligned',
            highlightClass: 'intro-tooltip-highlight-dashboard-search',
            tooltipClass: 'intro-tooltip-dashboard-btn-verify introjs-last'
          });
        } else {
          steps[steps.length - 1].tooltipClass = 'introjs-last';
        }

        this.set('introSteps', steps);
      }
    },

    actions: {
      introBeforeChange: function introBeforeChange(currentStep, nextStep) {
        if (nextStep.name === 'userMenu') {
          _ember['default'].run.later(function () {
            $('#dLabel2').trigger('click');
          });
        }

        if (currentStep.name === 'userMenu') {
          $(document.body).trigger('click');
        }
      }
    }
  });
});