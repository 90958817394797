define('flowprosal-web-client/components/segment-template', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['segment-template'],
    segmentTemplate: null,

    selectTemplate: _ember['default'].on('click', function () {
      this.attrs['on-click'](this.get('segmentTemplate'));
    })
  });
});