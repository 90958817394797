define("flowprosal-web-client/templates/terms-of-use", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 2,
              "column": 96
            }
          },
          "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "id", "user-top-logo");
          dom.setAttribute(el1, "src", "/images/flowprosalHorizontal.png");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 5,
              "column": 110
            }
          },
          "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "id", "user-top-logo");
          dom.setAttribute(el1, "src", "/images/flowprosalHorizontal.png");
          dom.setAttribute(el1, "width", "300px");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 9,
              "column": 0
            }
          },
          "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "user-menu", [], ["logout", "logout", "user", ["subexpr", "@mut", [["get", "currentUser", ["loc", [null, [8, 35], [8, 46]]]]], [], []], "id", "c-user-menu"], ["loc", [null, [8, 2], [8, 65]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 6
            },
            "end": {
              "line": 15,
              "column": 66
            }
          },
          "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Home ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 10
            },
            "end": {
              "line": 46,
              "column": 66
            }
          },
          "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Dashboard");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 10
            },
            "end": {
              "line": 47,
              "column": 64
            }
          },
          "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Contacts");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 10
            },
            "end": {
              "line": 48,
              "column": 62
            }
          },
          "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Clients");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 49,
              "column": 10
            },
            "end": {
              "line": 49,
              "column": 66
            }
          },
          "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Proposals");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child8 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 10
            },
            "end": {
              "line": 50,
              "column": 58
            }
          },
          "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Segments");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child9 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 10
            },
            "end": {
              "line": 51,
              "column": 76
            }
          },
          "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Transactions");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 56,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/terms-of-use.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "user-logo");
        dom.setAttribute(el1, "class", "col-md-3 hidden-sm hidden-xs");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "user-logo-md");
        dom.setAttribute(el1, "class", "col-md-3 visible-sm visible-xs");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "body-content");
        dom.setAttribute(el1, "class", "container ui-kit");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "breadcrumbs");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-12");
        dom.setAttribute(el3, "style", "text-align: left; padding-left:0px;");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" / Terms of use\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "light-blue");
        var el3 = dom.createTextNode("\n    Terms of use\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n    Lorem ipsum dolor sit amet, corpora fastidii complectitur ei usu. Duo affert dignissim ea, at doming suscipit his, no scaevola philosophia delicatissimi quo. Maluisset corrumpit at nec. An graeco vocent eruditi vim, ei his option feugiat maiorum, et sonet alterum gloriatur his. Vix hinc albucius tincidunt id.\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n    Lorem ipsum dolor sit amet, corpora fastidii complectitur ei usu. Duo affert dignissim ea, at doming suscipit his, no scaevola philosophia delicatissimi quo. Maluisset corrumpit at nec. An graeco vocent eruditi vim, ei his option feugiat maiorum, et sonet alterum gloriatur his. Vix hinc albucius tincidunt id.\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n    Lorem ipsum dolor sit amet, corpora fastidii complectitur ei usu. Duo affert dignissim ea, at doming suscipit his, no scaevola philosophia delicatissimi quo. Maluisset corrumpit at nec. An graeco vocent eruditi vim, ei his option feugiat maiorum, et sonet alterum gloriatur his. Vix hinc albucius tincidunt id.\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n    Lorem ipsum dolor sit amet, corpora fastidii complectitur ei usu. Duo affert dignissim ea, at doming suscipit his, no scaevola philosophia delicatissimi quo. Maluisset corrumpit at nec. An graeco vocent eruditi vim, ei his option feugiat maiorum, et sonet alterum gloriatur his. Vix hinc albucius tincidunt id.\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("nav");
        dom.setAttribute(el2, "id", "bt-menu");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "bt-menu flowlogo");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "src", "/images/flowprosalHorizontal.png");
        dom.setAttribute(el4, "width", "300px");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "#");
        dom.setAttribute(el3, "class", "bt-menu-trigger");
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Menu");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "class", "sideMenu");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "bt-overlay");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" /container ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [6]);
        var element1 = dom.childAt(element0, [13]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element1, [5]);
        var element4 = dom.childAt(element1, [7]);
        var morphs = new Array(13);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 1, 1);
        morphs[4] = dom.createAttrMorph(element1, 'class');
        morphs[5] = dom.createElementMorph(element2);
        morphs[6] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
        morphs[8] = dom.createMorphAt(dom.childAt(element3, [5]), 0, 0);
        morphs[9] = dom.createMorphAt(dom.childAt(element3, [7]), 0, 0);
        morphs[10] = dom.createMorphAt(dom.childAt(element3, [9]), 0, 0);
        morphs[11] = dom.createMorphAt(dom.childAt(element3, [11]), 0, 0);
        morphs[12] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [["block", "link-to", ["dashboard.index"], [], 0, null, ["loc", [null, [2, 2], [2, 108]]]], ["block", "link-to", ["dashboard.index"], [], 1, null, ["loc", [null, [5, 2], [5, 122]]]], ["block", "if", [["get", "currentUser", ["loc", [null, [7, 6], [7, 17]]]]], [], 2, null, ["loc", [null, [7, 0], [9, 7]]]], ["block", "link-to", ["application"], ["class", "breadcrumb-dashboard"], 3, null, ["loc", [null, [15, 6], [15, 78]]]], ["attribute", "class", ["concat", ["bt-menu ", ["subexpr", "if", [["get", "isMenuOpen", ["loc", [null, [39, 40], [39, 50]]]], "bt-menu-open", "bt-menu-close"], [], ["loc", [null, [39, 35], [39, 83]]]]]]], ["element", "action", ["toggleMenu"], [], ["loc", [null, [44, 16], [44, 39]]]], ["block", "link-to", ["dashboard.index"], ["class", "menuitem"], 4, null, ["loc", [null, [46, 10], [46, 78]]]], ["block", "link-to", ["contacts.index"], ["class", "menuitem"], 5, null, ["loc", [null, [47, 10], [47, 76]]]], ["block", "link-to", ["clients.index"], ["class", "menuitem"], 6, null, ["loc", [null, [48, 10], [48, 74]]]], ["block", "link-to", ["proposals.index"], ["class", "menuitem"], 7, null, ["loc", [null, [49, 10], [49, 78]]]], ["block", "link-to", ["segments"], ["class", "menuitem"], 8, null, ["loc", [null, [50, 10], [50, 70]]]], ["block", "link-to", ["dashboard.transactions"], ["class", "menuitem"], 9, null, ["loc", [null, [51, 10], [51, 88]]]], ["element", "action", ["toggleMenu"], [], ["loc", [null, [53, 28], [53, 51]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
    };
  })());
});