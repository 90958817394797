define('flowprosal-web-client/routes/company-selector', ['exports', 'ember', 'flowprosal-web-client/config/environment'], function (exports, _ember, _flowprosalWebClientConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var currentUser = this.session.get('currentUser');

      return _ember['default'].RSVP.hash({
        user: currentUser,
        companies: this.store.findAll('company')
      });
    },

    afterModel: function afterModel() {
      window.customStorage.removeItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier);
    },

    actions: {
      logout: function logout() {
        this.store.unloadAll();
        this.session.close();
        this.transitionTo('login');
      },

      chooseCompany: function chooseCompany(company) {
        window.customStorage.setItem(_flowprosalWebClientConfigEnvironment['default'].companyIdentifier, company.id);
        this.session.set('content.currentCompany', company);
        this.store.unloadAll('proposal');
        this.store.unloadAll('segment');
        this.store.unloadAll('segment-template');
        this.store.unloadAll('contact');
        this.store.unloadAll('client');
        this.transitionTo('dashboard');
      }
    }
  });
});