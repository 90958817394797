define('flowprosal-web-client/routes/clients/new', ['exports', 'ember', 'flowprosal-web-client/mixins/save-model-mixin-factory'], function (exports, _ember, _flowprosalWebClientMixinsSaveModelMixinFactory) {
  exports['default'] = _ember['default'].Route.extend((0, _flowprosalWebClientMixinsSaveModelMixinFactory['default'])('clients'), {
    model: function model() {
      return this.store.createRecord('client');
    },

    afterModel: function afterModel(_, transition) {
      var _this = this;

      if (transition.queryParams.assignToProposal) {
        return this.store.findRecord('proposal', transition.queryParams.assignToProposal).then(function (proposal) {
          _this.set('proposal', proposal);
        });
      } else {
        _this.set('proposal', undefined);
      }
    },

    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        displayNewRecordForm: true,
        selectedClient: this.get('proposal.client'),
        proposal: this.get('proposal')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('assignToProposal', undefined);
      }
    }
  });
});