define('flowprosal-web-client/routes/bootstrap/services', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.store.findRecord('company', params.company_id);
    },

    afterModel: function afterModel(model) {
      this.get('session').set('content.currentCompany', model);
    },

    resetController: function resetController(controller) {
      controller.get('segments').clear();
      controller.setProperties({
        areSegmentsValid: {},
        displayErrors: false,
        isShowingWarningModal: false
      });
    }
  });
});