define('flowprosal-web-client/components/signup-progress-bar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    activeStep: null,

    isActiveStepOver1: _ember['default'].computed('activeStep', function () {
      return this.get('activeStep') > 1;
    }),

    isActiveStepOver2: _ember['default'].computed('activeStep', function () {
      return this.get('activeStep') > 2;
    })
  });
});