define('flowprosal-web-client/models/change-request', ['exports', 'ember', 'ember-data', 'ember-api-actions'], function (exports, _ember, _emberData, _emberApiActions) {
  exports['default'] = _emberData['default'].Model.extend({
    scope: _emberData['default'].attr('boolean', { 'default': true }), //default scope change, if it's false then it's a payment request
    status: _emberData['default'].attr('string'),
    statusChangedAt: _emberData['default'].attr('string'),

    title: _emberData['default'].attr(),
    description: _emberData['default'].attr(),
    price: _emberData['default'].attr('number'),

    createdAt: _emberData['default'].attr('date'),
    acceptedAt: _emberData['default'].attr('date'),

    proposal: _emberData['default'].belongsTo('proposal'),

    totalPriceWithChange: _ember['default'].computed('price', 'proposal.finalTotalPrice', function () {
      return this.get('price') + this.get('proposal.finalTotalPrice');
    }),

    remainingBalance: _ember['default'].computed('totalPriceWithChange', 'proposal.sumPaidPaymentPrices', function () {
      return this.get('totalPriceWithChange') - this.get('proposal.sumPaidPaymentPrices');
    }),

    sendReminder: (0, _emberApiActions.memberAction)({ path: 'send_reminder' })
  });
});