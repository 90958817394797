define("flowprosal-web-client/templates/components/select-segment", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 6
            },
            "end": {
              "line": 13,
              "column": 6
            }
          },
          "moduleName": "flowprosal-web-client/templates/components/select-segment.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "partial", ["proposals/edit/partials/new-segments"], [], ["loc", [null, [12, 8], [12, 58]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 6
              },
              "end": {
                "line": 15,
                "column": 6
              }
            },
            "moduleName": "flowprosal-web-client/templates/components/select-segment.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "partial", ["proposals/edit/partials/favorite-segments"], [], ["loc", [null, [14, 8], [14, 63]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 6
                },
                "end": {
                  "line": 17,
                  "column": 6
                }
              },
              "moduleName": "flowprosal-web-client/templates/components/select-segment.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "partial", ["proposals/edit/partials/all-segments"], [], ["loc", [null, [16, 8], [16, 58]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 6
              },
              "end": {
                "line": 17,
                "column": 6
              }
            },
            "moduleName": "flowprosal-web-client/templates/components/select-segment.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "selectedAll", ["loc", [null, [15, 16], [15, 27]]]]], [], 0, null, ["loc", [null, [15, 6], [17, 6]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 6
            },
            "end": {
              "line": 17,
              "column": 6
            }
          },
          "moduleName": "flowprosal-web-client/templates/components/select-segment.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "selectedFavorites", ["loc", [null, [13, 16], [13, 33]]]]], [], 0, 1, ["loc", [null, [13, 6], [17, 6]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "flowprosal-web-client/templates/components/select-segment.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "c-select-segment");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "c-select-segment__overlay");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        var el4 = dom.createTextNode("Segments");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "c-select-segment__menu");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("Favorites");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("All Segments");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("New Segment");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "fa fa-info-circle btn-start-intro");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element3, [5]);
        var element7 = dom.childAt(element2, [5]);
        var element8 = dom.childAt(element0, [7]);
        var morphs = new Array(12);
        morphs[0] = dom.createElementMorph(element1);
        morphs[1] = dom.createAttrMorph(element2, 'class');
        morphs[2] = dom.createAttrMorph(element4, 'class');
        morphs[3] = dom.createElementMorph(element4);
        morphs[4] = dom.createAttrMorph(element5, 'class');
        morphs[5] = dom.createElementMorph(element5);
        morphs[6] = dom.createAttrMorph(element6, 'class');
        morphs[7] = dom.createElementMorph(element6);
        morphs[8] = dom.createAttrMorph(element7, 'class');
        morphs[9] = dom.createMorphAt(element7, 1, 1);
        morphs[10] = dom.createMorphAt(element0, 5, 5);
        morphs[11] = dom.createElementMorph(element8);
        return morphs;
      },
      statements: [["element", "action", ["leave"], [], ["loc", [null, [2, 41], [2, 59]]]], ["attribute", "class", ["concat", ["c-select-segment__drawer ", ["subexpr", "if", [["get", "opened", ["loc", [null, [3, 44], [3, 50]]]], "opened"], [], ["loc", [null, [3, 39], [3, 61]]]]]]], ["attribute", "class", ["concat", ["favorite-segments ", ["subexpr", "if", [["get", "selectedFavorites", ["loc", [null, [6, 41], [6, 58]]]], "active"], [], ["loc", [null, [6, 36], [6, 69]]]]]]], ["element", "action", ["showFavorites"], [], ["loc", [null, [6, 71], [6, 97]]]], ["attribute", "class", ["concat", ["all-segments ", ["subexpr", "if", [["get", "selectedAll", ["loc", [null, [7, 36], [7, 47]]]], "active"], [], ["loc", [null, [7, 31], [7, 58]]]]]]], ["element", "action", ["showAll"], [], ["loc", [null, [7, 60], [7, 80]]]], ["attribute", "class", ["concat", ["new-segment ", ["subexpr", "if", [["get", "selectedNew", ["loc", [null, [8, 35], [8, 46]]]], "active"], [], ["loc", [null, [8, 30], [8, 57]]]]]]], ["element", "action", ["showNew"], [], ["loc", [null, [8, 59], [8, 79]]]], ["attribute", "class", ["concat", ["c-select-segment__body ", ["subexpr", "if", [["subexpr", "not", [["get", "selectedNew", ["loc", [null, [10, 49], [10, 60]]]]], [], ["loc", [null, [10, 44], [10, 61]]]], "extra-top-padding", ""], [], ["loc", [null, [10, 39], [10, 86]]]]]]], ["block", "if", [["get", "selectedNew", ["loc", [null, [11, 12], [11, 23]]]]], [], 0, 1, ["loc", [null, [11, 6], [17, 13]]]], ["inline", "intro-js", [], ["steps", ["subexpr", "@mut", [["get", "introSteps", ["loc", [null, [21, 19], [21, 29]]]]], [], []], "start-if", ["subexpr", "@mut", [["get", "isIntroVisile", ["loc", [null, [21, 39], [21, 52]]]]], [], []], "on-exit", "introEnded", "on-complete", "introEnded"], ["loc", [null, [21, 2], [21, 100]]]], ["element", "action", ["startIntro"], [], ["loc", [null, [22, 47], [22, 70]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});