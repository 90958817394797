define('flowprosal-web-client/components/modal-mark-as-paid', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    actions: {
      closeModal: function closeModal() {
        this.sendAction('on-cancel');
      },

      markAsPaid: function markAsPaid() {
        var _this = this;
        this.attrs.markAsPaid(this.get('model')).then(function () {
          _this.sendAction('on-cancel');
        });
      }
    }
  });
});