define('flowprosal-web-client/components/form-edit-contact', ['exports', 'ember', 'flowprosal-web-client/mixins/send-save-mixin'], function (exports, _ember, _flowprosalWebClientMixinsSendSaveMixin) {
  exports['default'] = _ember['default'].Component.extend(_flowprosalWebClientMixinsSendSaveMixin['default'], {
    actions: {
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      },

      clientSelected: function clientSelected(client) {
        this.set('client', client);
        this.sendAction('clientSelected');
      }
    }
  });
});