define('flowprosal-web-client/controllers/contacts/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    session: _ember['default'].inject.service('session'),

    dropzoneUrl: _ember['default'].computed('model.id', function () {
      return this.container.lookup('adapter:application').buildURL('contacts', this.get('model.id')) + '/upload_avatar';
    }),

    dropzoneHeaders: _ember['default'].computed('session.currentUser.authenticationToken', 'session.currentCompany', function () {
      return { "Authorization": this.get('session.currentUser.authenticationToken'), "CompanyIdentifier": this.get('session.currentCompany.id') };
    }),

    dzAddedFile: function dzAddedFile(file) {
      if (this.dropzone.files.length > 1) {
        if (this.dropzone.files[0].status === 'success') {
          this.dropzone.removeFile(this.dropzone.files[0]);
        } else {
          this.dropzone.removeFile(file);
        }
      }
    },

    actions: {
      contactImageUploadComplete: function contactImageUploadComplete(file) {
        var jsonResponse = JSON.parse(file.xhr.response);
        this.get('store').pushPayload(jsonResponse);
        _ember['default'].$('#contact-info .user-profile-image').attr('src', jsonResponse.data.attributes.avatar);

        this.get('notifications').addNotification({
          message: 'Image upload completed.',
          type: 'success',
          autoClear: true,
          clearDuration: 2200
        });
      }
    }
  });
});