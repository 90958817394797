define('flowprosal-web-client/components/modal-gallery-images', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    isShowingUpload: true,

    onInit: _ember['default'].on('init', function () {
      this.set('uploadingFiles', []);
    }),

    actions: {
      closeUploadGalleryImageModal: function closeUploadGalleryImageModal() {
        this.sendAction('closeUploadGalleryImageModal');
      },

      showUploadPanel: function showUploadPanel() {
        this.set('isShowingUpload', true);
      },

      showSelectPanel: function showSelectPanel() {
        this.set('isShowingUpload', false);
      },

      selectExistingImage: function selectExistingImage(image) {
        this.sendAction('addExistingImageToGrid', image);
      },

      favoriteExistingImage: function favoriteExistingImage(image) {
        image.set('favorite', !image.get('favorite'));
        image.save();
      },

      galleryFileAdded: function galleryFileAdded(file) {
        //console.log(file);
        var f = _ember['default'].Object.create({
          name: file.name,
          bytesSent: 0,
          total: file.upload.total,
          isUploading: true,
          message: '',
          progress: file.upload.progress,
          file: file
        });

        this.get('uploadingFiles').pushObject(f);
        this.sendAction('galleryFileAdded', file);
      },

      uploadProgress: function uploadProgress(file, progress, bytesSent) {
        var f = this.get('uploadingFiles').findBy('name', file.name);
        f.set('progress', progress);
        f.set('bytesSent', Math.min(bytesSent, f.get('total')));
        if (progress === 100) {
          f.set('isUploading', false);
          f.set('message', 'converting image...');
        }
      },

      cancelUpload: function cancelUpload(f) {
        f.file.xhr.abort();
        f.set('isUploading', false);
        f.set('message', 'upload cancelled');
        this.sendAction('galleryUploadAbort', f.file);
      },

      galleryUploadComplete: function galleryUploadComplete(file) {
        var f = this.get('uploadingFiles').findBy('name', file.name);
        f.set('isUploading', false);
        f.set('message', 'successfully uploaded');
        this.sendAction('galleryUploadComplete', file);
      }

    }
  });
});