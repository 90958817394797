define('flowprosal-web-client/initializers/error-handler', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  /* global window */

  function handleError(error) {
    _ember['default'].Logger.assert(false, error);
  }

  function initialize() /* application */{

    _ember['default'].onerror = function (err) {
      handleError(err);
    };

    _ember['default'].RSVP.on('error', function (err) {
      handleError(err);
    });

    window.onerror = handleError;
  }

  exports['default'] = {
    name: 'error-handler',
    initialize: initialize
  };
});