define('flowprosal-web-client/helpers/fa-icon', ['exports', 'ember-cli-font-awesome/helpers/fa-icon'], function (exports, _emberCliFontAwesomeHelpersFaIcon) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliFontAwesomeHelpersFaIcon['default'];
    }
  });
  Object.defineProperty(exports, 'faIcon', {
    enumerable: true,
    get: function get() {
      return _emberCliFontAwesomeHelpersFaIcon.faIcon;
    }
  });
});