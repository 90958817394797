define('flowprosal-web-client/components/modal-proposal-accepted', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    actions: {
      closeModal: function closeModal() {
        this.sendAction('on-close');
      }
    }

  });
});